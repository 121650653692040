// Vendors
import Vue from 'vue';
import ShardsVue from 'shards-vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import DOMPurify from 'dompurify';
import 'sweetalert2/dist/sweetalert2.all.min.js'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import vSelect from 'vue-select';
import { mask } from 'vue-the-mask';

// Styles
import 'bootstrap/dist/css/bootstrap.css';
import '@/scss/shards-dashboards.scss';
import '@/assets/scss/date-range.override.scss';
import '@/assets/scss/tabs-validation.scss';
import 'vue-select/dist/vue-select.css';
import '@/assets/scss/vue-select.override.scss';

// Core
import App from './App.vue';
import router from './router';
import store from './store/index';

// Layouts
import Default from '@/layouts/Default.vue';
import GuestLayout from '@/layouts/Guest.vue';

// Helpers
import addVeeValidateRules from '@/utils/VeeValidateRules.js';

// Custom components
import FileInput from '@/components/common/FileInput.vue';

// Global components
Vue.component('guest-layout', GuestLayout);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('v-select', vSelect);
Vue.component('file-input', FileInput);

// Global directives
Vue.directive('mask', mask);

// Configure vendors
addVeeValidateRules(extend);

// Axios
window.axios = Axios;
Vue.prototype.$http = Axios;

if (window.location.hostname === 'localhost') {
  Vue.prototype.$http.defaults.baseURL = 'https://localhost:' + parseInt(window.location.port, 10) + '/';
} else {
  Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_SERVER_URL;
}

Vue.prototype.$showScheduleV2 = !!(process.env.VUE_APP_SHOW_SCHEDULE_V2);
Vue.prototype.$http.defaults.headers['Cache-Control'] = 'no-store,max-age=0';

Vue.prototype.$http.interceptors.request.use(config => {
  if (config.url.indexOf('/') !== 0 && !config.url.includes(process.env.VUE_APP_API_SERVER_URL) && config.headers['X-Csrf-Token']) {
    delete config.headers['X-Csrf-Token'];
  }

  return config;
});

Vue.prototype.$http.interceptors.response.use(undefined, (error) => {
  if (
    error.response.status === 401 &&
    router.currentRoute.path !== '/logowanie' &&
    router.currentRoute.path !== '/wczytywanie'
  ) {
    window.location.reload();
  }

  return Promise.reject(error);
});

// Shards
ShardsVue.install(Vue);
Vue.component('default-layout', Default);
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

// Other
Vue.use(VueAxios, Axios);
Vue.use(VueSweetalert2);

// DOMPurify configuration
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }

  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

// Register v-pure-html directive
Vue.directive('pure-html', {
  inserted: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
  update: (el, binding) => {
    if (binding.oldValue === binding.value) {
      return;
    }
    el.innerHTML = DOMPurify.sanitize(binding.value);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
