<template>
  <d-modal
  @close="handleClose"
  :class="{ 'is-hidden': isHidden }">
    <d-modal-header>
      <d-modal-title>
        Generuj raport przepracowanych godzin
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <d-form>
        <validation-observer ref="hours-report-download-form" tag="form">
          <div class="form-group">
            <label for="name">
              Wybierz rok
            </label>

            <validation-provider
              rules="required"
              v-slot="{ errors }">
              <div
                :class="{
                  'form-control': true,
                  'is-select': true,
                  'vs-wrap-text': true,
                  'is-invalid': errors.length
                }">
                <v-select
                  :options="optionsYears"
                  v-model="year"
                  placeholder="Wybierz rok"
                  :searchable="false">
                </v-select>
              </div>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="name">
              Wybierz miesiąc
            </label>
            <validation-provider
              rules="required"
              v-slot="{ errors }">
              <div
                :class="{
                  'form-control': true,
                  'is-select': true,
                  'vs-wrap-text': true,
                  'is-invalid': errors.length
                }">
                <v-select
                  v-model="month"
                  :options="optionsMonths"
                  :reduce="month => month.value"
                  :searchable="false"
                  placeholder="Wybierz miesiąc"
                  label="name">
                </v-select>
              </div>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        :disabled="generateInProgress"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="generateInProgress"
        @click.prevent="validateForm">
        <div
          v-if="generateInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
        Generuj raport
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
// import ListUtils from '@/utils/ListUtils.js';

export default {
  name: 'hours-report-download-form',
  computed: {
    optionsYears () {
      let years = [];
      let currentYear = (new Date()).getFullYear();

      for (let i = 2021; i <= currentYear; i++) {
        years.push(i);
      }

      return years;
    }
  },
  data () {
    return {
      generateInProgress: false,
      isHidden: true,
      year: null,
      month: null,
      optionsMonths: [
        {
          value: 1,
          name: 'Styczeń'
        }, {
          value: 2,
          name: 'Luty'
        }, {
          value: 3,
          name: 'Marzec'
        }, {
          value: 4,
          name: 'Kwiecień'
        }, {
          value: 5,
          name: 'Maj'
        }, {
          value: 6,
          name: 'Czerwiec'
        }, {
          value: 7,
          name: 'Lipiec'
        }, {
          value: 8,
          name: 'Siepień'
        }, {
          value: 9,
          name: 'Wrzesień'
        }, {
          value: 10,
          name: 'Październik'
        }, {
          value: 11,
          name: 'Listopad'
        }, {
          value: 12,
          name: 'Grudzień'
        }
      ]
    };
  },
  mounted () {
    this.$bus.$on('hours-report-download-popup-show', this.openPopup);
  },
  methods: {
    openPopup () {
      this.year = null;
      this.month = null;
      this.generateInProgress = false;

      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    },
    handleClose () {
      this.isHidden = true;
    },
    cancel() {
      this.handleClose();
    },
    validateForm () {
      FormUtils.validate(this.$refs['hours-report-download-form'], this.generate);
    },
    generate () {
      this.generateInProgress = true;

      Vue.swal({
        title: 'Czy na pewno chcesz wygenerować i pobrać raport?',
        text: 'Pobrany plik będzie miał format .xlsx',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, pobierz',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.$http.get('/api/users/working-times/export', {
            params: {
              year: parseInt(this.year, 10),
              month: parseInt(this.month, 10)
            },
            paramsSerializer: function paramsSerializer(params) {
              return Object.entries(Object.assign({}, params)).map(([key, value]) => `where%5B${key}%5D=${value}`).join('&');
            },
            responseType: 'blob',
          }).then(response => {
            if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', ['raportGodzin', parseInt(this.month, 10), parseInt(this.year, 10) + '.xlsx'].join('-'));
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              Vue.swal({
                title: 'Raport pobrany',
                html: 'Plik raportGodzin.xlsx zawierający raport przepracowanych godzin został pobrany.',
                icon: 'success',
                confirmButtonText: 'OK',
                buttonsStyling: true
              }).then(() => {
                this.generateInProgress = false;
              }).catch((errors) => {
                console.log(errors);
              });
            } else {
              Vue.swal({
                title: 'Wystąpił błąd podczas pobierania',
                html: 'Spróbuj ponownie',
                icon: 'warning',
                confirmButtonText: 'OK',
                buttonsStyling: true
              });
              this.generateInProgress = false;
            }
          }).catch(error => {
            console.log(error);
            Vue.swal({
              title: 'Wystąpił błąd podczas generowania',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            this.generateInProgress = false;
          });
        } else {
          this.generateInProgress = false;
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('hours-report-download-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
