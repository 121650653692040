<script>
export default {
  name: 'has-pagination',
  data () {
    return {
      currentPage: 1,
      paginationMetaData: null
    };
  },
  mounted () {
    this.$bus.$on('pagination-page-changed', this.setPage);
    this.$bus.$on('pagination-reset', this.resetPagination);
  },
  methods: {
    setPage (page) {
      this.currentPage = page;
      this.debouncedLoadFilteredData();
    },
    resetPagination () {
      this.currentPage = 1;
      this.debouncedLoadFilteredData();
    }
  },
  beforeDestroy () {
    this.$bus.$off('pagination-page-changed', this.setPage);
    this.$bus.$off('pagination-reset', this.resetPagination);
  }
}
</script>
