export default [
  {
    text: 'Wina operatora',
    value: 'OPERATOR'
  },
  {
    text: 'Wina maszyny',
    value: 'MACHINE'
  }
];
