export default {
  'readOnly': [
    'TO_SHIPMENT',
    'ENDED'
  ],
  'qaOnly': [
    'QUALITY_CONTROL'
  ],
  'inRealization': [
    'IN_REALIZATION',
    'WAITING_ADDITIONAL_PRODUCTS'
  ],
  'samplesOnly': [
    'WAITING_ACCEPT_SAMPLES',
    'WAITING_SAMPLES'
  ],
  'inPreparation': [
    'NEW',
    'WAITING_PRODUCTS',
    'WAITING_DST',
    'WAITING_SAMPLES',
    'WAITING_ACCEPT_SAMPLES',
  ],
  'productsSetup': [
    'NEW',
    'WAITING_PRODUCTS'
  ],
  'machinesEnabled': [
    'WAITING_SAMPLES',
    'WAITING_ACCEPT_SAMPLES',
    'IN_REALIZATION'
  ]
};
