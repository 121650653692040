export default class UniversalUtils {
  static downloadFile (fileData, fileName) {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
  }

  static generateUUID () {
    // eslint-disable-next-line no-restricted-globals
    if (self.crypto) {
      // eslint-disable-next-line no-restricted-globals
      return self.crypto.randomUUID();
    }

    // For older browsers
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise, no-mixed-operators
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line no-bitwise, no-mixed-operators
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
}
