<template>
  <div
    v-if="metaData !== null"
    class="pagination-wrapper mt-4">
    <d-card class="pagination text-center" style="width: 100%;">
      <d-button-group size="small" class="">
        <d-button
          :disabled="currentPage === 1"
          theme="light"
          @click.prevent.stop="goToFirstPage()">
          <i class="material-icons">first_page</i>
        </d-button>
        <d-button
          :disabled="currentPage === 1"
          theme="light"
          @click.prevent.stop="goToPreviousPage()">
          <i class="material-icons">chevron_left</i>
        </d-button>

        <d-button
          v-for="(button, index) of paginationButtons"
          :key="'pagination-button-' + index"
          :disabled="button.disabled"
          :theme="button.label === currentPage ? 'primary' : 'light'"
          @click.prevent.stop="setPage(button.label)">
          {{ button.label }}
        </d-button>

        <d-button
          :disabled="currentPage >= metaData.last_page"
          theme="light"
          @click.prevent.stop="goToNextPage()">
          <i class="material-icons">chevron_right</i>
        </d-button>
        <d-button
          :disabled="currentPage >= metaData.last_page"
          theme="light"
          @click.prevent.stop="goToLastPage()">
          <i class="material-icons">last_page</i>
        </d-button>
      </d-button-group>
    </d-card>

    <p class="text-center mt-4">
      <small>Ilość pozycji: {{ metaData.total }}</small>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    metaData: {
      default: null,
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  },
  computed: {
    paginationButtons () {
      let pagination = [];

      if (this.metaData.last_page <= 9) {
        for (let i = 1; i <= this.metaData.last_page; i++) {
          pagination.push({
            label: i,
            disabled: i === this.currentPage
          });
        }
      } else if (this.currentPage <= 3 || this.currentPage >= this.metaData.last_page - 2) {
        for (let i = 1; i <= 3; i++) {
          pagination.push({
            label: i,
            disabled: i === this.currentPage
          });
        }

        pagination.push({
          label: '...',
          disabled: true
        });

        for (let i = 2; i >= 0; i--) {
          pagination.push({
            label: this.metaData.last_page - i,
            disabled: this.metaData.last_page - i === this.currentPage
          });
        }
      } else {
        pagination.push({
          label: 1,
          disabled: false
        });

        pagination.push({
          label: 2,
          disabled: false
        });

        pagination.push({
          label: '...',
          disabled: true
        });

        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          pagination.push({
            label: i,
            disabled: i === this.currentPage
          });
        }

        pagination.push({
          label: '...',
          disabled: true
        });

        pagination.push({
          label: this.metaData.last_page - 1,
          disabled: false
        });

        pagination.push({
          label: this.metaData.last_page,
          disabled: false
        });
      }

      return pagination;
    }
  },
  data () {
    return {
      currentPage: 1
    };
  },
  mounted () {
    this.$bus.$on('pagination-reset', this.goToFirstPage);
  },
  methods: {
    setPage (page) {
      this.currentPage = page;
      this.triggerChanges();
    },
    goToFirstPage () {
      this.currentPage = 1;
      this.triggerChanges();
    },
    goToLastPage () {
      this.currentPage = this.metaData.last_page;
      this.triggerChanges();
    },
    goToNextPage () {
      this.currentPage++;

      if (this.currentPage > this.metaData.last_page) {
        this.currentPage = this.metaData.last_page;
      }

      this.triggerChanges();
    },
    goToPreviousPage () {
      this.currentPage--;

      if (this.currentPage < 1) {
        this.currentPage = 1;
      }

      this.triggerChanges();
    },
    triggerChanges () {
      this.$bus.$emit('pagination-page-changed', this.currentPage);
    }
  },
  beforeDestroy () {
    this.$bus.$off('pagination-reset', this.goToFirstPage);
  }
}
</script>
