var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-creator-service-samples"},[(_vm.dataLoaded)?_c('div',_vm._l((_vm.samples),function(sample,index){return _c('d-container',{key:'sample-item-' + sample.uuid,class:{
        'mt-4': true,
        'mb-0': true,
        'mx-0': true,
        'py-0': true,
        'px-0': true,
        'w-100': true,
        'sample-item': true,
        'bg-light': index%2 === 0
      },staticStyle:{"border-radius":"0.625rem","max-width":"unset"}},[_c('order-creator-service-single-sample',{ref:"order-creator-service-single-sample",refInFor:true,attrs:{"sample":sample,"sampleIndex":index,"productIndex":_vm.productIndex,"serviceIndex":_vm.serviceIndex,"allStrands":_vm.allStrands,"isReadOnly":_vm.isReadOnly,"acceptedSamples":_vm.acceptedSamples},on:{"sampleAcceptanceChanged":_vm.onSampleAcceptanceChange,"removeSample":_vm.removeSample}})],1)}),1):_vm._e(),(!_vm.dataLoaded)?_c('div',{staticClass:"pb-3 pt-3 text-center"},[_vm._v(" Trwa ładowanie danych… ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }