<template>
  <div>
    <h4
      v-if="!isOnDashboard"
      class="pb-3 mb-3 border-bottom d-flex flex-sm-wrap">
      Kartony z HPL
    </h4>

    <table class="table mt-0 mb-0 full-width">
      <thead class="bg-light">
        <tr>
          <th v-if="isOnDashboard" scope="col" class="border-0 align-middle">ID</th>
          <th v-if="isOnDashboard" scope="col" class="border-0 align-middle">Nazwa</th>
          <th scope="col" class="border-0 align-middle">Numer kartonu</th>
          <th scope="col" class="border-0 align-middle text-center">Data wysłania z HPL</th>
          <th scope="col" class="border-0 align-middle text-center">Data otrzymania w HPL</th>
          <th v-if="isOnDashboard" scope="col" class="border-0 align-middle text-center" style="width: 100px;">Akcje</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(boxItem, index) of boxesList"
          :key="'box-item-' + index">
          <td
            v-if="isOnDashboard"
            class="align-middle">
            <label class="table-mobile-label mb-1">ID</label>
            {{ boxItem.order_id }}
          </td>
          <td
            v-if="isOnDashboard"
            class="align-middle">
            <label class="table-mobile-label mb-1">Nazwa</label>
            {{ boxItem.order.order_name }}
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Numer kartonu</label>
            {{ boxItem.number }}
          </td>
          <td class="align-middle text-center">
            <label class="table-mobile-label mb-1">Data wysłania z HPL</label>
            {{ formatDateTimeString(boxItem.embroidery_sent_at) }}
          </td>
          <td class="align-middle text-center">
            <label class="table-mobile-label mb-1">Data wysłania z HPL</label>
            {{ formatDateTimeString(boxItem.embroidery_received_at) }}
          </td>
          <td
            v-if="isOnDashboard"
            class="text-center align-middle pt-0">
            <d-button
              theme="primary"
              @click.native="$router.push('/zamowienia/edycja/' + boxItem.order_id)"
              class="mx-2 mt-2"
              title="Edytuj">
              <i class="material-icons">edit</i>
            </d-button>
          </td>
        </tr>
        <tr v-if="boxesList.length === 0">
          <td :colspan="isOnDashboard ? 6 : 3" class="text-center">
            Brak elementów do wyświetlenia&hellip;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CalendarUtils from '@/utils/CalendarUtils.js';

export default {
  name: 'order-boxes-list',
  props: {
    boxesList: {
      required: true,
      type: Array
    },
    isOnDashboard: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {}
  },
  methods: {
    formatDateTimeString (dateString) {
      return CalendarUtils.formatDateTimeString(dateString);
    }
  }
}
</script>
