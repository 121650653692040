<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="9"
        lg="9"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Statystyki firmowe</span>
        <h3 class="page-title">Statystyki</h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="3"
        lg="3"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="primary"
          :disabled="exportInProgress"
          @click.prevent="exportToFile">
          <i class="material-icons">cloud_download</i>
          Eksportuj do pliku
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="allDataLoaded"
      style="width: 100%;"
      class="d-flex ml-0 mb-0 page-filters">
      <validation-observer
        ref="stats-filters-form"
        tag="form"
        class="d-flex mb-2">
        <div class="w-100 mb-2 mr-3" style="width: 25%;min-width: 200px;max-width: 250px;">
          <validation-provider
            rules="required"
            name="date"
            v-slot="{ errors }">
            <date-range-picker
              :locale-data="{
                'firstDay': 1,
                'format': 'dd-mm-yyyy',
                'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                'applyLabel': 'OK',
                'cancelLabel': 'Anuluj'
              }"
              :singleDatePicker="false"
              :timePicker="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="filters.period"
              :ranges="false"
              :linkedCalendars="false"
              style="max-width: 250px" />
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
        <v-select
          style="min-width: 25%;"
          class="mr-2 mb-3 mt-auto"
          :options="allWorkers"
          v-model="filters.users"
          :reduce="user => user.id"
          placeholder="Wybierz pracownika"
          multiple
          label="name">
        </v-select>

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-auto mr-1 mb-2 mt-auto"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2 mt-auto"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%;">
      <d-alert
        v-if="loadError"
        show
        theme="warning"
        class="mt-3">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
      <div v-else-if="!allDataLoaded" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>
      <div
        v-else
        class="table-responsive table-wrapper" >
        <table
          class="table scrollable-table table-hover mt-0 mb-0 full-width">
          <thead class="bg-light">
            <tr>
              <th
                rowspan="2"
                class="border-left-0 border-bottom-0 border-top-0 border-right align-middle bg-light first-sticky-col">
                Pracownik
              </th>
              <th
                rowspan="2"
                class="border-left-0 border-bottom-0 border-top-0 border-right align-middle bg-light second-sticky-col">
                Zdobienie
              </th>
              <th
                :colspan="datesInRange.length"
                class="border-left-0 border-bottom-0 border-top-0 border-right align-middle bg-light text-center">
                Wykonanie
              </th>
              <th
                rowspan="2"
                class="border-0 align-middle text-center col-success"
                style="min-width: 70px;">
                Suma
              </th>
            </tr>
            <tr>
              <th v-for="(date, dateIndex) in datesInRange"
                :key="date"
                :class="{
                  'border-left-0 border-top-0 border-bottom-0 border-right align-middle text-center bg-light text-primary execution-col': true,
                  'day-even': dateIndex % 2 === 0,
                  'day-odd': dateIndex % 2 !== 0
                }">
                {{  date }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(user, userIndex) in stats">
              <tr :key="'user-' + userIndex">
                <th
                  :rowspan="user.services.length === 0 ? 1 : user.services.length"
                  class="align-middle border-right bg-light first-sticky-col">
                  <label class="table-mobile-label mb-1">
                    Pracownik
                  </label>
                  {{ user.name }}
                </th>

                <template v-if="user.services.length === 0">
                  <td class="align-middle text-left border-right bg-light second-sticky-col">
                    <label class="table-mobile-label mb-1">Zdobienie</label>
                    -
                  </td>
                  <td
                    v-for="(date, dateIndex) in datesInRange"
                    :key="'no-execution-' + dateIndex"
                    :class="getExecutionCellClass(dateIndex)">
                    <label class="table-mobile-label mb-1">Wykonanie</label>
                    0
                  </td>
                  <td class="align-middle text-right border-right col-success">
                    <label class="table-mobile-label mb-1">Suma</label>
                    0
                  </td>
                </template>
                <template v-else>
                  <td class="align-middle text-left border-right bg-light second-sticky-col">
                    <label class="table-mobile-label mb-1">Zdobienie</label>
                    {{ user.services[0].service_name }}
                  </td>
                  <td
                    v-for="(date, dateIndex) in datesInRange"
                    :key="'execution-' + dateIndex + '-' + user.user_id"
                    :class="getExecutionCellClass(dateIndex)">
                    <label class="table-mobile-label mb-1">Wykonanie</label>
                    {{ getServiceExecutionsForDate(user, date, user.services[0].service_id) }}
                  </td>
                  <td class="align-middle text-right border-right col-success">
                    <label class="table-mobile-label mb-1">Suma</label>
                    {{ getUserServiceExecutionSum(user, user.services[0].service_id) }}
                  </td>
                </template>
              </tr>
              <template v-if="user.services.length > 1">
                <tr
                  v-for="service in user.services.slice(1)"
                  :key="'row-' + user.user_id + '-' + service.service_id">
                  <td
                    :key="'service-' + user.user_id + '-' + service.service_id"
                    class="align-middle text-left border-right bg-light second-sticky-col">
                    <label class="table-mobile-label mb-1">Zdobienie</label>
                    {{ service.service_name }}
                  </td>
                  <td
                    v-for="(date, dateIndex) in datesInRange"
                    :key="'execution-' + dateIndex + '-' + user.user_id + '-' + service.service_id"
                    :class="getExecutionCellClass(dateIndex)">
                    <label class="table-mobile-label mb-1">Wykonanie</label>
                    {{ getServiceExecutionsForDate(user, date, service.service_id) }}
                  </td>
                  <td class="align-middle text-right border-right col-success">
                    <label class="table-mobile-label mb-1">Suma</label>
                    {{ getUserServiceExecutionSum(user, service.service_id) }}
                  </td>
                </tr>
              </template>
            </template>
            <tr v-if="stats.length === 0">
              <td :colspan="(datesInRange.length * 2) + 2" class="text-center">
                Brak elementów do wyświetlenia&hellip;
              </td>
            </tr>
            <tr v-else>
              <td
                colspan="2"
                class="align-middle border-right bg-light first-sticky-col col-shadow">
                Suma
              </td>
              <td
                v-for="(date, dateIndex) in datesInRange"
                :key="'execution-sum-' + dateIndex"
                :class="getExecutionCellClass(dateIndex)">
                <label class="table-mobile-label mb-1">Wykonanie</label>
                {{ dailyExecutionsSum[date] }}
              </td>
              <td class="align-middle text-right border-right col-success">
                <label class="table-mobile-label mb-1">Suma</label>
                {{ dailyExecutionsSum.sum }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </d-card>
  </d-container>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import FormUtils from '../utils/FormUtils.js';
import CalendarUtils from '@/utils/CalendarUtils.js';
import ListUtils from '../utils/ListUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import HasFilters from '@/mixins/HasFilters.vue';
import qs from 'qs';

export default {
  name: 'Statistics',
  mixins: [
    HasFilters
  ],
  components: {
    'date-range-picker': DateRangePicker
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.loadedWorkers;
    },
    datesInRange () {
      const dates = [];
      let currentDate = new Date(this.filters.period.startDate);

      while (currentDate <= this.filters.period.endDate) {
        dates.push(CalendarUtils.formatDateString(new Date(currentDate)));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },
    dailyExecutionsSum () {
      const result = {};
      this.datesInRange.forEach(date => {
        result[date] = 0;
      });
      result.sum = 0;

      this.stats.forEach(item => {
        this.datesInRange.forEach(date => {
          if (item.dates[date]) {
            const dateObj = item.dates[date];
            // eslint-disable-next-line no-unused-vars
            Object.entries(dateObj.services).forEach(([serviceId, serviceData]) => {
              result[date] += serviceData.executions;
              result.sum += serviceData.executions;
            });
          }
        });
      });

      result.all = Object.values(result).reduce((sum, value) => sum + value, 0);

      return result;
    }
  },
  watch: {
    'filters.period': {
      handler () {
        this.debouncedLoadFilteredData();
        this.loaded = false;
      },
      deep: true
    }
  },
  data() {
    return {
      allWorkers: [],
      exportInProgress: false,
      filters: {
        period: {
          endDate: null,
          startDate: null
        },
        users: []
      },
      loaded: true,
      loadedWorkers: false,
      loadError: false,
      stats: []
    };
  },
  mounted () {
    this.loadAdditionalData();
    let currentDate = new Date();
    let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    this.filters.period = {
      startDate: firstDayOfMonth,
      endDate: currentDate
    };
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loaded = false;
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/stats/order-machine-execution/items',
        listField: 'stats',
        pagination: false,
        params: {
          date_from: CalendarUtils.formatDateString(this.filters.period.startDate),
          date_to: CalendarUtils.formatDateString(this.filters.period.endDate),
          users: this.filters.users
        }
      });
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/users/items',
        method: 'get',
        outputKey: 'allWorkers',
        loadedKey: 'loadedWorkers',
        errorKey: 'loadError',
        params: {
          pagination: 0,
          where: {
            active: 1,
            type: 'OPERATOR'
          }
        }
      });
    },
    validateFiltersForm () {
      FormUtils.validate(this.$refs['stats-filters-form'], this.debouncedLoadFilteredData);
    },
    showExportError () {
      Vue.swal({
        title: 'Wystąpił błąd podczas eksportu',
        html: 'Spróbuj ponownie',
        icon: 'warning',
        confirmButtonText: 'OK',
        buttonsStyling: true
      });
      this.exportInProgress = false;
    },
    exportToFile () {
      this.exportInProgress = true;

      let params = {
        date_from: CalendarUtils.formatDateString(this.filters.period.startDate),
        date_to: CalendarUtils.formatDateString(this.filters.period.endDate),
        users: this.filters.users
      }

      let paramsFormatted = qs.stringify(params)

      this.$http.post('/api/stats/order-machine-execution/export?' + paramsFormatted, {
        type: 'xls'
      }, {
        responseType: 'blob'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'statystyki.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.downloadingXls = false;
      }).catch(error => {
        console.log(error);
        this.showExportError();
      });
    },
    getExecutionCellClass (dateIndex) {
      let classList = ['align-middle', 'text-right', 'border-right'];

      if (dateIndex % 2 === 0) {
        classList.push('day-even');
      } else {
        classList.push('day-odd');
      }
      return classList.join(' ');
    },
    getServiceExecutionsForDate (user, date, serviceID) {
      if (!user.dates[date] || !user.dates[date].services || !user.dates[date].services[serviceID]) {
        return 0;
      }

      return user.dates[date].services[serviceID].executions;
    },
    getUserServiceExecutionSum (user, serviceId) {
      let executionsSum = 0;
      let datesOfWork = Object.keys(user.dates);

      for (let i = 0; i < datesOfWork.length; i++) {
        let serviceForDay = user.dates[datesOfWork[i]].services[serviceId];
        executionsSum += (serviceForDay ? serviceForDay.executions : 0)
      }

      return executionsSum;
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        period: {
          endDate: null,
          startDate: null
        },
        users: []
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
  }
};

</script>

<style lang="scss">
.page-filters form {
  width: 100%;
}

@media screen  and (max-width: 1230px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      input {
        margin-right: 0!important;
        min-width: unset!important;
        width: 100%;
      }
    }
  }
}

@media screen  and (max-width: 768px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      .v-select {
        margin-right: 0!important;
        max-width: unset!important;
        width: 100%!important;
      }
    }
  }
}

@import '@/assets/scss/views/schedule.scss';
</style>
