<template>
  <div class="order-creator-service-sample-single">
    <d-link
      class="dropdown-toggle"
      v-d-toggle="'product-' + productIndex + '-service-' + serviceIndex +'-sample-' + sampleIndex + '-collapse'">
      <d-row
        class="pl-4 py-2 border-bottom mx-0 my-0"
        style="background: #F5F6F8; border-radius: 0.625rem 0.625rem 0 0;">
        <h6
          class="mb-0 d-flex align-items-center flex-wrap"
          style="width: 100%">
          <span
            class="col-sm-12 col-md-4"
            style="text-wrap: balance;">
            Próba z dnia {{ prepareDateString(created_at) }}
          </span>
          <span
            class="col-sm-12 col-md-4"
            style="text-wrap: balance;">
            Nazwa: {{ name || '-' }}
          </span>
          <span
            class="col-sm-12 col-md-3"
            style="text-wrap: balance;">
            Status:
            <span
              :class="{
                'text-success': status === 'APPROVED',
                'text-warning': status === 'WAITING',
                'text-danger': status === 'REJECTED'
              }">
              {{ status ? statusOptions.find(item => item.value === status).text : '-' }}
            </span>
          </span>
        </h6>
        <d-button
          v-if="!id"
          theme="danger"
          outline
          class="ml-auto d-inline-block mr-4"
          @click.prevent.stop="removeSample()">
          <i class="material-icons">delete</i>
          Usuń
        </d-button>
      </d-row>
    </d-link>
    <d-collapse
      visible
      :id="'product-' + productIndex + '-service-' + serviceIndex +'-sample-' + sampleIndex + '-collapse'">
      <div class="d-flex pb-3 px-2 align-items-start product-info flex-wrap">
        <d-row class="mt-2 w-100">
          <d-col sm="6" md="6" lg="3">
            <div class="form-group">
              <label :for="'service-sample-name-' + productIndex + '-' + serviceIndex + '-' + sampleIndex">
                Nazwa próby:
              </label>

              <validation-provider
                :name="'service-sample-name-' + productIndex + '-' + serviceIndex + '-' + sampleIndex"
                v-slot="{ errors }">
                <d-input
                  :disabled="isReadOnly"
                  type="text"
                  :state="errors.length ? 'invalid' : null"
                  v-model="name" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col sm="6" md="6" lg="3">
            <div class="form-group">
              <label :for="'service-sample-preparation-' + productIndex + '-' + serviceIndex + '-' + sampleIndex">
                Czas przygotowania próby:
              </label>

              <validation-provider
                rules="only-natural-numbers"
                :name="'service-sample-preparation-' + productIndex + '-' + serviceIndex + '-' + sampleIndex"
                v-slot="{ errors }">
                <d-input-group
                  append="min."
                  :class="{'is-invalid': errors.length}">
                  <d-input
                    min="0"
                    :disabled="isReadOnly"
                    class="text-right"
                    v-model="preparation_time"
                    :state="errors.length ? 'invalid' : null"
                    type="number" />
                </d-input-group>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col sm="6" md="6" lg="3">
            <div class="form-group">
              <label :for="'service-sample-over-55-' + productIndex + '-' + serviceIndex + '-' + sampleIndex">
                Haft wyższy niż 55mm:
              </label>
              <d-checkbox
                :disabled="isReadOnly"
                toggle
                toggle-small
                class="d-block my-1"
                inline
                v-model="embroidery_over_55"/>
            </div>
          </d-col>
          <d-col sm="6" md="6" lg="3">
            <div class="form-group">
              <label :for="'service-sample-status-' + productIndex + '-' + serviceIndex + '-' + sampleIndex">
                Status:
              </label>
              <validation-provider
                :name="'service-sample-status-' + productIndex + '-' + serviceIndex + '-' + sampleIndex"
                :rules="isStatusInvalid ? 'is_not:APPROVED' : ''"
                :key="'sample-validation-' + productIndex + '-' + serviceIndex + '-' + sampleIndex"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length || isStatusInvalid,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :options="statusOptions"
                    v-model="status"
                    :reduce="status => status.value"
                    :disabled="isReadOnly"
                    placeholder="Wybierz status"
                    label="text">
                  </v-select>
                </div>
                <div v-if="isStatusInvalid" class="invalid-feedback">
                  Można zatwierdzić tylko jedną próbę zdobienia
                </div>
              </validation-provider>
            </div>
          </d-col>
        </d-row>

        <d-row class="w-100">
          <d-col sm="12" md="6">
            <div class="form-group">
              <d-checkbox
                inline
                v-model="isAdditionalFieldsEnabled"
                :disabled="isReadOnly"
                toggle
                toggle-small>
                Konfiguracja inna niż w zamówieniu
              </d-checkbox>
            </div>
          </d-col>
          <d-col
            v-if="sample.reject_reason && sample.reject_reason.length"
            sm="12"
            md="6">
            <div class="form-group">
              <label for="description">
                Uwagi z HPL:
              </label>
              <p>{{ sample.reject_reason }}</p>
            </div>
          </d-col>
        </d-row>

        <d-row
          v-if="isAdditionalFieldsEnabled"
          class="mt-2 w-100">
          <d-col sm="6" md="3">
            <div class="form-group">
              <label :for="'service-sample-nr-dst-' + productIndex + '-' + serviceIndex + '-' + sampleIndex">
                Numer DST:
              </label>

              <validation-provider
                :name="'service-sample-nr-dst-' + productIndex + '-' + serviceIndex + '-' + sampleIndex"
                v-slot="{ errors }">
                <d-input
                  :disabled="isReadOnly"
                  :state="errors.length ? 'invalid' : null"
                  v-model="dst_number" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col sm="6" md="3">
            <div class="form-group">
              <label :for="'service-sample-nr-stitch-' + productIndex + '-' + serviceIndex + '-' + sampleIndex">
                Liczba ściegów:
              </label>

              <validation-provider
                rules="only-natural-numbers"
                :name="'service-sample-nr-stitch-' + productIndex + '-' + serviceIndex + '-' + sampleIndex"
                v-slot="{ errors }">
                <d-input
                  :disabled="isReadOnly"
                  class="text-right"
                  type="number"
                  :min="1"
                  :step="1"
                  :state="errors.length ? 'invalid' : null"
                  v-model="stitch_number" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>

          <d-col md="6">
            <div class="form-group">
              <strand-select
                :ref="`${productIndex}-${serviceIndex}-sample-${sampleIndex}-strand-select`"
                :all-strands="allStrands"
                :current-strands="strands"
                :is-disabled="isReadOnly"
                :is-read-only="false"
                :index="`${productIndex}-${serviceIndex}-${sampleIndex}`" />
            </div>
          </d-col>
        </d-row>

        <d-row class="mt-2 w-100">
          <d-col>
            Zdjęcia podglądów:
          </d-col>
        </d-row>

        <template v-if="id">
          <d-row class="mt-2 w-100">
            <d-col class="12">
              <table class="table mt-2 mb-0">
                <thead class="bg-light">
                  <tr>
                    <th scope="col" class="border-0 text-left" style="width: 100px">Zdjęcie</th>
                    <th scope="col" class="border-0 text-left">Nazwa pliku</th>
                    <th scope="col" class="border-0 text-center">Dodano</th>
                    <th scope="col" class="border-0 text-center">Usunięto</th>
                    <th scope="col" class="border-0 align-middle text-center" style="width: 110px">Akcje</th>
                  </tr>
                </thead>
                <tbody
                  v-if="images.length"
                  style="background: #fff;">
                  <tr
                    v-for="(image, imgIndex) of images"
                    :key="'sample-item-' + productIndex + '-' + serviceIndex + '-' + sampleIndex + '-image' + imgIndex">
                    <td class="align-middle">
                      <img
                        :src="image.file_url"
                        height="80"
                        width="80"
                        :alt="image.file"
                        style="object-fit: contain;" />
                    </td>
                    <td class="align-middle text-left">
                      {{ image.file }}
                    </td>
                    <td class="align-middle text-center">
                      <label class="table-mobile-label mb-1">Dodano</label>
                      <span>{{ prepareDateTimeString(image.created_at) }}</span><br>
                      <small>Przez:
                        <template v-if="image.created_by_name">
                          <strong>{{ image.created_by_name }}</strong>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </small>
                    </td>
                    <td class="align-middle text-center">
                      <label class="table-mobile-label mb-1">Usunięto</label>
                      <template v-if="image.deleted_at && image.deleted_at.length">
                        <span>{{ prepareDateTimeString(image.deleted_at) }}</span><br>
                        <small>Przez:
                            <strong>{{ image.deleted_by_name|| '-' }}</strong>
                        </small>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td class="align-middle text-center">
                      <d-button
                        theme="danger"
                        :disabled="getIsDeleteDisabled(image)"
                        @click.prevent="removeSampleImage(imgIndex)">
                        <i class="material-icons">delete</i>
                        Usuń
                      </d-button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="!images.length">
                  <tr>
                    <td colspan="5" class="text-center">
                      Brak zdjęć dla tej próby...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5" class="text-center">
                      Trwa wczytywanie zdjęć...
                    </td>
                  </tr>
                </tbody>
              </table>
            </d-col>
          </d-row>
          <d-row
            v-if="!isReadOnly"
            class="w-100 mt-3">
            <d-col sm="12">
              <vue-dropzone
                :ref="'vueDropzone-' + sampleIndex + '-product-' + productIndex + '-service-' + serviceIndex"
                :id="'dropzone-' + sampleIndex + '-product-' + productIndex + '-service-' + serviceIndex"
                :options="dropZoneConfig"
                @vdropzone-mounted="setCustomUrl()"
                @vdropzone-success="setSampleImage">
              </vue-dropzone>
            </d-col>
          </d-row>
        </template>
        <template v-else>
          <d-row>
            <d-col>
              <span class="text-danger">Zdjęcia można dodawać tylko do zapisanych prób.</span>
            </d-col>
          </d-row>
        </template>
      </div>
    </d-collapse>
  </div>
</template>

<script>
import Vue from 'vue';
import ListUtils from '@/utils/ListUtils.js';
import StrandSelect from '@/components/forms/order/StrandSelect.vue';
import vueDraggable from 'vuedraggable';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import SampleStatuses from '@/data/sample-statuses.js';

export default {
  name: 'order-creator-service-single-sample',
  props: [
    'sample',
    'sampleIndex',
    'productIndex',
    'serviceIndex',
    'allStrands',
    'isReadOnly',
    'acceptedSamples'
  ],
  components: {
    'strand-select': StrandSelect,
    'vue-dropzone': vue2Dropzone,
    'draggable': vueDraggable
  },
  computed: {
    statusOptions () {
      return SampleStatuses;
    },
    dropZoneConfig () {
      let addImgEndpoint = 'api/orders/samples/add-image/';
      if (this.$http.defaults.baseURL.endsWith('/')) {
        addImgEndpoint = 'api/orders/samples/add-image/';
      }
      return {
        url: this.$http.defaults.baseURL + addImgEndpoint,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        parallelUploads: 1,
        headers: { 'X-Csrf-Token': this.$http.defaults.headers['X-Csrf-Token'] },
        paramName: 'file',
        dictDefaultMessage: 'Upuść zdjęcia tutaj...',
      };
    },
    availableStrandColors () {
      if (this.allStrands) {
        return this.allStrands.map(strand => strand.name);
      }

      return [];
    },
    isStatusInvalid () {
      if (this.acceptedSamples.length > 1 && this.acceptedSamples.indexOf(this.id) >= 0) {
        return true;
      }

      return false;
    }
  },
  watch: {
    status (newValue, oldValue) {
      if (oldValue !== newValue && newValue === 'APPROVED') {
        this.$emit('sampleAcceptanceChanged', this.id, true);
        return;
      }

      if (oldValue !== newValue && oldValue === 'APPROVED') {
        this.$emit('sampleAcceptanceChanged', this.id, false);
      }
    }
  },
  data () {
    return {
      dataLoaded: false,
      removePhotoInProgress: false,
      samplesOnLoadStatuses: [],
      id: null,
      order_service_id: null,
      name: '',
      preparation_time: 0,
      embroidery_over_55: false,
      created_at: new Date(),
      status: 'WAITING',
      images: [],
      stitch_number: 0,
      dst_number: null,
      strands: [],
      isAdditionalFieldsEnabled: false
    };
  },
  mounted () {
    this.id = this.sample.id;
    this.order_service_id = this.sample.order_service_id;
    this.name = this.sample.name;
    this.preparation_time = this.sample.preparation_time;
    this.embroidery_over_55 = !!this.sample.embroidery_over_55;
    this.created_at = this.sample.created_at;
    this.status = this.sample.status;
    this.images = this.sample.images;
    this.stitch_number = this.sample.stitch_number;
    this.strands = this.sample.strands || [];
    this.dst_number = this.sample.dst_number;

    this.isAdditionalFieldsEnabled = !!this.sample.dst_number || !!this.sample.stitch_number || !!(this.sample.strands && this.sample.strands.length);
    this.onLoadStatus = this.sample.status;

    this.dataLoaded = true;
  },
  methods: {
    setCustomUrl () {
      let addImgEndpoint = 'api/orders/samples/add-image/';
      if (this.$http.defaults.baseURL.endsWith('/')) {
        addImgEndpoint = 'api/orders/samples/add-image/';
      }

      Vue.nextTick(() => {
        this.$refs['vueDropzone-' + this.sampleIndex + '-product-' + this.productIndex + '-service-' + this.serviceIndex].setOption('url', this.$http.defaults.baseURL + addImgEndpoint + this.id);
      });
    },
    getSample() {
      let serviceStrands = this.strands;
      if (this.$refs[`${this.productIndex}-${this.serviceIndex}-sample-${this.sampleIndex}-strand-select`]) {
        serviceStrands = this.$refs[`${this.productIndex}-${this.serviceIndex}-sample-${this.sampleIndex}-strand-select`].getStrands();
      }
      return {
        id: this.id,
        name: this.name,
        preparation_time: this.preparation_time,
        embroidery_over_55: this.embroidery_over_55 ? 1 : 0,
        status: this.status,
        dst_number: this.dst_number,
        stitch_number: this.stitch_number,
        strands: serviceStrands
      }
    },
    prepareDateString (date) {
      date = new Date(date);

      return [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    prepareDateTimeString (date) {
      date = new Date(date);

      return [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-') + ' ' + [
        date.getHours(),
        date.getMinutes()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join(':');
    },
    setSampleImage (file, response) {
      this.images.push(response.image);
    },
    removeSampleImage (sampleImageIndex) {
      this.removePhotoInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/orders/samples/delete-image/',
        id: this.images[sampleImageIndex].id,
        successTitle: 'Usunięto zdjęcie próbki',
        successText: 'Wybrane zdjęcie próbki zostało usunięte',
        confirmTitle: 'Czy na pewno chcesz usunąć to zdjęcie próbki?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie zdjęcia próbki nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          Vue.set(this.images[sampleImageIndex], 'deleted_by', this.$store.getters['getUserID']);
          this.removePhotoInProgress = false;
        },
        failAction: () => {
          this.removePhotoInProgress = true;
        }
      });
    },
    removeSample () {
      this.$emit('removeSample', this.sampleIndex);
    },
    getIsDeleteDisabled(image) {
      if (this.isReadOnly) {
        return true;
      }
      return (image.deleted_by !== 0) || this.removePhotoInProgress;
    }
  }
};
</script>

<style lang="scss">
.sample-item .dropdown-toggle {
  position: relative;
  &:after {
    position: absolute;
    left: 3px;
    top: 22px;
  }
}
</style>
