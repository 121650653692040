<template>
  <div>
    <h4 class="pb-3 mb-3 border-bottom d-flex flex-sm-wrap">
      Zwroty do HPL
    </h4>

    <table class="table mt-0 mb-0 full-width">
      <thead class="bg-light">
        <tr>
          <th scope="col" class="border-0 align-middle px-0" style="width: 30px;"></th>
          <th scope="col" class="border-0 align-middle">ID</th>
          <th scope="col" class="border-0 align-middle">Produkty do zwrotu</th>
          <th scope="col" class="border-0 align-middle">Status</th>
          <th scope="col" class="border-0 align-middle text-center" style="width: 200px;">Akcje</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(returnItem, index) of returnsList"
          :key="'return-item-' + index"
          :class="{'table-danger': returnItem.status === 'TO_RETURN'}">
          <td class="align-middle px-0">
            <d-badge
              v-if="returnItem.status === 'TO_RETURN'"
              theme="danger"
              class="px-2 ml-2"
              :title="'Do zwrotu'">
              !
            </d-badge>
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">ID</label>
            {{ returnItem.id }}
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Produkty do zwrotu</label>
            <p
              v-for="(product, productIndex) of returnItem.products"
              :key="`return-${returnItem.id}-product-${productIndex}`"
              class="return-product">
              <small>Kod produktu: <span class="text-primary">{{ product.product_code }}</span></small>
              <small>Ilość: <span class="text-primary">{{ product.quantity }}</span></small>
          </p>
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Status</label>
            <span :class="getStatusClass((returnItem.status))">{{ getStatusName(returnItem.status) }}</span>
          </td>
          <td class="text-center align-middle">
            <d-button
              theme="primary"
              outline
              @click.prevent="downloadLabel(returnItem.id)"
              class="mx-2"
              title="Pobierz etykietę"
              :disabled="isReadonly ||actionInProgress">
              Pobierz etykietę
            </d-button>
            <d-button
              v-if="returnItem.status === 'TO_RETURN'"
              theme="success"
              @click.prevent="returnToHpl(returnItem.id)"
              class="mx-2 mt-3"
              title="Wysłano do HPL"
              :disabled="isReadonly || actionInProgress">
              Wysłano do HPL
            </d-button>
          </td>
        </tr>
        <tr v-if="returnsList.length === 0">
          <td colspan="4" class="text-center">
            Brak elementów do wyświetlenia&hellip;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import ReturnStatuses from '@/data/return-statuses.js';

export default {
  name: 'order-returns-list',
  props: {
    isReadonly: {
      default: true,
      type: Boolean
    },
    returnsList: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      actionInProgress: false
    }
  },
  methods: {
    getStatusName (returnStatus) {
      let statusFound = ReturnStatuses.find(item => item.value === returnStatus);

      if (!statusFound) {
        return '-';
      }

      return statusFound.text;
    },
    getStatusClass (returnStatus) {
      let statusFound = ReturnStatuses.find(item => item.value === returnStatus);

      if (!statusFound || !statusFound.class) {
        return '';
      }

      return statusFound.class;
    },
    downloadLabel (returnID) {
      this.actionInProgress = true;

      this.$http.post('/api/orders/returns/download-label/' + returnID, {}, {
        responseType: 'blob'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        let filenamePrefix = 'etykieta-zwrotu-';
        link.setAttribute('download', filenamePrefix + returnID + '.pdf');
        link.click();
        document.body.removeChild(link);
        this.actionInProgress = false;
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas pobierania',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.actionInProgress = false;
      });
    },
    returnToHpl (returnID) {
      FormUtils.submit(this, {
        endpoint: '/api/orders/returns/confirm-return/' + returnID,
        successTitle: 'Wysłano zwrot',
        successText: 'Wybrany zwrot został oznaczony jako wysłany do HPL',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Akcja nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$bus.$emit('reload-order-view');
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.return-product {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
