<template>
  <div class="row h-100 no-gutters">
    <div
      class="auth-form mx-auto my-auto col-md-6 col-lg-5"
      style="max-width: 535px">
      <div class="card">
        <div class="card-body">
          <h1 style="text-align: center">
            <img
              id="main-logo"
              class="d-inline-block align-top ml-auto mr-auto"
              style="max-width: 266px;"
              src="@/assets/images/logo.jpg"
              alt="Hafciarnia CRM" />
          </h1>
          <d-alert
            v-if="authError"
            theme="danger"
            show>
            Błąd autoryzacji. Spróbuj ponownie
          </d-alert>

          <validation-observer
            ref="password-reset-form"
            tag="form">
            <validation-provider
              rules="email"
              name="email"
              v-slot="{ errors }">
              <div class="form-group">
                <label>Adres e-mail</label>
                <d-input
                  v-model="email"
                  name="email"
                  type="email"
                  :state="errors.length ? 'invalid' : null"
                  placeholder="Twój adres e-mail" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>

            <validation-provider
              rules="required"
              name="password"
              v-slot="{ errors }">
              <div class="form-group">
                <label>Hasło</label>
                <d-input
                  v-model="password"
                  name="password"
                  type="password"
                  autocomplete="off"
                  :state="errors.length ? 'invalid' : null"
                  placeholder="" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </div>
            </validation-provider>

            <button
              type="submit"
              class="btn btn-pill btn-accent d-table mx-auto"
              @click.prevent="login"
              :disabled="submitting">
              <template v-if="!submitting">Zaloguj</template>
              <template v-else>Logowanie&hellip;</template>
            </button>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Login',
  data () {
    return {
      authError: false,
      submitting: false,
      email: '',
      password: ''
    }
  },
  methods: {
    login () {
      this.authError = false;
      this.submitting = true;
      delete this.$http.defaults.headers['X-Csrf-Token'];

      this.$http.post('/api/auth/login', {
        email: this.email,
        password: this.password
      }).then((response) => {
        this.submitting = false;
        this.$http.defaults.headers['X-Csrf-Token'] = `${response.data.csrf_token}`;
        this.$store.commit('setUserID', response.data.id);
        this.$store.commit('setUserEmail', response.data.email);
        this.$store.commit('setUserType', response.data.type);
        this.$store.commit('setUserPermissions', response.data.permissions);
        this.$store.commit('setUserCanStartWork', response.data.can_start);
        this.$store.commit('setUserCanStopWork', response.data.can_end);
        window.localStorage.setItem('last-token-refresh', +new Date());

        Vue.nextTick(() => {
          if (response.data.type === 'ADMIN') {
            this.$router.push('/kokpit');
          } else {
            this.$router.push('/zamowienia');
          }
        });
      }).catch((error) => {
        console.log(error);
        this.submitting = false;
        this.authError = true;
      });
    }
  }
}
</script>
