<template>
  <d-container sm="10">
    <d-row>
      <d-col class="ml-0 pr-0">
        <validation-provider
          name="status"
          rules="required"
          v-slot="{ errors }">
          <div
          :class="{
            'form-control': true,
            'is-select': true,
            'is-invalid': errors.length,
            'vs-wrap-text': true
          }">
            <v-select
              :disabled="!isEdit"
              :options="orderStatusOptions"
              :reduce="(status) => status.status_code"
              v-model="newStatus"
              placeholder="Status zamówienia"
              :clearable="false"
              :searchable="false"
              label="label">
            </v-select>
          </div>
          <div class="invalid-feedback">
            {{ errors[0] }}
          </div>
        </validation-provider>
      </d-col>
      <d-col
        v-if="isStatusChanged"
        sm="5"
        class="my-auto">
        <d-button
          :disabled="!isStatusChanged"
          theme="primary"
          class=" text-sm-center w-100"
          @click.prevent="checkStatusTransition">
          Zapisz status
        </d-button>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import OrderStatuses from '@/data/order-statuses.js';

export default {
  name: 'order-status-select',
  props: [
    'isEdit'
  ],
  computed: {
    orderStatusOptions () {
      let orderStatusesArr = [];
      let keys = Object.keys(OrderStatuses)
      for (let i = 0; i < keys.length; i++) {
        let orderStatus = OrderStatuses[keys[i]];
        orderStatus['status_code'] = keys[i];
        orderStatusesArr.push(orderStatus);
      }
      return orderStatusesArr;
    }
  },
  data () {
    return {
      status: null,
      newStatus: 'NEW',
      orderID: null,
      loadError: false,
      isStatusChanged: false
    }
  },
  watch: {
    newStatus (newValue) {
      if (newValue && newValue !== this.status) {
        this.isStatusChanged = true;
      } else {
        this.isStatusChanged = false;
      }
    }
  },
  methods: {
    setStatus (statusCode, orderID) {
      this.orderID = orderID;
      this.status = statusCode;
      this.newStatus = statusCode;
    },
    checkStatusTransition () {
      Vue.swal({
        title: 'Zmiana statusu',
        text: 'Czy wszystkie zmiany w zamówieniu zostały zapisane? Po tej zmianie statusu strona zamówienia zostanie odświeżona i wszystkie niezapisane zmiany zostaną utracone.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, zmień status',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.submitStatus(true);
        }
      });
    },
    submitStatus () {
      let formDataToSend = {
        status: this.newStatus
      };

      let endpointUrl = '/api/orders/statuses/change/' + this.orderID;

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano status',
        successText: 'Wybrany status został zapisany',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja statusu nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          Vue.set(this, 'status', this.newStatus);
          this.isStatusChanged = false;
          this.$bus.$emit('order-status-changed', this.status);

          window.location.reload();
        }
      });
    }
  }
}
</script>
