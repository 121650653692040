<template>
  <div class="order-creator-service-samples">
    <div
      v-if="dataLoaded">
      <d-container
        v-for="(sample, index) of samples"
        :key="'sample-item-' + sample.uuid"
        :class="{
          'mt-4': true,
          'mb-0': true,
          'mx-0': true,
          'py-0': true,
          'px-0': true,
          'w-100': true,
          'sample-item': true,
          'bg-light': index%2 === 0
        }"
        style="border-radius: 0.625rem; max-width: unset;">

        <order-creator-service-single-sample
          :sample="sample"
          :sampleIndex="index"
          :productIndex="productIndex"
          :serviceIndex="serviceIndex"
          :allStrands="allStrands"
          :isReadOnly="isReadOnly"
          :acceptedSamples="acceptedSamples"
          ref="order-creator-service-single-sample"
          @sampleAcceptanceChanged="onSampleAcceptanceChange"
          @removeSample="removeSample"
        />

      </d-container>
    </div>
    <div
      v-if="!dataLoaded"
      class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import OrderCreatorServiceSingleSample from './OrderCreatorServiceSingleSample.vue';
import UniversalUtils from '@/utils/UniversalUtils.js';

export default {
  name: 'order-creator-service-samples',
  props: [
    'samples',
    'productIndex',
    'serviceIndex',
    'allStrands',
    'isReadOnly'
  ],
  components: {
    'order-creator-service-single-sample': OrderCreatorServiceSingleSample
  },
  data () {
    return {
      acceptedSamples: [],
      dataLoaded: false
    };
  },
  mounted () {
    this.dataLoaded = true;
  },
  methods: {
    addSample () {
      this.samples.push({
        id: null,
        created_at: new Date(),
        dst_number: null,
        embroidery_over_55: false,
        images: [],
        name: '',
        order_service_id: null,
        preparation_time: 0,
        status: 'WAITING',
        stitch_number: 0,
        strands: [],
        uuid: UniversalUtils.generateUUID()
      });

      Vue.nextTick(() => {
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      });
    },
    getSamples() {
      let samplesToSend = [];
      for (let i = 0; i < this.samples.length; i++) {
        samplesToSend.push(this.$refs['order-creator-service-single-sample'][i].getSample());
      }

      return samplesToSend;
    },
    removeSample (sampleIndex) {
      this.samples.splice(sampleIndex, 1);
    },
    onSampleAcceptanceChange (sampleID, isAccepted) {
      let acceptedSampleIndex = this.acceptedSamples.indexOf(sampleID);

      if (acceptedSampleIndex >= 0 && !isAccepted) {
        this.acceptedSamples.splice(acceptedSampleIndex, 1);
        return;
      }

      if (acceptedSampleIndex < 0 && isAccepted) {
        this.acceptedSamples.push(sampleID);
      }
    }
  }
};
</script>

<style lang="scss">
.sample-item .dropdown-toggle {
  position: relative;
  &:after {
    position: absolute;
    left: 3px;
    top: 22px;
  }
}
</style>
