<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zamówienia</span>
        <h3 class="page-title">
          Podgląd zamówienia
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/zamowienia')">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form class="pb-5">
      <div
        v-if="!loaded"
        class="loading-spinner pb-3 pt-3 text-center">
        <span class="loading-spinner-content text-primary">
          Trwa ładowanie danych&hellip;
        </span>
      </div>
      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
      <div v-else-if="loaded">
        <order-controller-product
          v-for="(product, index) of data.products"
          :key="'product-preview-' + index"
          :class="index > 0 ? 'mt-4' : ''"
          :product="product"
          :order-deadline="data.deadline"
          :is-in-qa="false" />
      </div>
    </d-form>
  </d-container>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';
import OrderControllerProduct from '@/components/forms/order/OrderControllerProduct.vue';

export default {
  name: 'order-controller-form-preview',
  components: {
    'order-controller-product': OrderControllerProduct
  },
  data() {
    return {
      data: {
        id: null,
        products: [],
        deadline: ''
      },
      loaded: false,
      loadError: false,
    };
  },
  mounted () {
    this.loadItemData();
  },
  methods: {
    loadItemData (isControlDone = false) {
      if (isControlDone) {
        this.$router.push('/zamowienia');
        return;
      }

      this.loaded = false;

      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/orders/item/',
        instanceFields: ['id', 'products', 'deadline'],
        loadedKey: 'loaded'
      });
    },
  }
};
</script>
