<template>
  <tr
    v-show="isItemVisible"
    :class="rowClassList">
    <td
      :class="{
        'align-middle border-right': true,
        'bg-light': schedule.quantity === schedule.planned_quantity
      }">
      <label class="table-mobile-label mb-1">
        Zamówienie / <span class="text-primary">Zdobienie</span>
      </label>
      {{ schedule.order_id }} - {{ schedule.order_name }}<br>
      <span class="text-primary">{{ schedule.service_name }}</span>
      <div class="details-column">
        <small>produkt: <strong>{{ getCapType(schedule.product_type) }}</strong></small>
        <small>haft wyższy niż 55mm: <strong>{{ schedule.embroidery_over_55 ? 'tak' : 'nie' }}</strong></small>
        <small>liczba ściegów: <strong>{{ schedule.stitch_number }}</strong></small>
        <small :class="{ 'text-danger': isScheduledAfterDeadline, 'text-info': !isScheduledAfterDeadline }">deadline: <strong>{{ schedule.order_deadline }}</strong></small>
        <small>do zrobienia: <strong>{{ schedule.quantity }}</strong></small>
        <small>zaplanowane: <strong>{{ schedule.planned_quantity }}</strong></small>
        <small
          v-if="plannedWithoutDate"
          class="text-danger d-flex">
          zaplanowane bez daty: <strong>{{ plannedWithoutDate }}</strong>
          <i class="material-icons alert-icon ml-1">
            report_problem
          </i>
        </small>
        <small>wykonane: <strong>{{ schedule.execution }}</strong></small>
        <small
          v-if="(schedule.planned_quantity - plannedWithoutDate) >= schedule.quantity"
          class="text-primary">
          zaplanowano na: <strong>{{ getScheduledDatesString() }}</strong>
        </small>
      </div>

      <div class="flex flex-wrap">
        <d-button
          v-if="!isReadonly && ((schedule.planned_quantity - plannedWithoutDate) < schedule.quantity)"
          theme="primary"
          class="text-nowrap mt-2 mr-2"
          size="sm"
          @click.prevent="addScheduleItem">
          <i class="material-icons">add</i>
          Zaplanuj
        </d-button>
        <d-button
          theme="primary"
          outline
          class="text-nowrap mt-2"
          size="sm"
          :disabled="plannedSum < 1 || !firstVisibleScheduleItem"
          @click.prevent="showFirstPlannedDate">
          <template v-if="plannedSum < 1">
            Brak planu
          </template>
          <template v-else-if="!firstVisibleScheduleItem">
            Brak planu w wyświetlanym przedziale
          </template>
          <template v-else>
            Pokaż pierwszy element planu
          </template>
        </d-button>
      </div>
    </td>
    <template v-for="(day, dayIndex) in daysInSchedule">
      <template v-for="(machine, machineIndex) in filteredMachines">
        <td
          v-if="day.machinesVisible.indexOf(machine.id) > -1"
          :key="`schedule-${itemIndex}-machine-${machineIndex}-day-${dayIndex}-${day.date}-${machine.id}`"
          v-on:dblclick="isReadonly ? () => {} : handleDoubleClick($event, day.date, machine.id)"
          scope="col"
          :class="{
            'align-middle py-lg-3 py-md-3 py-3 text-center border-right schedule-cell': true,
            'day-even': dayIndex % 2 === 0,
            'day-odd': dayIndex % 2 !== 0,
            'table-danger': machinesForDaysTime[day.date + '-' + machine.id] && machinesForDaysTime[day.date + '-' + machine.id] > machine.day_working_time
          }">
          <label class="table-mobile-label mb-1">
            {{ day.date }} / {{ machine.name }}
          </label>
          <div class="cell-content">
            <template v-if="getIsDateSmaller(day.date, schedule.order_created_at)">
              <d-alert theme="light" show class="my-auto">Data przed utworzeniem zamówienia</d-alert>
            </template>
            <template v-else-if="schedule.supported_machines.indexOf(machine.id) < 0">
              <d-alert theme="warning" show class="my-auto">Maszyna nie obsługuje zdobienia</d-alert>
            </template>
            <template v-else-if="getDataForDateAndMachine(schedule, day.date, machine.id)">
              <div v-if="isReadonly" class="schedule-plan-item my-auto">
                <div class="input-group text-right flex-column">
                  <span class="ml-auto mb-1">
                    <strong class="text-primary">{{ getDataForDateAndMachine(schedule, day.date, machine.id).planned_quantity }}</strong> .szt
                  </span>
                </div>
              </div>
              <lazy-wrapper
                v-else
                :key="`schedule-${itemIndex}-machine-${machine.id}-day-${day.date}-plan-wrapper`"
                :additional-class="'schedule-plan-item my-auto'"
                :min-height="30"
                :render-on-idle="false"
                :unrender="true">
                <schedule-plan
                  :ref="`schedule-${itemIndex}-machine-${machine.id}-day-${day.date}-plan`"
                  :day="day.date"
                  :is-readonly="isReadonly"
                  :item-index="itemIndex"
                  :machine="machine"
                  :machine-for-day-scheduled="machinesForDaysTime[day.date + '-' + machine.id] || 0"
                  :machines-plan-and-execution-amount="machinesPlanAndExecutionAmount"
                  :schedule="schedule"
                  :schedule-item="getDataForDateAndMachine(schedule, day.date, machine.id)" />
              </lazy-wrapper>
            </template>
            <template v-else>
              <span class="table-mobile-label my-auto">
                brak planu
              </span>
            </template>
            <small
              class="schedule-plan-execution text-right mb-0"
              v-pure-html="getExecutionForCell(day.date, machine.id)">
            </small>
          </div>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import Vue from 'vue';
import SchedulePlan from '@/components/forms/schedule/SchedulePlan.vue';
import CalendarUtils from '@/utils/CalendarUtils.js';
import LazyWrapper from '@/components/common/LazyWrapper.vue';

export default {
  name: 'ScheduleItem',
  components: {
    'schedule-plan': SchedulePlan,
    'lazy-wrapper': LazyWrapper
  },
  props: {
    allMachines: {
      required: true,
      type: Array
    },
    daysInSchedule: {
      required: true,
      type: Array
    },
    filters: {
      default: () => {},
      type: Object
    },
    filteredMachines: {
      required: true,
      type: Array
    },
    isReadonly: {
      default: false,
      type: Boolean
    },
    itemIndex: {
      required: true,
      type: Number
    },
    machinesForDaysTime: {
      default: () => {},
      type: Object
    },
    productTypeOptions: {
      required: true,
      type: Array
    },
    schedule: {
      required: true,
      type: Object
    }
  },
  watch: {
    plannedSum (newVal) {
      this.schedule.planned_quantity = newVal;
    },
    filters: {
      handler () {
        this.setIsItemVisible();
      },
      deep: true
    }
  },
  computed: {
    plannedSum () {
      return this.schedule.items.reduce((sum, item) => sum + Number(item.planned_quantity), 0);
    },
    isScheduledAfterDeadline () {
      let deadlineDate = new Date(this.schedule.order_deadline);
      let scheduleItemAfter = this.schedule.items.find(item => new Date(item.date) > deadlineDate);

      return !!scheduleItemAfter;
    },
    rowClassList () {
      let classList = [];
      if ((this.schedule.quantity !== this.schedule.planned_quantity) || this.schedule.execution < this.schedule.planned_quantity) {
        classList.push('table-danger');
      } else if (!this.isScheduledAfterDeadline && this.schedule.quantity === this.schedule.execution) {
        classList.push('table-success');
      }

      return classList;
    },
    firstVisibleScheduleItem () {
      if (this.plannedSum < 1) {
        return null;
      }

      let scheduleItemsCopy = JSON.parse(JSON.stringify(this.schedule.items));
      scheduleItemsCopy = scheduleItemsCopy.filter(item => this.daysInSchedule.find(day => day.date === item.date && day.machinesVisible.indexOf(item.machine_id) > -1));

      if (!scheduleItemsCopy.length) {
        return null;
      }

      scheduleItemsCopy.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
      return scheduleItemsCopy[0];
    },
    machinesPlanAndExecutionAmount () {
      let output = {};

      for (let i = 0; i < this.allMachines.length; i++) {
        let machineId = this.allMachines[i].id;
        let executionsAmount = this.schedule.executions.filter(item => item.machine_id === machineId).reduce((sum, exec) => sum + exec.execution, 0);
        let plannedAmount = this.schedule.items.filter(item => item.machine_id === machineId).reduce((sum, plan) => sum + plan.planned_quantity, 0);

        output[machineId] = {
          executions: executionsAmount,
          plannedQuantity: plannedAmount
        }
      }

      return output;
    },
    plannedWithoutDate () {
      return this.schedule.items.filter(item => !item.date).reduce((sum, item) => sum + item.planned_quantity, 0);
    }
  },
  data () {
    return {
      isItemVisible: true
    }
  },
  mounted () {
    this.setIsItemVisible();
  },
  methods: {
    getDataForDateAndMachine (serviceData, date, machineID) {
      return serviceData.items.find(item => item.machine_id === machineID && item.date === date);
    },
    addScheduleItem () {
      let firstPlanWithoutDates = this.schedule.items.find(item => !item.date);
      this.$bus.$emit('add-schedule-popup-show', this.schedule, this.itemIndex, this.machinesPlanAndExecutionAmount, firstPlanWithoutDates);
    },
    handleDoubleClick (e, day, machineID) {
      let existingItem = this.$refs[`schedule-${this.itemIndex}-machine-${machineID}-day-${day}-plan`];
      if (existingItem && existingItem[0]) {
        existingItem[0].enableEditMode();
        return;
      }
      let newPlanData = {
        date: day,
        execution: 0,
        machine_id: machineID,
        order_service_id: this.schedule.order_service_id,
        ordering: this.schedule.ordering,
        planned_quantity: 0,
        edit_mode: true
      };
      this.$bus.$emit('schedule-items-add', this.itemIndex, newPlanData);
    },
    getCapType (capType) {
      if (!capType) {
        return '-';
      }
      let productType = this.productTypeOptions.find(cap => cap.value === capType);
      if (productType && productType.text) {
        return productType.text;
      }

      return '-';
    },
    setIsItemVisible () {
      if (this.filters.machineID) {
        if (!this.schedule.items.length) {
          Vue.set(this, 'isItemVisible', false);
          return;
        }

        let isVisible = this.schedule.items.find(item => item.machine_id === this.filters.machineID);
        if (!isVisible) {
          Vue.set(this, 'isItemVisible', false);
          return;
        }
      }

      if (this.filters.search) {
        if ((this.schedule.order_name.toLowerCase().indexOf(this.filters.search) < 0) && String(this.schedule.order_id).indexOf(this.filters.search) < 0) {
          Vue.set(this, 'isItemVisible', false);
          return;
        }
      }

      if (this.filters.dateFrom && this.filters.dateTo) {
        let dateOutsideOfBounds = this.schedule.items.find(item => {
          if (!item.planned_quantity && !item.execution) {
            return false;
          }

          return !this.getIsDayVisible(item.date);
        })

        if (dateOutsideOfBounds) {
          Vue.set(this, 'isItemVisible', false);
          return;
        }

        let dateInsideOfBounds = this.schedule.items.find(item => {
          if (!item.planned_quantity && !item.execution) {
            return false;
          }

          return this.getIsDayVisible(item.date);
        })

        if (!dateInsideOfBounds) {
          Vue.set(this, 'isItemVisible', false);
          return;
        }
      }

      if (this.filters.scheduleItemState) {
        if (this.filters.scheduleItemState === 'isPlanned') {
          if (this.schedule.quantity !== this.schedule.planned_quantity) {
            Vue.set(this, 'isItemVisible', false);
            return;
          }
        } else if (this.filters.scheduleItemState === 'withErrors') {
          if (!this.isScheduledAfterDeadline && (this.schedule.quantity === this.schedule.planned_quantity)) {
            Vue.set(this, 'isItemVisible', false);
            return;
          }
        } else if (this.filters.scheduleItemState === 'withoutDates') {
          if (!(this.schedule.items.find(item => !item.date))) {
            Vue.set(this, 'isItemVisible', false);
            return;
          }
        }
      }

      Vue.set(this, 'isItemVisible', true);
    },
    getIsDayVisible (date) {
      let dayDate = new Date(date);
      return CalendarUtils.getIsDateInRange(dayDate, this.filters.dateFrom, this.filters.dateTo);
    },
    showFirstPlannedDate () {
      if (!this.firstVisibleScheduleItem) {
        return;
      }
      this.$bus.$emit('scroll-to-day', this.firstVisibleScheduleItem.date, this.firstVisibleScheduleItem.machine_id);
    },
    getExecutionForCell (day, machineID) {
      let executionFound = this.schedule.executions.find(item => item.realization_date === day && item.machine_id === machineID);
      if (!executionFound) {
        return '';
      }
      return `Wykonano <strong class="text-primary">${executionFound.execution}</strong> szt.`;
    },
    getIsDateSmaller (date1, date2) {
      return CalendarUtils.getIsDayBeforeDate(date1, date2);
    },
    getScheduledDatesString () {
      return [...new Set(this.schedule.items.map(item => item.date))].join('; ');
    }
  }
}
</script>
