<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Cennik</span>
        <h3 class="page-title">
          <template v-if="$route.meta.type === 'services'">Lista cen zdobień</template>
          <template v-if="$route.meta.type === 'setups'">Lista cen setup</template>
        </h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">

        <d-button
          v-if="$route.meta.type === 'services'"
          @click.prevent="openImportPopup"
          theme="primary"
          class="mr-2">
          <i class="material-icons">
            cloud_upload
          </i>
          Importuj cenniki
        </d-button>

        <d-button
          v-if="$route.meta.type === 'services'"
          :disabled="exportInProgress"
          @click.prevent="exportPrices"
          theme="secondary"
          class="mr-2">
          <i class="material-icons">
            cloud_download
          </i>
          Eksportuj cenniki
        </d-button>

        <d-button
          v-if="$route.meta.type === 'services'"
          theme="success"
          @click.prevent="$router.push('/cenniki/zdobienia/nowy')">
          <i class="material-icons">add</i>
          Dodaj nową cenę zdobienia
        </d-button>
        <d-button
          v-if="$route.meta.type === 'setups'"
          theme="success"
          @click.prevent="$router.push('/cenniki/setup/nowy')">
          <i class="material-icons">add</i>
          Dodaj nową cenę setup
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="loaded"
      style="width: 100%;"
      class="d-flex ml-0 mb-0 page-filters">
      <validation-observer
        ref="prices-filters-form"
        tag="form"
        class="d-flex mb-2">
        <d-input
          placeholder="Podaj nazwę lub kod produktu"
          v-model="filters.search"
          class="mr-1 mb-2"
          style="min-width: 290px;" />

        <v-select
          style="width: 360px; min-width: 250px; max-width: 30%;"
          class="mr-1 mb-2 services-select"
          :options="allServices"
          v-model="filters.service_id"
          :reduce="(service) => service.id"
          placeholder="Wybierz zdobienie"
          :searchable="true"
          :disabled="!loadedServices"
          :filterBy="filterByForServices"
          label="name"
          @search="debouncedFetchServices">
          <template slot="no-options">
            <template v-if="!servicesEmptyResults">Trwa wyszukiwanie&hellip;</template>
            <template v-else>Brak zdobień pasujących do podanej frazy&hellip;</template>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}<br>
              <small>kod: {{ option.code }}</small>
            </div>
          </template>
          <template
            slot="selected-option"
            slot-scope="option">
            <div class="selected d-center">
              {{ option.name }} <small>kod: {{ option.code }}</small>
            </div>
          </template>
        </v-select>

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-md-auto mr-1 mb-2"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%;">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle">Zdobienie</th>
            <th scope="col" class="border-0 align-middle text-right">Cena</th>
            <th
              v-if="$route.meta.type === 'services'"
              scope="col"
              class="border-0 align-middle text-right pr-lg-5">
              Ilość od
            </th>
            <th
              v-if="$route.meta.type === 'setups'"
              scope="col"
              class="border-0 align-middle text-right pr-lg-5">
              Ilość do
            </th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="loaded">
          <tr
            v-for="(price, index) of prices"
            :key="'price-' + index">
            <td class="align-middle py-lg-3 py-md-3 py-3">
              Kod: <strong>{{ price.service && price.service.code ? price.service.code : '-' }}</strong>
              <br>
              <small>Nazwa: <strong>{{ price.service && price.service.name ? price.service.name : '-' }}</strong></small>
            </td>
            <td class="align-middle text-right py-lg-3 py-md-3 py-3">
              {{ Number(price.price).toFixed(2) }} zł
            </td>
            <td class="align-middle text-right py-lg-3 py-md-3 py-3 pr-lg-5">
              {{ price.value_from }} szt.
            </td>
            <td
              class="align-middle text-center py-lg-3 py-md-3 py-3"
              style="width: 150px;">
              <d-button
                theme="primary"
                @click.native="$router.push(editUrl + price.id)"
                class="mr-2 mx-2 mt-2"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
              <d-button
                theme="danger"
                :disabled="removeInProgress"
                @click.native="removePrice(price.id)"
                class="mx-2 mt-2"
                title="Usuń">
                <i class="material-icons">delete</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="prices.length === 0">
            <td
              colspan="5"
              class="text-center">
              Brak cen do wyświetlenia&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!loaded" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>

    <pagination :meta-data="paginationMetaData" />

    <prices-import-popup
      v-if="showImportPopup" />

  </d-container>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import FormUtils from '../utils/FormUtils.js';
import HasFilters from '../mixins/HasFilters.vue';
import HasPagination from '../mixins/HasPagination.vue';
import ListUtils from '../utils/ListUtils.js';
import Pagination from '../components/common/Pagination.vue';
import PricesImportPopup from './../components/popups/PricesImportPopup.vue';
import UniversalUtils from '@/utils/UniversalUtils.js';

export default {
  name: 'prices',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination,
    'prices-import-popup': PricesImportPopup
  },
  computed: {
    editUrl () {
      if (this.$route.meta.type === 'services') {
        return '/cenniki/zdobienia/edycja/';
      }

      if (this.$route.meta.type === 'setups') {
        return '/cenniki/setup/edycja/';
      }

      return '';
    }
  },
  data() {
    return {
      allServices: [],
      exportInProgress: false,
      loaded: false,
      loadedServices: false,
      loadError: false,
      prices: [],
      removeInProgress: false,
      servicesEmptyResults: false,
      showExportPopup: false,
      showImportPopup: false,
      filters: {
        search: '',
        service_id: ''
      }
    };
  },
  mounted () {
    this.loadFilteredData();
    this.loadAdditionalData();
    this.$bus.$on('prices-import-popup-close', this.closeImportPopup);
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.search) {
        where.search = this.filters.search;
      }

      if (this.filters.service_id) {
        where.service_id = this.filters.service_id;
      }

      if (this.$route.meta.type === 'services') {
        where.is_setup = 0;
      }

      if (this.$route.meta.type === 'setups') {
        where.is_setup = 1;
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/services/prices/items?page=' + this.currentPage,
        listField: 'prices',
        pagination: true,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.filters = {
        search: '',
        service_id: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['prices-filters-form'], this.debouncedLoadFilteredData);
    },
    removePrice (priceID) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/services/prices/delete/',
        id: priceID,
        successTitle: 'Usunięto cenę',
        successText: 'Wybrana cena została usunięta',
        confirmTitle: 'Czy na pewno chcesz usunąć tę cenę?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie ceny nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        },
        failAction: () => {
          this.removeInProgress = false;
        }
      });
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/items',
        method: 'get',
        outputKey: 'allServices',
        loadedKey: 'loadedServices',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    },
    filterByForServices (option, label, search) {
      return (option.name + ' ' + option.code).toLocaleLowerCase('pl').indexOf(search.toLocaleLowerCase('pl')) > -1;
    },
    fetchServices (searchPhrase, loadingCallback) {
      this.servicesEmptyResults = false;
      loadingCallback(true);

      let endpointUrl = '/api/services/items';
      let responseAsArray = false;
      let paramsData = {};

      if (searchPhrase !== '') {
        paramsData.where = {
          search: searchPhrase
        };
      }

      if (this.filters.service_id !== 0 && this.filters.service_id !== null && searchPhrase === '') {
        endpointUrl = '/api/services/item/' + parseInt(this.filters.service_id, 10);
        responseAsArray = true;
      }

      FormUtils.loadAdditionalData(this, {
        method: 'get',
        endpoint: endpointUrl,
        outputKey: 'allServices',
        errorKey: 'loadError',
        params: paramsData,
        wrapResponseIntoArray: responseAsArray,
        successAction: (response) => {
          if (!response.length) {
            this.servicesEmptyResults = true;
          }

          loadingCallback(false);
        }
      });
    },
    // eslint-disable-next-line
    debouncedFetchServices: debounce(function (searchPhrase, loadingCallback) {
      this.fetchServices(searchPhrase, loadingCallback);
    }, 500),
    exportPrices () {
      this.exportInProgress = true;

      Vue.swal({
        title: 'Czy na pewno chcesz wyeksportować cennik?',
        text: 'Pobrany plik będzie miał format .xlsx',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, pobierz',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.$http.get('/api/services/prices/export', {
            responseType: 'blob'
          }).then(response => {
            if (response.status === 200) {
              UniversalUtils.downloadFile(response.data, 'cenniki.xlsx');

              Vue.swal({
                title: 'Cennik pobrany',
                html: 'Plik cenniki.xlsx zawierający cennik został pobrany.',
                icon: 'success',
                confirmButtonText: 'OK',
                buttonsStyling: true
              }).then(() => {
                this.exportInProgress = false;
              }).catch((errors) => {
                console.log(errors);
              });
            } else {
              Vue.swal({
                title: 'Wystąpił błąd podczas eksportu',
                html: 'Spróbuj ponownie',
                icon: 'warning',
                confirmButtonText: 'OK',
                buttonsStyling: true
              });
              this.exportInProgress = false;
            }
          }).catch(error => {
            console.log(error);
            Vue.swal({
              title: 'Wystąpił błąd podczas eksportu',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            this.exportInProgress = false;
          });
        } else {
          this.exportInProgress = false;
        }
      });
    },
    openImportPopup () {
      this.showImportPopup = true;
    },
    closeImportPopup () {
      this.showImportPopup = false;
      this.loadFilteredData();
    }
  },
  beforeDestroy () {
    this.$bus.$off('prices-import-popup-close', this.closeImportPopup);
  }
};
</script>

<style lang="scss">
.page-filters form {
  width: 100%;
}

@media screen  and (max-width: 1230px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      input {
        margin-right: 0!important;
        min-width: unset!important;
        width: 100%;
      }
    }
  }
}

@media screen  and (max-width: 768px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      .v-select {
        margin-right: 0!important;
        max-width: unset!important;
        width: 100%!important;
      }
    }
  }
}

</style>
