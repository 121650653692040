<template>
  <div>
    <h4
      v-if="!isOnDashboard"
      class="pb-3 mb-3 border-bottom d-flex flex-sm-wrap">
      Historia synchronizacji
      <d-button
        v-if="isVisible"
        theme="success"
        class="ml-auto"
        :disabled="isReadonly || !logsList.length || !logsList.find(item => !item.is_read)"
        @click.prevent="markLogsAsRead">
        Przeczytałem wszystkie
      </d-button>
    </h4>

    <p
      v-if="!isVisible"
      class="text-center mb-0">
      Dane o historii kontroli produktów będą dostępne po zapisaniu zamówienia&hellip;
    </p>
    <table
      v-else
      class="table mt-0 mb-0 full-width">
      <thead class="bg-light">
        <tr>
          <th v-if="!isOnDashboard" scope="col" class="border-0 align-middle px-0" style="width: 30px;"></th>
          <th scope="col" class="border-0 align-middle" style="width: 100px;">Data</th>
          <th scope="col" class="border-0 align-middle">Zmiana dotyczy</th>
          <th scope="col" class="border-0 align-middle">Rodzaj zmiany</th>
          <th scope="col" class="border-0 align-middle">Zmienione pole</th>
          <th scope="col" class="border-0 align-middle">Obecna wartość</th>
          <th scope="col" class="border-0 align-middle">Poprzednia wartość</th>
          <th v-if="isOnDashboard" scope="col" class="border-0 align-middle text-center" style="width: 100px;">Akcje</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(logItem, index) of logsList"
          :key="'log-item-' + index">
          <td
            v-if="!isOnDashboard"
            class="align-middle px-0">
            <d-badge
              v-if="!logItem.is_read"
              theme="danger"
              class="px-2"
              :title="'Nie przeczytano'">
              !
            </d-badge>
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Data</label>
            <small>{{ formatDateTimeString(logItem.created_at) }}</small>
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Zmiana dotyczy</label>
            <template v-if="logItem.source === 'ORDER'">
              <small>Zamówienia</small>
            </template>
            <template v-else-if="logItem.source === 'PRODUCT'">
              <small>
                Produktu<br>
                <span class="text-primary">
                  {{ logItem.product.product_code }}
                </span>
              </small>
            </template>
            <template v-else-if="logItem.source === 'SERVICE'">
              <small>
                Zdobienia<br>
                <span class="text-primary">
                  {{ logItem.service.service_name }}
                </span>
              </small>
            </template>
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Rodzaj zmiany</label>
            <small
              v-if="validActions.indexOf(logItem.action) > -1"
              :class="availableActions[logItem.action].class">
              {{ availableActions[logItem.action].label }}
            </small>
            <small v-else>—</small>
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Zmienione pole</label>
            <small>
              {{ getModifiedField(logItem) || '—' }}
            </small>
            {{ logItem.filed }}
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Obecna wartość</label>
            <small
              v-if="getModifiedField(logItem)"
              class="text-success">
              {{ logItem.value }}
            </small>
            <small v-else>—</small>
          </td>
          <td class="align-middle">
            <label class="table-mobile-label mb-1">Poprzednia wartość</label>
            <small
              v-if="getModifiedField(logItem)"
              class="text-danger">
              {{ logItem.prev_value }}
            </small>
            <small v-else>—</small>
          </td>
          <td
            v-if="isOnDashboard"
            class="text-center align-middle pt-0">
            <d-button
              theme="primary"
              @click.native="$router.push('/zamowienia/edycja/' + logItem.order_id)"
              class="mx-2 mt-2"
              title="Edytuj">
              <i class="material-icons">edit</i>
            </d-button>
          </td>
        </tr>
        <tr v-if="logsList.length === 0">
          <td colspan="7" class="text-center">
            Brak elementów do wyświetlenia&hellip;
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="isActionInprogress" class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import CalendarUtils from '@/utils/CalendarUtils.js';

export default {
  name: 'order-sync-history',
  props: {
    isOnDashboard: {
      default: false,
      type: Boolean
    },
    isReadonly: {
      default: true,
      type: Boolean
    },
    isVisible: {
      default: true,
      type: Boolean
    },
    logsList: {
      required: true,
      type: Array
    },
    orderId: {
      required: true,
      type: Number
    }
  },
  computed: {
    validActions () {
      return Object.keys(this.availableActions);
    }
  },
  data () {
    return {
      availableActions: {
        ACCEPT_SAMPLE: {
          class: 'text-success',
          label: 'Akceptacja próby',
          value: 'ACCEPT_SAMPLE'
        },
        ADD: {
          class: 'text-success',
          label: 'Dodanie',
          value: 'ADD'
        },
        REJECT_SAMPLE: {
          class: 'text-danger',
          label: 'Odrzucenie próby',
          value: 'REJECT_SAMPLE'
        },
        UPDATE: {
          class: 'text-warning',
          label: 'Aktualizacja',
          value: 'UPDATE'
        },
        UPDATE_FILES: {
          class: 'text-warning',
          label: 'Aktualizacja plików zdobienia',
          value: 'UPDATE_FILES'
        },
        DELETE_IN_HPL: {
          class: 'text-danger',
          label: 'Usunięcie w HPL',
          value: 'DELETE_IN_HPL'
        },
        SKIP: {
          class: 'text-secondary',
          label: 'Pominięta synchronizacja',
          value: 'SKIP'
        }
      },
      availableFields: {
        description: 'Opis',
        embroidery_color: 'Kolory nici',
        embroidery_height: 'Wysokość haftu',
        embroidery_position_name: 'Pozycja dekoracji',
        embroidery_type_name: 'Rodzaj haftu',
        embroidery_width: 'Szerokość haftu',
        order_name: 'Nazwa zamówienia',
        product_code: 'Kod produktu',
        quantity: 'Ilość',
        service_group_name: 'Nazwa zdobienia',
        service_name: 'Nazwa wariantu zdobienia'
      },
      isActionInprogress: false
    }
  },
  methods: {
    formatDateTimeString (dateString) {
      return CalendarUtils.formatDateTimeString(dateString);
    },
    getModifiedField (logItem) {
      if (!(logItem.field in this.availableFields) || logItem.action !== 'UPDATE') {
        return null;
      }

      return this.availableFields[logItem.field];
    },
    markLogsAsRead () {
      Vue.set(this, 'isActionInprogress', true);

      Vue.swal({
        title: 'Oznacz wszystkie jako przeczytane',
        text: 'Czy na pewno chcesz oznaczyć całą historię synchronizacji jako przeczytaną',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          FormUtils.submit(this, {
            endpoint: '/api/orders/mark-logs-as-read/' + this.orderId,
            successTitle: 'Oznaczono jako przeczytane',
            successText: 'Historia synchronizacji została oznaczona jako przeczytana.',
            errorTitle: 'Wystąpił błąd',
            errorText: 'Akcja nie powiodła się. Spróbuj ponownie.',
            successAction: () => {
              Vue.set(this, 'isActionInprogress', false);
              this.$bus.$emit('reload-order-view');
            },
            failAction: () => {
              Vue.set(this, 'isActionInprogress', false);
            }
          });
        } else {
          Vue.set(this, 'isActionInprogress', false);
        }
      });
    }
  }
}
</script>
