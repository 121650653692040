<template>
  <div class="splashscreen">
    <div class="splashscreen-wrapper">
      <span class="splashscreen-text">
        Trwa ładowanie danych&hellip;
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'splashscreen',
  mounted () {
    this.$http.get('/api/auth/me').then((response) => {
      let nextRoute = this.$route.params.nextRoute;

      if (!nextRoute || nextRoute === '/wczytywanie') {
        nextRoute = '/';
      }

      this.$http.defaults.headers['X-Csrf-Token'] = `${response.data.csrf_token}`;
      this.$store.commit('setUserID', response.data.id);
      this.$store.commit('setUserEmail', response.data.email);
      this.$store.commit('setUserType', response.data.type);
      this.$store.commit('setUserPermissions', response.data.permissions);
      this.$store.commit('setUserCanStartWork', response.data.can_start);
      this.$store.commit('setUserCanStopWork', response.data.can_end);
      window.localStorage.setItem('last-token-refresh', +new Date());

      setTimeout(() => {
        this.$router.push(nextRoute);
      }, 1000);
    }).catch(error => {
      console.log(error);

      setTimeout(() => {
        this.$router.push({ name: 'Logowanie' });
      }, 1000);
    });
  }
}
</script>
