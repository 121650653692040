<script>
export default {
  name: 'has-filters',
  data () {
    return {
      initialFilterValues: {}
    };
  },
  beforeMount () {
    this.initialFilterValues = JSON.parse(JSON.stringify(this.filters));
    this.loadFilters();
  },
  mounted () {
    this.$bus.$on('view-filters-save', this.saveFilters);
    this.$bus.$on('view-filters-reset', this.resetFilters);
  },
  methods: {
    loadFilters () {
      if (!localStorage.getItem('hafciarnia-crm-filters')) {
        this.resetFilters();
        return;
      }

      let filtersData = JSON.parse(localStorage.getItem('hafciarnia-crm-filters'));

      if (filtersData.view && filtersData.view === this.$route.path.replace(/^a-z0-9\-\//gmi, '')) {
        this.filters = JSON.parse(JSON.stringify(filtersData.filters));

        if (this.filters.dateFrom) {
          this.dateRange.startDate = new Date(this.filters.dateFrom);
        }

        if (this.filters.dateTo) {
          this.dateRange.endDate = new Date(this.filters.dateTo);
        }
      } else {
        this.resetFilters();
      }
    },
    saveFilters () {
      let filtersData = {
        view: this.$route.path.replace(/^a-z0-9\-\//gmi, ''),
        filters: this.filters
      };
      filtersData = JSON.stringify(filtersData);
      localStorage.setItem('hafciarnia-crm-filters', filtersData);
    },
    resetFilters () {
      this.filters = JSON.parse(JSON.stringify(this.initialFilterValues));
      this.saveFilters();
    }
  },
  beforeDestroy () {
    this.$bus.$off('view-filters-save', this.saveFilters);
    this.$bus.$off('view-filters-reset', this.saveFilters);
  }
}
</script>
