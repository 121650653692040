import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      id: 0,
      email: '',
      permissions: {},
      type: ''
    },
    counters: {
      newOrders: 0,
      scheduleWithoutDate: 0
    }
  },
  actions: {
    logoutUser ({ commit }) {
      commit('setUser', {});
    }
  },
  mutations: {
    setUserID (state, id) {
      Vue.set(state.user, 'id', parseInt(id, 10));
    },
    setUserEmail (state, email) {
      Vue.set(state.user, 'email', email);
    },
    setUserType (state, type) {
      Vue.set(state.user, 'type', type);
    },
    setUserPermissions (state, permissions) {
      Vue.set(state.user, 'permissions', JSON.parse(JSON.stringify(permissions)));
    },
    setUserCanStartWork (state, canStart) {
      Vue.set(state.user, 'can_start', canStart);
    },
    setUserCanStopWork (state, canEnd) {
      Vue.set(state.user, 'can_end', canEnd);
    },
    setScheduleItemsWithoutDateCount (state, count) {
      Vue.set(state.counters, 'scheduleWithoutDate', count);
    },
    setNewOrdersCount (state, count) {
      Vue.set(state.counters, 'newOrders', count);
    }
  },
  getters: {
    getUserID: (state) => state.user.id,
    getUserEmail: (state) => state.user.email,
    getUserType: (state) => state.user.type,
    getUserPermission: (state) => (permission) => !!state.user.permissions[permission],
    getUserCanStartWork: (state) => state.user.can_start,
    getUserCanStopWork: (state) => state.user.can_end,
    getScheduleItemsWithoutDateCount: (state) => state.counters.scheduleWithoutDate,
    getNewOrdersCount: (state) => state.counters.newOrders,
    getCounters: (state) => state.counters
  }
});
