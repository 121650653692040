<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Cennik</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja ceny</template>
          <template v-else>Dodawanie ceny</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push(backUrl)"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadPriceError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadPriceError">
          <d-tabs
            card
            :class="{'is-loading': !allDataLoaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="width-narrow px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <validation-observer
                ref="price-form"
                tag="form">

                <div class="form-group">
                  <label for="name">
                    Zdobienie:
                  </label>

                  <validation-provider
                    name="services"
                    rules="required"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        class=""
                        :options="allServices"
                        v-model="data.service_id"
                        :reduce="(service) => service.id"
                        placeholder="Wybierz zdobienie"
                        :disabled="isEdit"
                        label="name">
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <d-row>
                  <d-col sm="12" md="4" lg="4">
                    <div class="form-group">
                      <label for="price">
                        Cena:
                      </label>

                      <validation-provider
                        rules="required|min_value:1"
                        name="price"
                        type="number"
                        v-slot="{ errors }">
                        <d-input-group
                          append="zł"
                          :class="{'is-invalid': errors.length}">
                          <input
                            :class="{
                            'form-control': true,
                            'is-invalid': errors.length
                            }"
                            :invalid="errors.length ? true : null"
                            type="number"
                            min="0"
                            class="text-right"
                            v-model="data.price"/>
                        </d-input-group>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>

                  <d-col
                    v-if="$route.meta.type === 'services' || $route.meta.type === 'setups'"
                    cols="12" md="4" lg="4">
                    <div class="form-group">
                      <label for="value-from">
                        <template v-if="$route.meta.type === 'services'">Ilość od:</template>
                        <template v-if="$route.meta.type === 'setups'">Ilość do:</template>
                      </label>

                      <validation-provider
                        rules="required|min_value:1|only-natural-numbers"
                        name="value-from"
                        type="number"
                        v-slot="{ errors }">
                        <d-input-group
                          append="szt."
                          :class="{'is-invalid': errors.length}">
                          <d-input
                            :state="errors.length ? 'invalid' : null"
                            type="number"
                            min="0"
                            :step="1"
                            class="text-right"
                            v-model="data.value_from"/>
                        </d-input-group>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>
              </validation-observer>

              <d-alert
                v-if="loadError"
                show
                theme="warning">
                Wczytywanie danych nie powiodło się.
                <a
                  href="javascript:window.location.reload();"
                  class="alert-link">
                  Odśwież stronę
                </a>
                aby spróbować ponownie.
              </d-alert>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import FormUtils from '../../utils/FormUtils.js';

export default {
  name: 'price-form',
  computed: {
    allDataLoaded () {
      return this.loaded;
    },
    backUrl () {
      if (this.$route.meta.type === 'services') {
        return '/cenniki/zdobienia/';
      }

      if (this.$route.meta.type === 'setups') {
        return '/cenniki/setup/';
      }

      return '';
    },
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      data: {
        id: 0,
        is_setup: 0,
        price: 0,
        service_id: null,
        value_from: 0
      },
      loaded: false,
      loadError: false,
      loadedServices: false,
      allServices: [],
      loadPriceError: false
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
    }
    this.loadAdditionalData();
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/services/prices/item/',
        instanceFields: ['id', 'is_setup', 'price', 'service_id', 'value_from'],
        loadedKey: 'loaded',
        errorKey: 'loadPriceError'
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['price-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/services/prices/update/' + this.id : '/api/services/prices/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano cenę',
        successText: 'Wybrana cena została zapisana',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja ceny nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push(this.backUrl);
        }
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        price: this.data.price,
        service_id: this.data.service_id,
        value_from: this.data.value_from
      };

      if (this.$route.meta.type === 'services') {
        formDataToSend.is_setup = 0;
      }

      if (this.$route.meta.type === 'setups') {
        formDataToSend.is_setup = 1;
      }

      return formDataToSend;
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/items',
        method: 'get',
        outputKey: 'allServices',
        loadedKey: 'loadedServices',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    }
  }
};
</script>
