<template>
  <d-container fluid class="main-content-container px-4">
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Ustawienia</span>
        <h3 class="page-title">Ustawienia systemu CRM</h3>
      </d-col>
    </d-row>

    <d-row>
      <d-col
        lg
        col
        :cols="3"
        v-for="(card, index) in cards" :key="'card-' + index">
        <d-card class="card card-small text-center mb-4">
            <d-card-body>
              <router-link :to="card.link">
                <h3 class="display-4 mt-2 mb-2">
                  <i class="material-icons text-primary" style="font-size: 64px;">
                    {{ card.icon }}
                  </i>
                </h3>

                {{ card.label }}
              </router-link>
            </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>

export default {
  name: 'settings',
  computed: {
    cards () {
      return [
        {
          label: 'Nici',
          icon: 'panorama_vertical_select',
          link: '/ustawienia/nici'
        },
        {
          label: 'Rodzaje haftów',
          icon: 'star_half',
          link: '/ustawienia/rodzaje-haftow'
        },
        {
          label: 'Pozycje dekoracji',
          icon: 'vertical_align_center',
          link: '/ustawienia/pozycje-dekoracji'
        }
      ];
    }
  }
};
</script>
