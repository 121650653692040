<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Operatorzy i maszyny</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja wpisu</template>
          <template v-else>Dodawanie wpisu</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/operatorzy-i-maszyny')"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadError">
          <d-tabs
            card
            :class="{'is-loading': !allDataLoaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="width-narrow px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>
              <validation-observer
                ref="worker-assign-form"
                tag="form">
                <d-row>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="worker">
                        Operator maszyny:
                      </label>
                      <validation-provider
                        rules="required"
                        name="worker"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-select': true,
                            'is-invalid': errors.length,
                            'vs-wrap-text': true
                          }">
                          <v-select
                            class=""
                            :options="allWorkers"
                            v-model="data.user_id"
                            :reduce="(worker) => worker.id"
                            placeholder="Wybierz operatora maszyny"
                            label="name">
                          </v-select>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="machine">
                        Maszyna:
                      </label>
                      <validation-provider
                        rules="required"
                        name="machine"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-select': true,
                            'is-invalid': errors.length,
                            'vs-wrap-text': true
                          }">
                          <v-select
                            class=""
                            :options="allMachines"
                            v-model="data.machine_id"
                            :reduce="(machine) => machine.id"
                            placeholder="Wybierz maszynę"
                            label="name">
                          </v-select>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>

                <d-row>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="worker">
                        Zmiana:
                      </label>
                      <validation-provider
                        rules="required"
                        name="worker"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-select': true,
                            'is-invalid': errors.length,
                            'vs-wrap-text': true
                          }">
                          <v-select
                            class=""
                            :options="shifts"
                            v-model="selectedShift"
                            placeholder="Wybierz zmianę"
                            label="label">
                          </v-select>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>

                <d-row>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="dateStart">
                        Czas rozpoczęcia pracy:
                      </label>

                      <validation-provider
                        rules="required|requiredStartRange"
                        name="dateStart"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-date-range': true,
                            'vs-wrap-text': true,
                            'is-invalid': errors.length
                          }">
                          <date-range-picker
                            v-if="dateStartPickerVisible"
                            ref="pickerStart"
                            :locale-data="{
                              'firstDay': 1,
                              'format': 'DD-MM-YYYY HH:mm:ss',
                              'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                              'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                              'applyLabel': 'OK',
                              'cancelLabel': 'Anuluj'
                            }"
                            class="w-100"
                            :singleDatePicker="true"
                            :timePicker="true"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            v-model="dateStart"
                            :ranges="false"
                            :linkedCalendars="true"
                            :date-format="dateFormat">
                            <template v-slot:input="pickerStart">
                              {{ formatDateStart(pickerStart.startDate) }}
                            </template>
                          </date-range-picker>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="dateEnd">
                        Czas zakończenia pracy:
                      </label>

                      <validation-provider
                        rules="required|requiredStartRange"
                        name="dateEnd"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-date-range': true,
                            'vs-wrap-text': true,
                            'is-invalid': errors.length
                          }">
                          <date-range-picker
                            v-if="dateEndPickerVisible"
                            ref="pickerEnd"
                            :locale-data="{
                              'firstDay': 1,
                              'format': 'DD-MM-YYYY HH:mm:ss',
                              'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                              'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                              'applyLabel': 'OK',
                              'cancelLabel': 'Anuluj'
                            }"
                            class="w-100"
                            :singleDatePicker="true"
                            :timePicker="true"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            v-model="dateEnd"
                            :ranges="false"
                            :linkedCalendars="false"
                            :date-format="dateFormat">
                            <template v-slot:input="pickerEnd">
                              {{ formatDateEnd(pickerEnd.endDate) }}
                            </template>
                          </date-range-picker>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>
              </validation-observer>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import CalendarUtils from '@/utils/CalendarUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'working-hours-form',
  components: {
    'date-range-picker': DateRangePicker
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.loadedWorkers && this.loadedMachines;
    },
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  watch: {
    'dateStart.startDate': function (newValue) {
      if (newValue === null) {
        this.data.start_at = null;
      }

      this.data.date = CalendarUtils.formatDateString(newValue, null);
      this.data.start_at = CalendarUtils.formatTimeString(newValue, null);
    },
    'dateEnd.startDate': function (newValue) {
      if (newValue === null) {
        this.data.end_at = null;
      }

      this.data.end_at = CalendarUtils.formatTimeString(newValue, null);
    },
    selectedShift (newValue) {
      if (!newValue || !this.viewInitiated) {
        return;
      }

      this.setDateTimeForShift(newValue, this.dateStart.startDate);
    }
  },
  data () {
    return {
      allMachines: [],
      allWorkers: [],
      data: {
        date: null,
        end_at: null,
        id: 0,
        machine_id: null,
        start_at: null,
        user_id: null
      },
      dateEnd: {
        startDate: null
      },
      dateEndPickerVisible: false,
      dateStart: {
        startDate: null
      },
      dateStartPickerVisible: false,
      loaded: false,
      loadedMachines: false,
      loadedWorkers: false,
      loadError: false,
      selectedShift: null,
      shifts: [{
        label: 'Pierwsza zmiana',
        name: 'first-shift',
        startAt: '06:00:00',
        endAt: '14:00:00'
      }, {
        label: 'Druga zmiana',
        name: 'second-shift',
        startAt: '14:00:00',
        endAt: '22:00:00'
      }, {
        label: 'Trzecia zmiana',
        name: 'third-shift',
        startAt: '22:00:00',
        endAt: '06:00:00'
      }],
      submitInProgress: false,
      viewInitiated: false
    };
  },
  mounted () {
    this.dateStartPickerVisible = true;
    this.dateEndPickerVisible = true;
    this.loadAdditionalData();
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
      this.viewInitiated = true;
    }
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/users/working-hours/item/',
        instanceFields: ['id', 'user_id', 'machine_id', 'date', 'start_at', 'end_at'],
        booleanFields: ['active'],
        loadedKey: 'loaded',
        errorKey: 'loadError',
        successAction: (response) => {
          if (!response.start_at) {
            Vue.set(this.dateStart, 'startDate', null);
          }

          if (!response.end_at) {
            Vue.set(this.dateEnd, 'startDate', null);
          }

          let shift = this.findShift(response);
          Vue.set(this, 'selectedShift', shift);

          this.dateStartPickerVisible = true;
          this.dateEndPickerVisible = true;

          this.setDateTimeForShift(shift, response.date, response.start_at, response.end_at);

          Vue.nextTick(() => {
            this.viewInitiated = true;
          })
        }
      });
    },
    findShift(apiShift) {
      if (!apiShift.start_at || !apiShift.end_at) {
        return null;
      }

      let apiStart = Number(apiShift.start_at.split(':').shift());
      let apiEnd = Number(apiShift.end_at.split(':').shift());

      // If the end time is earlier than the start time, it's the third shift
      if (apiEnd < apiStart) {
        return JSON.parse(JSON.stringify(this.shifts.find(shift => shift.name === 'third-shift')));
      }

      for (let i = 0; i < this.shifts.length; i++) {
        let shift = this.shifts[i];
        let shiftEnd = Number(shift.endAt.split(':').shift());
        if (apiStart < (shiftEnd - 2)) {
          return JSON.parse(JSON.stringify(shift));
        }
      }

      // Default to third shift if no exact match is found
      return JSON.parse(JSON.stringify(this.shifts.find(shift => shift.name === 'third-shift')));
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/users/items',
        method: 'get',
        outputKey: 'allWorkers',
        loadedKey: 'loadedWorkers',
        errorKey: 'loadError',
        params: {
          pagination: 0,
          where: {
            active: 1,
            type: 'OPERATOR'
          }
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/items',
        method: 'get',
        outputKey: 'allMachines',
        loadedKey: 'loadedMachines',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['worker-assign-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = {
        date: this.data.date,
        end_at: this.data.end_at,
        machine_id: this.data.machine_id,
        start_at: this.data.start_at,
        user_id: this.data.user_id
      }

      let endpointUrl = this.isEdit ? '/api/users/working-hours/update/' + this.id : '/api/users/working-hours/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano godziny pracy',
        successText: 'Wybrane godziny pracy zostały zapisane',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja godzin pracy nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push('/operatorzy-i-maszyny');
        }
      });
    },
    setDateTimeForShift (shiftObj, startDate = null, startTime = null, endTime = null) {
      let shiftStart = startDate ? new Date(startDate) : new Date();
      if (!startDate) {
        shiftStart.setDate(shiftStart.getDate() + 1);
      }

      let shiftEnd = new Date(shiftStart);

      let [hoursStart, minutesStart, secondsStart] = startTime ? startTime.split(':').map(Number) : shiftObj.startAt.split(':').map(Number);

      shiftStart.setHours(hoursStart);
      shiftStart.setMinutes(minutesStart);
      shiftStart.setSeconds(secondsStart);

      let [hoursEnd, minutesEnd, secondsEnd] = endTime ? endTime.split(':').map(Number) : shiftObj.endAt.split(':').map(Number);

      shiftEnd.setHours(hoursEnd);
      shiftEnd.setMinutes(minutesEnd);
      shiftEnd.setSeconds(secondsEnd);

      if (hoursStart > hoursEnd) {
        shiftEnd.setDate(shiftStart.getDate() + 1);
      }

      let dateStart = { startDate: shiftStart, endDate: shiftStart };
      let dateEnd = { startDate: shiftEnd, endDate: shiftEnd };
      this.$refs.pickerStart.$emit('update', dateStart);
      this.$refs.pickerEnd.$emit('update', dateEnd);
    },
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date() + (24 * 60 * 60 * 1000)
      }
      return classes
    },
    formatDateStart (input) {
      if (input === null) {
        return 'od';
      }

      return input.toLocaleString();
    },
    formatDateEnd (input) {
      if (input === null) {
        return 'do';
      }

      return input.toLocaleString();
    }
  }
}
</script>
