<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Czas pracy</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja wpisu czasu pracy</template>
          <template v-else>Dodawanie  wpisu czasu pracy</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/czas-pracy')"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadError">
          <d-tabs
            card
            :class="{'is-loading': !allDataLoaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="width-narrow px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <validation-observer
                ref="work-form"
                tag="form">
                <div class="form-group">
                  <label for="worker">
                    Pracownik:
                  </label>
                  <validation-provider
                    name="worker"
                    rules="required"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        class=""
                        :options="allWorkers"
                        v-model="data.user_id"
                        :reduce="worker => worker.id"
                        placeholder="Wybierz pracownika"
                        label="name">
                      </v-select>
                    </div>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <d-row>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="dateStart">
                        Czas rozpoczęcia pracy:
                      </label>

                      <validation-provider
                        rules="required|requiredStartRange"
                        name="dateStart"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-date-range': true,
                            'vs-wrap-text': true,
                            'is-invalid': errors.length
                          }">
                          <date-range-picker
                            v-if="dateStartPickerVisible"
                            ref="pickerStart"
                            :locale-data="{
                              'firstDay': 1,
                              'format': 'DD-MM-YYYY HH:mm',
                              'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                              'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                              'applyLabel': 'OK',
                              'cancelLabel': 'Anuluj'
                            }"
                            class="w-100"
                            :singleDatePicker="true"
                            :timePicker="true"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            v-model="dateStart"
                            :ranges="false"
                            :linkedCalendars="true">
                            <template v-slot:input="pickerStart">
                              {{ formatDateStart(pickerStart.startDate) }}
                            </template>
                          </date-range-picker>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="dateEnd">
                        Czas zakończenia pracy:
                      </label>

                      <validation-provider
                        rules="required|requiredStartRange"
                        name="dateEnd"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-date-range': true,
                            'vs-wrap-text': true,
                            'is-invalid': errors.length
                          }">
                          <date-range-picker
                            v-if="dateEndPickerVisible"
                            ref="pickerEnd"
                            :locale-data="{
                              'firstDay': 1,
                              'format': 'DD-MM-YYYY HH:mm',
                              'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                              'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                              'applyLabel': 'OK',
                              'cancelLabel': 'Anuluj'
                            }"
                            class="w-100"
                            :singleDatePicker="true"
                            :timePicker="true"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            v-model="dateEnd"
                            :ranges="false"
                            :linkedCalendars="false">
                            <template v-slot:input="pickerEnd">
                              {{ formatDateEnd(pickerEnd.endDate) }}
                            </template>
                          </date-range-picker>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>
              </validation-observer>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import Vue from 'vue';
import FormUtils from '../../utils/FormUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'work-time-form',
  components: {
    'date-range-picker': DateRangePicker
  },
  computed: {
    allDataLoaded () {
      return this.loaded;
    },
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  watch: {
    'dateStart.startDate': function (newValue) {
      if (newValue === null) {
        this.data.start_at = null;
      }

      this.data.start_at = this.prepareDateTimeString(newValue);
    },
    'dateEnd.startDate': function (newValue) {
      if (newValue === null) {
        this.data.end_at = null;
      }

      this.data.end_at = this.prepareDateTimeString(newValue);
    }
  },
  data() {
    return {
      data: {
        id: 0,
        user_id: 0,
        start_at: null,
        end_at: null,
        user: {}
      },
      dateStartPickerVisible: false,
      dateEndPickerVisible: false,
      dateStart: {
        startDate: null
      },
      dateEnd: {
        startDate: null
      },
      allWorkers: [],
      loadedWorkers: false,
      loaded: false,
      loadError: false
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
      this.dateStartPickerVisible = true;
      this.dateEndPickerVisible = true;
    }

    this.loadAdditionalData();
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/users/working-times/item/',
        instanceFields: ['id', 'user_id', 'start_at', 'end_at', 'user'],
        loadedKey: 'loaded',
        errorKey: 'loadError',
        successAction: (response) => {
          if (response) {
            Vue.nextTick(() => {
              Vue.set(this.dateStart, 'startDate', new Date(response.start_at));
              Vue.set(this.dateEnd, 'startDate', new Date(response.end_at));
              this.dateStartPickerVisible = true;
              this.dateEndPickerVisible = true;
            });
          }
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['work-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/users/working-times/update/' + this.id : '/api/users/working-times/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano wpis',
        successText: 'Wybrany wpis rejestracji czasu pracy został zapisany',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja wpisu nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push('/czas-pracy')
        }
      });
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/users/items',
        method: 'get',
        outputKey: 'allWorkers',
        loadedKey: 'loadedWorkers',
        errorKey: 'loadError',
        params: {
          pagination: 0,
          where: {
            active: 1
          }
        }
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        user_id: this.data.user_id,
        start_at: this.data.start_at,
        end_at: this.data.end_at,
      };

      return formDataToSend;
    },
    prepareDateTimeString (date) {
      date = new Date(date);

      return [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-') + ' ' + [
        date.getHours(),
        date.getMinutes()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join(':');
    },
    formatDateStart (input) {
      if (input === null) {
        return 'od';
      }

      return input.toLocaleString();
    },
    formatDateEnd (input) {
      if (input === null) {
        return 'do';
      }

      return input.toLocaleString();
    }
  }
};
</script>
