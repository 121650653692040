<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Czas pracy</span>
        <h3 class="page-title">Lista wpisów czasu pracy</h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end ml-auto">
        <d-button
          theme="primary"
          class="mr-2"
          @click.prevent="$bus.$emit('hours-report-download-popup-show')">
          <i class="material-icons">cloud_download</i>
          Pobierz raport godzin pracy
        </d-button>

        <d-button
          theme="success"
          @click.prevent="$router.push('/czas-pracy/nowy')">
          <i class="material-icons">add</i>
          Dodaj wpis
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="loaded"
      style="width: 100%;"
      class="d-flex ml-0 mb-2 page-filters">
      <validation-observer
        ref="work-time-filters-form"
        tag="form"
        class="d-flex align-items-start">
        <v-select
          :disabled="!usersLoaded"
          style="width: 30%;"
          class="mr-1 mb-2"
          :options="allUsers"
          v-model="filters.user_id"
          :reduce="user => user.id"
          placeholder="Wybierz pracownika"
          :searchable="false"
          label="name">
        </v-select>

        <validation-provider
          :rules="filters.month ? 'required' : ''"
          name="search-year"
         style="width: 30%;"
          class="mr-1 mb-2"
          v-slot="{ errors }">
          <div
            :class="{
              'form-control': true,
              'is-select': true,
              'is-invalid': errors.length,
              'vs-wrap-text': true,
              'mr-1': true
            }">
            <v-select
              :options="optionsYears"
              v-model="filters.year"
              placeholder="Wybierz rok"
              :searchable="false">
            </v-select>
          </div>
          <div class="invalid-feedback">
            {{ errors[0] }}
          </div>
        </validation-provider>

        <validation-provider
          :rules="filters.year ? 'required' : ''"
          name="search-month"
          style="width: 30%;"
          class="mr-3 mb-2"
          v-slot="{ errors }">
          <div
            :class="{
              'form-control': true,
              'is-select': true,
              'is-invalid': errors.length,
              'vs-wrap-text': true
            }">
            <v-select
              v-model="filters.month"
              :options="optionsMonths"
              :reduce="month => month.value"
              :searchable="false"
              placeholder="Wybierz miesiąc"
              label="name">
            </v-select>
          </div>
          <div class="invalid-feedback">
            {{ errors[0] }}
          </div>
        </validation-provider>
        <d-button
          theme="info"
          outline
          class="text-nowrap ml-md-auto mr-1 mb-2"
          @click.prevent="validateFiltersForm">
          Szukaj
        </d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">
          Resetuj filtry
        </d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%;">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle">Pracownik</th>
            <th scope="col" class="border-0 align-middle text-center">Czas</th>
            <th scope="col" class="border-0 align-middle text-center">Data</th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="loaded">
          <tr
            v-for="(entry, index) of workTimes"
            :key="'entry-' + index">
            <td class="align-middle py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Pracownik</label>
              {{ entry.user.name }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Czas</label>
              {{ formatTime(entry.minutes) }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Data</label>
              <small>Rozpoczęto: <span class="text-primary">{{ entry.start_at }}</span></small><br>
              <small>Zakończono: <span class="text-primary">{{ entry.end_at }}</span></small>
            </td>
            <td class="text-center align-middle pt-0">
              <d-button
                theme="primary"
                @click.native="$router.push('/czas-pracy/edycja/' + entry.id)"
                class="mx-2 mt-2"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
              <d-button
                theme="danger"
                :disabled="removeInProgress"
                @click.native="removeEntry(entry.id)"
                class="mx-2 mt-2"
                title="Usuń">
                <i class="material-icons">delete</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="workTimes.length === 0">
            <td
              colspan="4"
              class="text-center">
              Brak zarejestrowanej pracy do wyświetlenia&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!loaded" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>

    <pagination :meta-data="paginationMetaData" />
    <hours-report-download-popup />
  </d-container>
</template>

<script>
import debounce from 'lodash.debounce';
import FormUtils from '../utils/FormUtils.js';
import HasFilters from '../mixins/HasFilters.vue';
import HasPagination from '../mixins/HasPagination.vue';
import ListUtils from '../utils/ListUtils.js';
import Pagination from '../components/common/Pagination.vue';
import HoursReportDownloadPopup from './../components/popups/HoursReportDownloadPopup.vue';

export default {
  name: 'work-time-log',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination,
    'hours-report-download-popup': HoursReportDownloadPopup
  },
  computed: {
    optionsYears () {
      let years = [];
      let currentYear = (new Date()).getFullYear();

      for (let i = 2021; i <= currentYear; i++) {
        years.push(i);
      }

      return years;
    }
  },
  data() {
    return {
      workTimes: [],
      loaded: false,
      loadError: false,
      removeInProgress: false,
      allUsers: [],
      usersLoaded: false,
      filters: {
        user_id: null,
        year: null,
        month: null
      },
      optionsMonths: [
        {
          value: 1,
          name: 'Styczeń'
        }, {
          value: 2,
          name: 'Luty'
        }, {
          value: 3,
          name: 'Marzec'
        }, {
          value: 4,
          name: 'Kwiecień'
        }, {
          value: 5,
          name: 'Maj'
        }, {
          value: 6,
          name: 'Czerwiec'
        }, {
          value: 7,
          name: 'Lipiec'
        }, {
          value: 8,
          name: 'Sierpień'
        }, {
          value: 9,
          name: 'Wrzesień'
        }, {
          value: 10,
          name: 'Październik'
        }, {
          value: 11,
          name: 'Listopad'
        }, {
          value: 12,
          name: 'Grudzień'
        }
      ]
    };
  },
  mounted () {
    this.loadFilteredData();
    this.loadAdditionalData();
  },
  methods: {
    beforeDebouncedLoadFilteredData () {
      this.$bus.$emit('pagination-reset');
      this.debouncedLoadFilteredData();
    },
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.user_id) {
        where.user_id = this.filters.user_id;
      }

      if (this.filters.year && this.filters.month) {
        where.year = this.filters.year;
        where.month = this.filters.month;
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/users/working-times/items?page=' + this.currentPage,
        listField: 'workTimes',
        pagination: true,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        user_id: null,
        year: null,
        month: null
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    validateFiltersForm () {
      FormUtils.validate(this.$refs['work-time-filters-form'], this.beforeDebouncedLoadFilteredData);
    },
    removeEntry (entryID) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/users/working-times/delete/',
        id: entryID,
        successTitle: 'Usunięto wpis',
        successText: 'Wybrany wpis rejestracji czasu pracy został usunięty',
        confirmTitle: 'Czy na pewno chcesz usunąć ten wpis?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie wpisu nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        }
      });
    },
    formatTime (minutes) {
      if (minutes >= 60) {
        return `${Math.floor(minutes / 60)}h ${minutes % 60}min`;
      }
      return `${minutes}min`;
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/users/items',
        method: 'get',
        outputKey: 'allUsers',
        loadedKey: 'usersLoaded',
        errorKey: 'loadError',
        params: {
          pagination: 0,
        }
      });
    }
  }
};
</script>
