<template>
  <d-modal
  @close="handleClose"
  :class="{ 'is-hidden': isHidden }">
    <d-modal-header>
      <d-modal-title>
        Generuj raport zrealizaowanych zamówień
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <d-form>
        <validation-observer ref="orders-report-download-form" tag="form">
          <div class="form-group">
            <label for="name">
              Wybierz zakres dat raportu
            </label>

            <validation-provider
              rules="required|requiredDateRange"
              v-slot="{ errors }">
              <div
                :class="{
                  'form-control': true,
                  'is-date-range': true,
                  'vs-wrap-text': true,
                  'is-invalid': errors.length
                }">
                <date-range-picker
                  ref="picker"
                  :locale-data="{
                    'firstDay': 1,
                    'format': 'DD-MM-YYYY',
                    'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                    'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                  }"
                  style="min-width: 240px;"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="dateRange"
                  :ranges="false"
                  :linkedCalendars="true">
                  <template v-slot:input="picker" style="min-width: 350px; max-width: 50%;">
                    {{ picker.startDate | dateStart }} - {{ picker.endDate | dateEnd }}
                  </template>
                </date-range-picker>
              </div>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        :disabled="generateInProgress"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="generateInProgress"
        @click.prevent="validateForm">
        <div
          v-if="generateInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
        Generuj raport
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import FormUtils from '../../utils/FormUtils.js';
import Vue from 'vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'orders-report-download-form',
  components: {
    'date-range-picker': DateRangePicker
  },
  filters: {
    formatDate (input) {
      if (!input) {
        return '-';
      }

      let dateObject = new Date(input);

      return [
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-') + ' ' + [
        dateObject.getHours(),
        dateObject.getMinutes()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join(':');
    },
    dateStart (input) {
      if (input === null) {
        return ' od ';
      }

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    dateEnd (input) {
      if (input === null) {
        return ' do ';
      }

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    }
  },
  data () {
    return {
      generateInProgress: false,
      isHidden: true,
      dateRange: {
        startDate: null,
        endDate: null
      }
    };
  },
  mounted () {
    this.$bus.$on('orders-report-download-popup-show', this.openPopup);
  },
  methods: {
    openPopup () {
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.generateInProgress = false;

      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    },
    handleClose () {
      this.isHidden = true;
    },
    cancel() {
      this.handleClose();
    },
    prepareDateString (input) {
      if (input === null) {
        return null;
      }
      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    validateForm () {
      FormUtils.validate(this.$refs['orders-report-download-form'], this.generate);
    },
    generate () {
      this.generateInProgress = true;

      let params = '?date_from=' + this.prepareDateString(this.dateRange.startDate) + '&date_to=' + this.prepareDateString(this.dateRange.endDate);

      Vue.swal({
        title: 'Czy na pewno chcesz wygenerować i pobrać raport?',
        text: 'Pobrany plik będzie miał format .xlsx',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, pobierz',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.$http.get('/api/dashboard/workers-orders-report' + params, {
            responseType: 'blob'
          }).then(response => {
            if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'raportRealizacji.xlsx');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              Vue.swal({
                title: 'Raport pobrany',
                html: 'Plik raportRealizacji.xlsx zawierający raport realizowanych zamówień został pobrany.',
                icon: 'success',
                confirmButtonText: 'OK',
                buttonsStyling: true
              }).then(() => {
                this.generateInProgress = false;
              }).catch((errors) => {
                console.log(errors);
              });
            } else {
              Vue.swal({
                title: 'Wystąpił błąd podczas pobierania',
                html: 'Spróbuj ponownie',
                icon: 'warning',
                confirmButtonText: 'OK',
                buttonsStyling: true
              });
              this.generateInProgress = false;
            }
          }).catch(error => {
            console.log(error);
            Vue.swal({
              title: 'Wystąpił błąd podczas generowania',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            this.generateInProgress = false;
          });
        } else {
          this.generateInProgress = false;
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('orders-report-download-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
