<template>
  <d-modal
    size="lg"
    :class="{ 'big-popup': true, 'is-hidden': isHidden }"
    @close="handleClose">
    <d-modal-header class="px-4">
      <d-modal-title>
        Lista zamówień {{ listTitle }}
      </d-modal-title>
    </d-modal-header>
    <d-modal-body class="pt-0">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle pl-4">Klient/Zamówienie</th>
            <th scope="col" class="border-0 align-middle text-center">Liczba produktów</th>
            <th scope="col" class="border-0 align-middle text-center">Data złożenia zamówienia</th>
            <th scope="col" class="border-0 align-middle text-center">Ostateczny termin wykonania</th>
            <th scope="col" class="border-0 align-middle pr-4 text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, index) of ordersLits"
            :key="'order-' + index">
            <td class="align-middle py-lg-1 py-md-3 py-3 pl-4">
              {{ order.customer_name }}<br>
              <small>{{ order.order_name}}</small>
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              {{ order.products_count }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              {{ prepareDateString(order.created_at) }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              {{ prepareDateString(order.deadline) }}
            </td>
            <td
              class="align-middle text-center py-lg-1 py-md-3 py-3 pr-4"
              style="width: 80px;">
              <d-button
                theme="primary"
                @click.native="$router.push('/zamowienia/edycja/' + order.id)"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="!ordersLits.length">
            <td
              colspan="5"
              class="text-center px-4">
              Brak zebranych zamówień&hellip;
            </td>
          </tr>
        </tbody>
      </table>
    </d-modal-body>
  </d-modal>
</template>

<script>
export default {
  name: 'orders-popup',
  computed: {
    listTitle () {
      switch (this.listKey) {
        case 'deadline_passed': return 'po terminie realizacji';
        case 'waiting_accept_samples': return 'oczekujących na akceptację próby';
        case 'waiting_dst': return 'oczekujących na DST';
        case 'waiting_samples': return 'oczekujących na próbę';
        default: return '';
      }
    },
  },
  data () {
    return {
      ordersLits: [],
      listKey: '',
      isHidden: true
    };
  },
  mounted () {
    this.$bus.$on('orders-stats-list-popup-show', this.openPopup);
  },
  methods: {
    prepareDateString (input) {
      if (input === null) {
        return '';
      }
      input = new Date(input);

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    handleClose () {
      this.isHidden = true;
    },
    openPopup (listToDisplay, listKey) {
      this.ordersLits = listToDisplay;
      this.listKey = listKey;

      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    }
  },
  beforeDestroy () {
    this.$bus.$off('orders-stats-list-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}

.modal-body {
  padding-left: 0;
  padding-right: 0;
}
</style>
