<template>
  <div class="orders-view-wrapper">
    <orders-operator v-if="isOperator" />
    <orders v-else />
  </div>
</template>

<script>
import Orders from './Orders.vue';
import OrdersOperator from './OrdersOperator.vue';

export default {
  name: 'orders-wrapper',
  components: {
    'orders': Orders,
    'orders-operator': OrdersOperator,
  },
  computed: {
    isOperator () {
      return this.$store.getters['getUserType'] === 'OPERATOR';
    }
  }
};
</script>
