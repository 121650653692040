<template>
  <d-modal
    :class="{ 'is-hidden': isHidden }"
    @close="handleClose">
    <d-modal-header class="py-3 px-3">
      <d-modal-title>
        Wprowadź wynik kontroli
      </d-modal-title>
    </d-modal-header>
    <d-modal-body class="py-3 px-3">
      <d-row
        class="px-2 py-2 justify-content-between mx-0 mb-3 flex-wrap"
        style="background: #F5F6F8; border-radius: 0.625rem">
        <d-col sm="7"><small>Zamówienie: <strong>{{ orderName }}</strong></small></d-col>
        <d-col sm="5"><small>Do skontrolowania: <strong>{{ (maxQuantity + deliveredQuantity) - controlledQuantity }}/{{ maxQuantity }}</strong></small></d-col>
        <d-col sm="7"><small>Produkt: <strong>{{ product_code }}</strong></small></d-col>
        <d-col sm="5"><small>Ilość zdobień: <strong>{{ servicesCount }}</strong></small></d-col>
      </d-row>
      <d-form>
        <validation-observer ref="order-creator-quality-control-form" tag="form">
          <d-row
            v-if="isAdmin">
            <d-col sm="12">
              <label for="worker">
                Kontroler:
              </label>
              <validation-provider
                name="worker"
                rules="required"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    class=""
                    :options="allWorkers"
                    v-model="user_id"
                    :reduce="worker => worker.id"
                    placeholder="Wybierz kontrolera"
                    label="name">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </d-col>
          </d-row>

          <d-row class="d-flex flex-wrap mt-4">
            <d-col sm="4">
              <div
                v-if="dateRangePickerVisible"
                class="form-group">
                <label for="execution-date">
                  Data kontroli:
                </label>
                <validation-provider
                  rules="required|requiredStartRange"
                  name="execution-date"
                  v-slot="{ errors }">
                  <div
                    :class="{
                      'form-control': true,
                      'is-date-range': true,
                      'vs-wrap-text': true,
                      'is-invalid': errors.length
                    }">
                    <date-range-picker
                      ref="picker"
                      :locale-data="{
                        'firstDay': 1,
                        'format': 'DD-MM-YYYY',
                        'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                        'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                        'applyLabel': 'OK',
                        'cancelLabel': 'Anuluj'
                      }"
                      class="w-100"
                      :singleDatePicker="true"
                      :timePicker="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      v-model="dateRange"
                      :ranges="false"
                      :linkedCalendars="true"
                      :auto-apply="true">
                      <template v-slot:input="picker">
                        {{ prepareDateString(picker.startDate) }}
                      </template>
                    </date-range-picker>
                  </div>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </d-col>
            <d-col sm="4">
              <div class="form-group">
                <label for="correct">
                  Poprawne:
                </label>

                <validation-provider
                  :rules="'required|only-natural-numbers|min_value:0|max_value:' + ((maxQuantity + deliveredQuantity) - controlledQuantity - rejected)"
                  name="correct"
                  v-slot="{ errors }">
                  <d-input-group
                    append="szt."
                    :class="{'is-invalid': errors.length}">
                    <d-input
                      min="0"
                      :max="(maxQuantity + deliveredQuantity) - controlledQuantity - rejected"
                      :disabled="((maxQuantity + deliveredQuantity) - controlledQuantity) <= 0"
                      class="text-right"
                      v-model="correct"
                      :state="errors.length ? 'invalid' : null"
                      type="number" />
                  </d-input-group>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </d-col>
            <d-col sm="4">
              <div class="form-group">
                <label for="rejected">
                  Odrzucone:
                </label>

                <validation-provider
                  :rules="'required|only-natural-numbers|min_value:0|max_value:' + ((maxQuantity + deliveredQuantity) - controlledQuantity - correct)"
                  name="rejected"
                  v-slot="{ errors }">
                  <d-input-group
                    append="szt."
                    :class="{'is-invalid': errors.length}">
                    <d-input
                      min="0"
                      :max="(maxQuantity + deliveredQuantity) - controlledQuantity - correct"
                      :disabled="((maxQuantity + deliveredQuantity) - controlledQuantity) <= 0"
                      class="text-right"
                      v-model="rejected"
                      :state="errors.length ? 'invalid' : null"
                      type="number" />
                  </d-input-group>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </d-col>
          </d-row>

          <d-row>
            <d-col sm="12">
              <div class="form-group">
                <label for="description">
                  Uwagi:
                </label>
                <d-textarea
                  name="description"
                  v-model="description"
                  :value.prop="description"
                  :rows="6"/>
              </div>
            </d-col>
          </d-row>

          <template v-if="rejected > 0">
            <d-row>
              <d-col sm="12">
                <div class="form-group">
                  <label for="description">
                    Powód odrzucenia:
                  </label>
                  <validation-provider
                    name="reject-reason"
                    :rules="rejected > 0 ? 'required' : ''"
                    v-slot="{ errors }">
                    <div
                      :class="{
                        'form-control': true,
                        'is-select': true,
                        'is-invalid': errors.length,
                        'vs-wrap-text': true
                      }">
                      <v-select
                        :disabled="!rejected"
                        :options="rejectionOptions"
                        v-model="reject_reason"
                        :reduce="reason => reason.value"
                        placeholder="Wybierz przyczynę"
                        label="text">
                      </v-select>
                    </div>
                  </validation-provider>
                </div>
              </d-col>
            </d-row>

            <d-row>
              <d-col sm="12">
                <div class="form-group">
                  <label>
                    Zdjęcie wady:
                  </label>

                  <div class="">
                    <file-input
                      :onChange="setImage"
                      :disabled="rejected < 1"
                      id="inputFileRejectedImage" />
                  </div>
                </div>
              </d-col>
            </d-row>
          </template>
        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="submitInProgress"
        @click.prevent="validateForm">
        <div
          v-if="submitInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
        Zapisz
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import FormUtils from '../../utils/FormUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import RejectionReasons from '@/data/rejection-reasons.js';

export default {
  name: 'order-creator-quality-control',
  components: {
    'date-range-picker': DateRangePicker
  },
  watch: {
    'dateRange.startDate': function (newValue) {
      if (newValue === null) {
        this.control_date = null;
      }

      this.control_date = this.prepareDateString(newValue);
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters['getUserType'] === 'ADMIN';
    },
    rejectionOptions () {
      return RejectionReasons;
    },
  },
  data () {
    return {
      productID: null,
      allWorkers: [],
      control_date: null,
      user_id: 0,
      correct: 0,
      rejected: 0,
      description: '',
      reject_reason: null,
      fault_image: null,
      deliveredQuantity: 0,
      maxQuantity: 0,
      controlledQuantity: 0,
      orderName: '',
      product_code: '',
      submitInProgress: false,
      isHidden: true,
      dateRange: {
        startDate: null
      },
      dateRangePickerVisible: false,
      sourceView: 'orderView',
      servicesCount: 0
    };
  },
  mounted () {
    this.control_date = null;
    this.$bus.$on('quality-control-popup-show', this.openPopup);
  },
  methods: {
    handleClose () {
      this.isHidden = true;
      this.dateRangePickerVisible = false;
    },
    validateForm () {
      FormUtils.validate(this.$refs['order-creator-quality-control-form'], this.save);
    },
    save() {
      let isControlDone = (((Number(this.maxQuantity) + Number(this.deliveredQuantity)) - Number(this.controlledQuantity)) - Number(this.correct) - Number(this.rejected)) === 0;
      this.submitInProgress = true;
      let endpointUrl = '/api/orders/products/add-control/' + this.productID;
      let formDataToSend = this.prepareFormDataToSend();

      if (this.isAdmin) {
        formDataToSend.user_id = this.user_id
      }

      let imageFields = false;
      if (this.fault_image) {
        formDataToSend.fault_image = this.fault_image;
        imageFields = ['fault_image'];
      }

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano kontrolę jakości',
        successText: 'Wybrana kontrola jakości została zapisana',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja danych kontroli jakości nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          Vue.nextTick(() => {
            this.submitInProgress = false;
            this.handleClose();

            if (this.sourceView === 'controllerList') {
              this.$bus.$emit('reload-controller-list', isControlDone);
            } else {
              this.$bus.$emit('reload-order-view', isControlDone);
            }
          });
        },
        failAction: () => {
          this.submitInProgress = false;
        },
      }, imageFields);
    },
    cancel () {
      this.handleClose();
    },
    prepareDateString (input) {
      if (input === null) {
        return 'Wybierz datę';
      }
      input = new Date(input);

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        control_date: this.control_date,
        correct: this.correct,
        rejected: this.rejected,
        description: this.description,
        reject_reason: this.reject_reason
      };

      return formDataToSend;
    },
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date()
      }
      return classes
    },
    openPopup (product, orderName, allWorkers = [], servicesCount = 1, sourceView = 'orderView') {
      this.allWorkers = allWorkers;
      this.productID = product.id;
      this.maxQuantity = product.quantity;
      this.deliveredQuantity = product.controls.map(control => control.delivered).reduce((sum, control) => sum + control, 0);
      this.orderName = orderName;
      this.product_code = product.product_code;
      this.sourceView = sourceView;
      this.servicesCount = servicesCount;

      this.user_id = null;
      this.correct = 0;
      this.rejected = 0;
      this.description = '';
      this.dateRange.startDate = new Date();
      this.control_date = null;
      this.reject_reason = null;
      this.fault_image = null;
      this.controlledQuantity = 0;

      if (product.controls) {
        for (let i = 0; i < product.controls.length; i++) {
          this.controlledQuantity += product.controls[i].correct;
          this.controlledQuantity += product.controls[i].rejected;
        }
      }

      setTimeout(() => {
        this.isHidden = false;
        this.dateRangePickerVisible = true;
        if (this.$refs['order-creator-quality-control-form']) {
          this.$refs['order-creator-quality-control-form'].reset();
        }
      }, 0);
    },
    setImage (event) {
      this.fault_image = event.target.files[0];
    }
  },
  beforeDestroy () {
    this.$bus.$off('quality-control-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
