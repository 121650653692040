<template>
  <d-modal
    size="lg"
    :class="{ 'big-popup': true, 'is-hidden': isHidden }"
    @close="handleClose">
    <d-modal-header>
      <d-modal-title class="w-100">
        Kartony z HPL
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <order-boxes-list
        ref="order-boxes-list"
        :boxes-list="boxesLits"
        :is-on-dashboard="true"/>
    </d-modal-body>
  </d-modal>
</template>

<script>
import OrderBoxesList from '@/components/forms/order/OrderBoxesList.vue';

export default {
  name: 'orders-boxes-popup',
  components: {
    'order-boxes-list': OrderBoxesList
  },
  data () {
    return {
      boxesLits: [],
      isHidden: true
    };
  },
  mounted () {
    this.$bus.$on('orders-boxes-popup-show', this.openPopup);
  },
  methods: {
    handleClose () {
      this.isHidden = true;
    },
    openPopup (listToDisplay) {
      this.boxesLits = listToDisplay;

      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    }
  },
  beforeDestroy () {
    this.$bus.$off('orders-boxes-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}

.modal-body {
  padding-left: 0;
  padding-right: 0;
}
</style>
