<template>
  <d-modal
    @close="handleClose"
    :class="{ 'is-hidden': isHidden }">
    <d-modal-header>
      <d-modal-title>
        Zmień status zamówienia
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <d-form>
          <div class="form-group">
            <label for="name">
              Wybierz nowy status:
            </label>

            <div
              :class="{
                'form-control': true,
                'is-select': true,
                'vs-wrap-text': true
              }">
              <v-select
                :options="orderStatusOptions"
                :reduce="(status) => status.status_code"
                v-model="newStatus"
                placeholder="Status zamówienia"
                :clearable="false"
                :searchable="false"
                label="label">
              </v-select>
            </div>
          </div>
        </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="statusChangeInProgress"
        @click.prevent="saveNewOrderStatus()">
        <div
          v-if="statusChangeInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
          Zapisz
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import OrderStatuses from '@/data/order-statuses.js';

export default {
  name: 'order-status-change-popup',
  computed: {
    orderStatusOptions () {
      let orderStatusesArr = [];
      let keys = Object.keys(OrderStatuses)
      for (let i = 0; i < keys.length; i++) {
        let orderStatus = OrderStatuses[keys[i]];
        orderStatus['status_code'] = keys[i];
        orderStatusesArr.push(orderStatus);
      }
      return orderStatusesArr;
    }
  },
  data () {
    return {
      isHidden: true,
      orderID: 0,
      initialStatus: 'NEW',
      newStatus: 'NEW',
      statusChangeInProgress: false
    };
  },
  mounted () {
    this.$bus.$on('order-status-change-popup-show', this.openPopup);
  },
  methods: {
    openPopup (orderID, currentStatus) {
      Vue.set(this, 'orderID', orderID);
      Vue.set(this, 'initialStatus', currentStatus);
      Vue.set(this, 'newStatus', currentStatus);
      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    },
    handleClose () {
      this.isHidden = true;
    },
    cancel() {
      this.handleClose();
    },
    saveNewOrderStatus () {
      if (this.initialStatus === this.newStatus) {
        Vue.swal({
          title: 'Status pozostał bez zmian',
          text: '',
          icon: 'info',
          confirmButtonText: 'OK',
        })
        this.handleClose();
        return;
      }

      this.statusChangeInProgress = true;
      let formDataToSend = {
        status: this.newStatus
      };

      let endpointUrl = '/api/orders/statuses/change/' + this.orderID;

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano status',
        successText: 'Wybrany status został zapisany',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja statusu nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          Vue.set(this, 'statusChangeInProgress', false);
          this.$bus.$emit('order-status-change-popup-update', this.openPopup);
          this.handleClose();
        },
        failAction: () => {
          Vue.set(this, 'statusChangeInProgress', false);
          this.handleClose();
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('order-status-change-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
