<template>
  <d-container
    fluid
    class="pl-0 pr-0 pl-md-2 pr-md-2">
    <d-row
      class="main-wrapper mr-0 ml-0"
      style="max-width: 100%;">
      <!-- Main Sidebar -->
      <main-sidebar
        :items="sidebarItems" />

      <d-col
        class="main-content ml-auto mr-auto"
        tag="main"
        cols="12">
        <div id="scroll-detector"></div>
        <!-- Content -->
        <slot />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import getSidebarItems from '@/data/sidebar-nav-items';
import MainSidebar from '@/components/layout/MainSidebar.vue';

export default {
  name: 'main-view',
  components: {
    MainSidebar
  },
  data() {
    return {
      sidebarItems: getSidebarItems(),
      scrollObserver: null,
      scrollDetector: null
    };
  },
  mounted () {
    if ('IntersectionObserver' in window) {
      this.scrollDetector = document.querySelector('#scroll-detector');
      this.scrollObserver = new IntersectionObserver(
        ([e]) => {
          if (e.intersectionRatio === 0) {
            document.body.classList.add('is-scrolled');
          }

          if (e.intersectionRatio === 1) {
            document.body.classList.remove('is-scrolled');
          }
        },
        {
          threshold: [0, 1]
        }
      );

      this.scrollObserver.observe(this.scrollDetector);
    }

    document.body.classList.add('js-not-close-modals-on-backdrop-click');
  },
  beforeDestroy () {
    if ('IntersectionObserver' in window && this.scrollObserver) {
      this.scrollObserver.unobserve(this.scrollDetector);
    }
  }
};
</script>

<style lang="scss">
.main-wrapper {
  padding-left: 240px;
}

.main-content {
  max-width: 1440px;
}

@media screen and (max-width: 1200px) {
  .main-content {
    padding-top: 3.75rem !important;
  }

  .main-wrapper {
    padding-left: 0;
  }
}
</style>
