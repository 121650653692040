<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Użytkownicy</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja użytkownika</template>
          <template v-else>Dodawanie użytkownika</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/uzytkownicy')"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadError">
          <d-tabs
            card
            :class="{'is-loading': !allDataLoaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="width-narrow px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <validation-observer
                ref="user-form"
                tag="form">

                <d-row class="mb-3">
                  <d-col>
                    <label class="d-block">Konto aktywne</label>
                    <d-checkbox
                      toggle
                      toggle-small
                      class="mr-0"
                      inline
                      v-model="data.active"/>
                  </d-col>
                </d-row>
                <d-row class="mb-3">
                  <d-col>
                    <label class="d-block">
                      Rodzaj konta
                    </label>
                    <d-form-radio
                      inline
                      v-model="data.type"
                      value="ADMIN">
                      Administrator
                    </d-form-radio>
                    <d-form-radio
                      inline
                      v-model="data.type"
                      value="OFFICE">
                      Biuro
                    </d-form-radio>
                    <d-form-radio
                      inline
                      v-model="data.type"
                      value="OPERATOR">
                      Operator maszyny
                    </d-form-radio>
                    <d-form-radio
                      inline
                      v-model="data.type"
                      value="CONTROLLER">
                      Kontroler
                    </d-form-radio>
                  </d-col>
                </d-row>

                <div class="form-group">
                  <label for="name">
                    Imię i nazwisko:
                  </label>

                  <validation-provider
                    rules="required"
                    name="name"
                    v-slot="{ errors }">
                    <d-input
                      :state="errors.length ? 'invalid' : null"
                      v-model="data.name" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="email">
                    E-mail:
                  </label>

                  <validation-provider
                    rules="required|email"
                    name="email"
                    v-slot="{ errors }">
                    <d-input
                      v-model="data.email"
                      type="email"
                      :state="errors.length ? 'invalid' : null"/>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="password">
                    Hasło:
                  </label>

                  <validation-provider
                    :rules="isEdit ? 'min:6' : 'required|min:6'"
                    name="password"
                    v-slot="{ errors }">
                    <d-input
                      type="password"
                      :state="errors.length ? 'invalid' : null"
                      v-model="data.password" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="password-confirmation">
                    Powtórz hasło:
                  </label>

                  <validation-provider
                    :rules="isEdit ? 'min:6|password:password' : 'required|min:6|password:password'"
                    name="password-confirmation"
                    v-slot="{ errors }">
                    <d-input
                      type="password"
                      :state="errors.length ? 'invalid' : null"
                      v-model="data.passwordConfirmation" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
              </validation-observer>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import FormUtils from './../../utils/FormUtils.js';

export default {
  name: 'user-form',
  computed: {
    allDataLoaded () {
      return this.loaded;
    },
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      data: {
        id: 0,
        name: '',
        email: '',
        type: 'OPERATOR',
        active: true,
        password: '',
        passwordConfirmation: ''
      },
      loaded: false,
      loadError: false
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
    }
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/users/item/',
        instanceFields: ['id', 'name', 'email', 'type', 'active'],
        booleanFields: ['active'],
        loadedKey: 'loaded',
        errorKey: 'loadError'
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['user-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/users/update/' + this.id : '/api/users/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano użytkownika',
        successText: 'Wybrany użytkownik został zapisany',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja użytkownika nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push('/uzytkownicy')
        }
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        name: this.data.name,
        email: this.data.email,
        type: this.data.type,
        active: +this.data.active
      };

      if (this.data.password !== '' && this.data.passwordConfirmation !== '') {
        formDataToSend.password = this.data.password;
        formDataToSend.password_confirmation = this.data.passwordConfirmation;
      }

      return formDataToSend;
    }
  }
};
</script>
