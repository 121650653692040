export default [
  {
    text: 'Czapka z daszkiem',
    value: 'CAP_WITH_PEAK'
  },
  {
    text: 'Czapka bez daszka',
    value: 'CAP_WITHOUT_PEAK'
  }
];
