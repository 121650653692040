<template>
  <d-container fluid>
    <main class="main-login-content">
      <slot />
    </main>
  </d-container>
</template>
<script>
export default {
  name: 'GuestLayout'
};
</script>

<style scoped>
.main-login-content{
  height: 100vh;
}
</style>
