export default class CalendarUtils {
  static getIsSameDay (date1, date2) {
    if (!date1 || !date2) {
      return false;
    }
    if (date1.getDate() !== date2.getDate()) {
      return false;
    }
    if (date1.getMonth() !== date2.getMonth()) {
      return false;
    }
    if (date1.getFullYear() !== date2.getFullYear()) {
      return false;
    }

    return true;
  }

  static getIsDateInRange (dateToCheck, datFrom, dateTo) {
    let itemDate = dateToCheck.getTime();

    if (!datFrom && !dateTo) {
      return true;
    }

    if (!datFrom) {
      return (itemDate <= dateTo.getTime());
    }

    if (!dateTo) {
      return (itemDate >= datFrom.getTime());
    }

    return (itemDate >= datFrom.getTime() && itemDate <= dateTo.getTime());
  }

  static getIsDayBeforeDate (date1, date2) {
    return new Date(new Date(date1).toDateString()) < new Date(new Date(date2).toDateString());
  }

  static formatDateString (dateInput, noDateTxt = '-') {
    if (dateInput === null) {
      return noDateTxt;
    }
    let deteToFormat = new Date(dateInput);

    return [
      deteToFormat.getFullYear(),
      deteToFormat.getMonth() + 1,
      deteToFormat.getDate()
    ]
      .map(n => n < 10 ? '0' + n : n)
      .join('-');
  }

  static formatDateTimeString (dateInput, noDateTxt = '-') {
    if (!dateInput) {
      return noDateTxt;
    }

    let newDate = new Date(dateInput);

    let datePart = [
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      newDate.getDate()
    ]
      .map(n => n < 10 ? '0' + n : n)
      .join('-');

    let timePart = [
      newDate.getHours(),
      newDate.getMinutes()
    ]
      .map(n => n < 10 ? '0' + n : n)
      .join(':');

    return `${datePart} ${timePart}`;
  }

  static formatTimeString (input, noTimeTxt = '-') {
    if (input === null) {
      return noTimeTxt;
    }

    return [input.getHours(), input.getMinutes()].map(n => n < 10 ? '0' + n : n).join(':');
  }
};
