<template>
  <d-modal
    size="lg"
    :class="{ 'big-popup': true, 'is-hidden': isHidden , 'services-to-settle-popup': true}"
    @close="handleClose">
    <d-modal-header class="px-4">
      <d-modal-title class="w-100">
        <div class="d-flex flex-wrap">
          <span class="mr-2">Lista zdobień do rozliczenia</span>

          <div class="ml-auto mr-3">
            <d-button
              theme="primary"
              class="ml-auto text-nowrap"
              @click.prevent="$bus.$emit('dashboard-services-settlements-import-popup-open')">
              <i class="material-icons">cloud_upload</i>
              Importuj XLSX
            </d-button>

            <d-button
              theme="success"
              class="ml-2 text-nowrap"
              @click.prevent.stop="loadItemsData(true)">
              <div
                v-if="downloadInProgress"
                class="spinner-border spinner-border-sm"
                role="status">
              </div>
              <template v-if="!downloadInProgress">
                <i class="material-icons">cloud_download</i>
                Pobierz XLS
              </template>
            </d-button>
          </div>
        </div>
      </d-modal-title>
    </d-modal-header>
    <d-modal-body class="px-4">
      <d-row
        v-if="loaded"
        style="width: 100%;"
        class="d-flex ml-0 mb-0 page-filters">
        <validation-observer
          ref="orders-services-to-settle-filters-form"
          tag="form"
          class="d-flex mb-3">
          <d-input
            placeholder="Nazwa zamówienia"
            v-model="filters.orderName"
            class="mr-1 mb-2"/>

          <v-select
            style="min-width: 30%;"
            class="mr-0 mb-2 status-select"
            :options="orderSettlementOptions"
            v-model="filters.settlementStatus"
            :reduce="status => status.value"
            placeholder="Status rozliczenia"
            :searchable="false"
            label="label">
          </v-select>

          <d-button
            theme="info"
            outline
            class="text-nowrap ml-auto ml-md-3 mr-1 mb-2"
            @click.prevent="validateFiltersForm">
            Szukaj
          </d-button>

          <d-button
            theme="danger"
            outline
            class="text-nowrap mb-2"
            @click.prevent="clearFilters">
            Resetuj filtry
          </d-button>
        </validation-observer>
      </d-row>

      <d-card style="width: 100%;">
        <table class="table mt-0 mb-0 full-width">
          <thead class="bg-light">
            <tr>
              <th scope="col" class="border-0 align-middle text-center">ID</th>
              <th scope="col" class="border-0 align-middle">Haft/Zamówienie</th>
              <th scope="col" class="border-0 align-middle">DST</th>
              <th scope="col" class="border-0 align-middle text-center">Ilość</th>
              <th scope="col" class="border-0 align-middle text-center">Ściegi</th>
              <th scope="col" class="border-0 align-middle text-center">Wysoki haft</th>
              <th scope="col" class="border-0 align-middle text-center">Powtórne</th>
              <th scope="col" class="border-0 align-middle text-center">Program</th>
              <th scope="col" class="border-0 align-middle text-center text-lg-right">Ceny</th>
              <th scope="col" class="border-0 align-middle text-center" style="width: 130px;">Akcje</th>
            </tr>
          </thead>
          <tbody v-if="loaded">
            <tr
              v-for="(service, index) of servicesList"
              :key="'service-' + index">
              <td class="align-middle text-center py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">ID</label>
                {{ service.id }}
              </td>
              <td class="align-middle py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">Haft/Zamówienie</label>
                {{ service.name }}<br>
                <small>{{ service.order_name}}</small>
              </td>
               <td class="align-middle py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">DST</label>
                {{ service.dst_number }}
              </td>
              <td class="align-middle text-center py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">Ilość</label>
                {{ service.quantity }}
              </td>
              <td class="align-middle text-center py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">Ściegi</label>
                {{ service.stitch_number }}
              </td>
              <td class="align-middle text-center py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">Wysoki haft</label>
                {{ service.embroidery_over_55 ? 'Tak' : 'Nie' }}
              </td>
              <td class="align-middle text-center py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">Powtórne</label>
                {{ service.dst === 'REPEAT' ? 'T' : 'N' }}
              </td>
              <td class="align-middle text-center py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1">Program</label>
                {{ service.price_setup }}
              </td>
              <td class="align-middle text-center text-lg-right py-lg-1 py-md-3 py-3">
                <label class="table-mobile-label mb-1 ">Ceny</label>
                <small>Haft: <strong>{{ service.price }}</strong></small><br>
                <small>Wartość hafty: <strong>{{ service.item_value }}</strong></small><br>
                <small class="text-primary">Wartość FV: <strong>{{ service.invoice_value }}</strong></small>
              </td>
              <td class="align-middle text-center py-lg-1 py-md-3 py-3">
                <d-button
                  v-if="service.can_settlement"
                  theme="primary"
                  :disabled="settlementInProgress === service.id"
                  @click.native="settleItem(service.id)"
                  title="Rozlicz"
                  class="my-auto">
                  Rozlicz
                </d-button>
                <small v-else class="text-success">
                  <strong>Rozliczono:</strong><br>{{ service.settlement_at }}
                </small>
              </td>
            </tr>
            <tr v-if="!servicesList.length">
              <td
                colspan="10"
                class="text-center px-4">
                Brak zdobień&hellip;
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="!loaded" class="pb-3 pt-3 text-center">
          Trwa ładowanie danych&hellip;
        </div>

        <d-alert
          v-if="loadError"
          show
          theme="warning">
          Wczytywanie danych nie powiodło się.
          <a
            href="javascript:window.location.reload();"
            class="alert-link">
            Odśwież stronę
          </a>
          aby spróbować ponownie.
        </d-alert>
      </d-card>

      <pagination :meta-data="paginationMetaData" />
    </d-modal-body>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import FormUtils from '@/utils/FormUtils.js';
import ListUtils from '@/utils/ListUtils.js';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'orders-services-to-settle-popup',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination
  },
  computed: {
  },
  data () {
    return {
      downloadInProgress: false,
      filters: {
        orderName: '',
        settlementStatus: 'unsettled'
      },
      isHidden: true,
      loaded: false,
      loadError: false,
      servicesList: [],
      settlementInProgress: false,
      orderSettlementOptions: [
        {
          label: 'Nierozliczone',
          value: 'unsettled'
        },
        {
          label: 'Rozliczone',
          value: 'settled'
        },
        {
          label: 'Wszystkie',
          value: null
        }
      ]
    };
  },
  mounted () {
    this.$bus.$on('orders-services-to-settle-popup-show', this.openPopup);
    this.$bus.$on('orders-services-to-settle-popup-reload', this.loadItemsData);
  },
  methods: {
    handleClose () {
      this.isHidden = true;
    },
    openPopup () {
      this.loadItemsData();

      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    },
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadItemsData();
    }, 250),
    loadItemsData (downloadAsFile = false) {
      let where = {};

      if (this.filters.settlementStatus) {
        where.settlement_status = this.filters.settlementStatus;
      }

      if (this.filters.orderName) {
        where.order = { order_name: this.filters.orderName };
      }

      if (downloadAsFile) {
        if (this.downloadInProgress) {
          return;
        }

        this.downloadInProgress = true;
        this.$http.post('/api/orders/services/items', {
          download_excel: 1,
          where
        }, {
          responseType: 'blob'
        }).then(response => {
          if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'zdobienia-do-rozliczenia.xlsx');
            document.querySelector('.services-to-settle-popup .modal-body').appendChild(link);
            link.click();
            document.querySelector('.services-to-settle-popup .modal-body').removeChild(link);
            this.downloadInProgress = false;
          } else {
            Vue.swal({
              title: 'Wystąpił błąd podczas pobierania',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            this.downloadInProgress = false;
          }
        }).catch(error => {
          console.log(error);
          Vue.swal({
            title: 'Wystąpił błąd podczas generowania',
            html: 'Spróbuj ponownie',
            icon: 'warning',
            confirmButtonText: 'OK',
            buttonsStyling: true
          });
          this.downloadInProgress = false;
        });
      } else {
        ListUtils.loadItemsData(this, {
          method: 'get',
          endpoint: '/api/orders/services/items?page=' + this.currentPage,
          listField: 'servicesList',
          pagination: true,
          params: {
            where
          }
        });
      }
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['orders-services-to-settle-filters-form'], this.debouncedLoadFilteredData);
    },
    clearFilters () {
      this.filters = {
        orderName: '',
        settlementStatus: 'unsettled'
      };

      this.loadItemsData();
      this.$bus.$emit('view-filters-reset');
    },
    settleItem (itemID) {
      this.settlementInProgress = itemID;

      FormUtils.submitInBackground(this, {
        endpoint: '/api/orders/services/settlement/' + itemID,
        errorTitle: 'Wystąpił błąd',
        errorText: 'Rozliczenie zdobienia nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.settlementInProgress = false;
          this.loadItemsData();

          Vue.swal({
            title: 'Rozliczenie dodane',
            html: 'Wybrane zdobie zostało rozliczone',
            icon: 'success',
            confirmButtonText: 'OK',
            buttonsStyling: true,
            timer: 2000
          });
        },
        failAction: () => {
          this.settlementInProgress = false;
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('orders-services-to-settle-popup-show', this.openPopup);
    this.$bus.$off('orders-services-to-settle-popup-reload', this.loadItemsData);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}

.modal-body {
  padding-left: 0;
  padding-right: 0;
}
</style>
