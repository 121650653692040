var render = function render(){var _vm=this,_c=_vm._self._c;return _c('d-container',{staticClass:"main-content-container px-0 px-md-2 px-lg-4",attrs:{"fluid":""}},[_c('d-row',{staticClass:"page-header py-4",attrs:{"no-gutters":""}},[_c('d-col',{staticClass:"text-left mb-4 mb-sm-0",attrs:{"sm":"12","md":"6","lg":"6"}},[_c('span',{staticClass:"text-uppercase page-subtitle"},[_vm._v("Czas pracy")]),_c('h3',{staticClass:"page-title"},[(_vm.isEdit)?[_vm._v("Edycja wpisu czasu pracy")]:[_vm._v("Dodawanie wpisu czasu pracy")]],2)]),_c('d-col',{staticClass:"text-right text-sm-right d-flex justify-content-end align-items-end",attrs:{"sm":"12","md":"6","lg":"6"}},[_c('d-button',{staticClass:"mr-1",attrs:{"theme":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/czas-pracy')}}},[_c('i',{staticClass:"material-icons"},[_vm._v("arrow_back")]),_vm._v(" Wróć do listy ")]),_c('d-button',{attrs:{"theme":"success"},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_vm._v(" Zapisz zmiany ")])],1)],1),_c('d-form',[_c('d-card',{staticClass:"mb-5"},[(!_vm.allDataLoaded && !_vm.loadError)?_c('div',{staticClass:"loading-spinner pb-3 pt-3 text-center"},[_c('span',{staticClass:"loading-spinner-content text-primary"},[_vm._v(" Trwa ładowanie danych… ")])]):_vm._e(),(!_vm.loadError)?[_c('d-tabs',{class:{'is-loading': !_vm.allDataLoaded},attrs:{"card":""}},[_c('d-tab',{staticClass:"width-narrow px-3 px-md-4",attrs:{"title":"Dane ogólne","active":""}},[_c('h4',{staticClass:"mb-3 pb-3 border-bottom"},[_vm._v(" Dane ogólne ")]),_c('validation-observer',{ref:"work-form",attrs:{"tag":"form"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"worker"}},[_vm._v(" Pracownik: ")]),_c('validation-provider',{attrs:{"name":"worker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{class:{
                      'form-control': true,
                      'is-select': true,
                      'is-invalid': errors.length,
                      'vs-wrap-text': true
                    }},[_c('v-select',{attrs:{"options":_vm.allWorkers,"reduce":worker => worker.id,"placeholder":"Wybierz pracownika","label":"name"},model:{value:(_vm.data.user_id),callback:function ($$v) {_vm.$set(_vm.data, "user_id", $$v)},expression:"data.user_id"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2801787143)})],1),_c('d-row',[_c('d-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"dateStart"}},[_vm._v(" Czas rozpoczęcia pracy: ")]),_c('validation-provider',{attrs:{"rules":"required|requiredStartRange","name":"dateStart"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{class:{
                          'form-control': true,
                          'is-date-range': true,
                          'vs-wrap-text': true,
                          'is-invalid': errors.length
                        }},[(_vm.dateStartPickerVisible)?_c('date-range-picker',{ref:"pickerStart",staticClass:"w-100",attrs:{"locale-data":{
                            'firstDay': 1,
                            'format': 'DD-MM-YYYY HH:mm',
                            'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                            'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                            'applyLabel': 'OK',
                            'cancelLabel': 'Anuluj'
                          },"singleDatePicker":true,"timePicker":true,"showWeekNumbers":false,"showDropdowns":false,"ranges":false,"linkedCalendars":true},scopedSlots:_vm._u([{key:"input",fn:function(pickerStart){return [_vm._v(" "+_vm._s(_vm.formatDateStart(pickerStart.startDate))+" ")]}}],null,true),model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}}):_vm._e()],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2832499939)})],1)]),_c('d-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"dateEnd"}},[_vm._v(" Czas zakończenia pracy: ")]),_c('validation-provider',{attrs:{"rules":"required|requiredStartRange","name":"dateEnd"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{class:{
                          'form-control': true,
                          'is-date-range': true,
                          'vs-wrap-text': true,
                          'is-invalid': errors.length
                        }},[(_vm.dateEndPickerVisible)?_c('date-range-picker',{ref:"pickerEnd",staticClass:"w-100",attrs:{"locale-data":{
                            'firstDay': 1,
                            'format': 'DD-MM-YYYY HH:mm',
                            'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                            'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                            'applyLabel': 'OK',
                            'cancelLabel': 'Anuluj'
                          },"singleDatePicker":true,"timePicker":true,"showWeekNumbers":false,"showDropdowns":false,"ranges":false,"linkedCalendars":false},scopedSlots:_vm._u([{key:"input",fn:function(pickerEnd){return [_vm._v(" "+_vm._s(_vm.formatDateEnd(pickerEnd.endDate))+" ")]}}],null,true),model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}}):_vm._e()],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,3702197607)})],1)])],1)],1)],1)],1)]:[_c('d-alert',{staticClass:"my-5 mx-5",staticStyle:{"font-weight":"bold"},attrs:{"show":"","theme":"warning"}},[_vm._v(" Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub "),_c('a',{staticClass:"alert-link",staticStyle:{"font-weight":"bold","text-decoration":"underline"},attrs:{"href":"javascript:window.location.reload();"}},[_vm._v(" odśwież stronę ")]),_vm._v(" aby spróbować ponownie. ")])]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }