<template>
  <d-card
    v-if="allDataLoaded"
    class="mt-4">
    <d-card-body class="px-2 px-lg-3 py-2 py-lg-2">
      <d-alert
        v-if="!!product.deleted_in_hpl"
        show
        theme="danger"
        class="mt-3 mb-4">
        Ten produkt został usunięty w zamówieniu HPL.
      </d-alert>
      <div>
        <d-row
          class="mt-2 d-flex">
          <d-col md="4">
            <div class="form-group">
              <label :for="'product-code-' + productIndex">
                Kod produktu:
              </label>

              <validation-provider
                rules="required"
                :name="'product-code-' + productIndex"
                v-slot="{ errors }">
                <d-input
                  :disabled="isBlocked || isQAOnly || !isProductSetup"
                  :state="errors.length ? 'invalid' : null"
                  v-model="product.product_code" />
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
          <d-col md="3">
            <div class="form-group">
              <label :for="'product-quantity-' + productIndex">
                Liczba sztuk:
              </label>

              <validation-provider
                rules="required|min_value:1|only-natural-numbers"
                :name="'product-quantity-' + productIndex"
                v-slot="{ errors }">
                <d-input-group
                  append="szt."
                  :class="{'is-invalid': errors.length}">
                  <d-input
                    min="1"
                    step="1"
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    class="text-right"
                    v-model="product.quantity"
                    :state="errors.length ? 'invalid' : null"
                    type="number" />
                </d-input-group>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
          <d-col md="3">
            <div class="form-group">
              <label :for="'product-color-' + productIndex">
                Kolor:
              </label>

              <validation-provider :name="'product-color-' + productIndex">
                <d-input
                  :disabled="isBlocked || isQAOnly || !isProductSetup"
                  v-model="product.color_name" />
              </validation-provider>
            </div>
          </d-col>

          <d-col class="d-flex">
            <d-button
              v-if="isProductSetup && !isQAOnly"
              theme="danger"
              outline
              class="ml-auto align-self-center"
              :disabled="isBlocked"
              @click.prevent="removeProduct()">
              <i class="material-icons">delete</i>
              Usuń
            </d-button>
            <d-button
              v-if="isQAOnly"
              :disabled="isBlocked || leftToControlCount"
              theme="primary"
              class="ml-auto align-self-center"
              @click.prevent="$bus.$emit('quality-control-popup-show', product, orderName, allWorkers, product.services.length)">
              Wprowadź wynik kontroli
            </d-button>
          </d-col>
        </d-row>

        <d-row
          class="d-flex">
          <d-col md="4">
            <div class="form-group">
              <label :for="'product-type-' + productIndex">
                Typ czapki:
              </label>
              <validation-provider
                :name="'product-type-' + productIndex"
                rules="required"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    :disabled="isBlocked || isQAOnly || !isProductSetup"
                    :options="productTypeOptions"
                    v-model="product.type"
                    :reduce="type => type.value"
                    placeholder="Wybierz rodzaj czapki"
                    label="text">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </d-col>
          <d-col md="3">
            <div class="form-group">
              <label :for="'product-color-' + productIndex">
                Rozmiar:
              </label>

              <d-input
                :disabled="isBlocked || true"
                :value="product.size_name || '-'" />
            </div>
          </d-col>
        </d-row>

        <d-row
          class="px-2 py-2 d-flex justify-content-around mx-0"
          style="background: #F5F6F8; border-radius: 0.625rem">
          <small class="mr-3">
            Liczba zdobień: <strong>{{ product.services.length }}</strong>
          </small>
          <small class="mr-3">
            Oczekuje na DST: <strong>{{ awaitingDSTCount }}</strong>
          </small>
          <small class="mr-3">
            Oczekuje na próbę: <strong>{{ awaitingSampleCount }}</strong>
          </small>
          <small class="mr-3">
            Oczekuje na akceptację próby: <strong>{{ awaitingSampleApprovalCount }}</strong>
          </small>
          <small class="mr-3">
            Liczba wykonanych zdobień: <strong> {{ executedCount }}/{{ servicesToExecuteCount }}</strong>
          </small>
          <small class="mr-0">
            Liczba skontrolowanych: <strong :class="{ 'text-danger': controlledCount.rejected }">{{ controlledCount.amount }}/{{ product.quantity }}</strong>
          </small>
        </d-row>

        <d-row
          class="py-3 d-flex align-items-end">
          <d-col
            v-if="!userIsOffice"
            sm="10"
            class="text-primary">
            Koszt produktu: <strong>{{ servicesTotalCost }} zł</strong>
          </d-col>
          <d-col
            :sm="userIsOffice ? 12 : 2"
            class="my-auto ml-auto"
            style="text-align: center;">
            <d-button
              v-if="isProductSetup"
              theme="success"
              class="ml-auto d-block"
              :disabled="isBlocked"
              @click.prevent="addService()">
              <i class="material-icons">add</i>
              Dodaj zdobienie
            </d-button>
          </d-col>
        </d-row>
      </div>

      <d-row
        v-if="status === 'WAITING_ADDITIONAL_PRODUCTS' && product.control_rejected_count > 0"
        class="py-3 d-flex align-items-center">
          <d-col
            sm="8">
            <d-alert
              theme="danger"
              show
              class="my-0">
              W wyniku kontroli odrzuconych zostało  <strong>{{ product.control_rejected_count }}</strong> produktów, które trzeba domówić.
            </d-alert>
          </d-col>
          <d-col
            sm="4">
            <d-button
              theme="primary"
              class="ml-auto d-block"
              :disabled="isBlocked"
              @click.prevent="setProductsAsDelivered()">
              Produkty dostarczone
            </d-button>
          </d-col>
      </d-row>

      <div
        class="d-flex flex-column">
        <d-row class="mt-0 ml-0">
          <d-link
            class="dropdown-toggle"
            v-d-toggle="'product-' + productIndex + '-services-collapse'">
            Zdobienia
          </d-link>
        </d-row>
        <d-collapse
          visible
          :id="'product-' + productIndex + '-services-collapse'">
          <div class="order-creator-service">
          <d-alert
            v-if="!isBlocked && !product.services.length"
            theme="warning"
            show
            class="mt-2">
            Dodaj zdobienia do produktu klikając przycisk "Dodaj zdobienie"
          </d-alert>

          <div
            v-if="dataLoaded">
            <order-creator-service
              v-for="(service, index) of product.services"
              :ref="'order-creator-services'"
              :key="'service-item-' + index + '-' + service.uuid"
              :allContractors="allContractors"
              :allEmbroideryPositions="allEmbroideryPositions"
              :allEmbroideryTypes="allEmbroideryTypes"
              :allMachines="allMachines"
              :allPrices="allPrices"
              :allServiceGroups="allServiceGroups"
              :allServices="allServices"
              :allStrands="allStrands"
              :allWorkers="allWorkers"
              :initial-service="service"
              :isBlocked="isBlocked"
              :isInPreparation="isInPreparation"
              :isMachinesEnabled="isMachinesEnabled"
              :isProductSetup="isProductSetup"
              :isQAOnly="isQAOnly"
              :isSamplesOnly="isSamplesOnly"
              :orderName="orderName"
              :performPriceRecalculate="performPriceRecalculate"
              :productCode="product.product_code"
              :productIndex="productIndex"
              :productQuantity="product.quantity"
              :rejectedCount="rejectedCount"
              :service-index="index"
              :status="status"
              :toBeDelivered="toBeDelivered"
              @removeService="handleRemoveService"
              @updateServicePrice="handleUpdateServicePrice"/>
          </div>
          <div
            v-if="!dataLoaded"
            class="pb-3 pt-3 text-center">
            Trwa ładowanie danych&hellip;
          </div>
        </div>
        </d-collapse>
      </div>
    </d-card-body>
  </d-card>
</template>

<script>
import Vue from 'vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import CustomDecimals from '@/utils/CustomDecimals.js';
import UniversalUtils from '@/utils/UniversalUtils.js';
import OrderCreatorService from './OrderCreatorService.vue';
import StrandSelect from '@/components/forms/order/StrandSelect.vue';
import DstTypes from '@/data/dst-types.js';
import FilesList from '@/components/forms/order/FilesList.vue';
import ProductTypes from '@/data/product-types.js';

export default {
  name: 'order-creator-product',
  props: [
    'allContractors',
    'allEmbroideryPositions',
    'allEmbroideryTypes',
    'allMachines',
    'allPrices',
    'allServiceGroups',
    'allServices',
    'allStrands',
    'allWorkers',
    'initialProduct',
    'isBlocked',
    'isInPreparation',
    'isMachinesEnabled',
    'isProductSetup',
    'isQAOnly',
    'isSamplesOnly',
    'orderName',
    'performPriceRecalculate',
    'productIndex',
    'status',
    'toBeDelivered'
  ],
  components: {
    'date-range-picker': DateRangePicker,
    'order-creator-service': OrderCreatorService,
    'strand-select': StrandSelect,
    'files-list': FilesList
  },
  watch: {
    servicesTotalCost (newValue) {
      this.$emit('updateProductPrice', this.productIndex, newValue);
    }
  },
  computed: {
    allDataLoaded () {
      return this.dataLoaded;
    },
    userIsOffice () {
      return this.$store.getters['getUserType'] === 'OFFICE';
    },
    productTypeOptions () {
      return ProductTypes;
    },
    dstOptions () {
      return DstTypes;
    },
    clientDiskOptions () {
      return [
        {
          text: 'Tak',
          value: 1
        },
        {
          text: 'Nie',
          value: 0
        }
      ];
    },
    dstAccepted () {
      if (this.status === 'NEW' || this.status === 'WAITING_PRODUCTS' || this.status === 'WAITING_DST') {
        return false;
      }

      return true;
    },
    availableStrandColors () {
      if (this.allStrands) {
        return this.allStrands.map(strand => strand.name);
      }

      return [];
    },
    servicesTotalCost () {
      return this.roundProperly(this.servicesPrices.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0));
    },
    leftToControlCount () {
      let deliveredQuantity = this.product.controls.map(control => control.delivered).reduce((sum, control) => sum + control, 0);
      let controlledQuantity = 0;
      if (this.product.controls) {
        for (let i = 0; i < this.product.controls.length; i++) {
          controlledQuantity += this.product.controls[i].correct;
          controlledQuantity += this.product.controls[i].rejected;
        }
      }

      return ((this.product.quantity + deliveredQuantity) - controlledQuantity) <= 0;
    },
    rejectedCount () {
      if (this.product.control_rejected_count > 0 || !this.product.controls || !this.product.controls.length) {
        return 0;
      }

      return this.product.controls.map(control => control.rejected).reduce((sum, control) => sum + control);
    },
    awaitingDSTCount () {
      return this.product.services.filter(service => !service.dst_settled).length;
    },
    awaitingSampleCount () {
      let counter = 0;
      for (let i = 0; i < this.product.services.length; i++) {
        if (this.product.services[i].samples && this.product.services[i].samples.length) {
          counter += this.product.services[i].samples.filter(sample => sample.status === 'REJECTED').length;
        }
      }
      return counter;
    },
    awaitingSampleApprovalCount () {
      let counter = 0;
      for (let i = 0; i < this.product.services.length; i++) {
        if (this.product.services[i].samples && this.product.services[i].samples.length) {
          counter += this.product.services[i].samples.filter(sample => sample.status === 'WAITING').length;
        }
      }
      return counter;
    },
    executedCount () {
      let counter = 0;

      for (let i = 0; i < this.product.services.length; i++) {
        for (let j = 0; j < this.product.services[i].machines.length; j++) {
          if (this.product.services[i].machines[j].executions && this.product.services[i].machines[j].executions.length) {
            counter += this.product.services[i].machines[j].executions.reduce((sum, item) => sum + item.execution, 0);
          }
        }
      }
      return counter;
    },
    servicesToExecuteCount() {
      let counter = 0;
      for (let i = 0; i < this.product.services.length; i++) {
        if (this.product.services[i].quantity) {
          counter += Number(this.product.services[i].quantity);
        }
      }
      return counter;
    },
    controlledCount () {
      if (!this.product.controls) {
        return { amount: 0, rejected: 0 };
      }

      let counter = 0;
      let isRejected = false;
      for (let i = 0; i < this.product.controls.length; i++) {
        counter += this.product.controls[i].correct + this.product.controls[i].rejected;
        if (this.product.controls[i].rejected > 0) {
          isRejected = true;
        }
      }
      return { amount: counter, rejected: isRejected };
    },
  },
  data () {
    return {
      dataLoaded: false,
      product: {},
      servicesPrices: []
    };
  },
  mounted () {
    this.product = JSON.parse(JSON.stringify(this.initialProduct));
    for (let i = 0; i < this.product.services.length; i++) {
      this.servicesPrices.push(0);
    }

    Vue.nextTick(() => {
      this.dataLoaded = true;
    })
  },
  methods: {
    addService () {
      this.product.services.push({
        id: null,
        contractor_id: null,
        description: '',
        dst: null,
        dst_disc: false,
        dst_number: '',
        dst_settled: false,
        dstReceiveDate: {
          startDate: null
        },
        embroidery_color: '',
        embroidery_height: 0,
        embroidery_position: null,
        embroidery_position_id: 0,
        embroidery_position_name: null,
        embroidery_type_id: 0,
        embroidery_width: 0,
        embroideryPositionNameVal: '',
        machines: [],
        price: 0,
        price_multiple_from: 0,
        price_setup: 0,
        quantity: this.product.quantity,
        real_service_id: 0,
        samples: [],
        service_group_id: null,
        service_id: null,
        stitch_number: 0,
        strands: [],
        totalPrice: 0,
        uuid: UniversalUtils.generateUUID()
      });

      this.servicesPrices.push(0);

      setTimeout(() => {
        if (this.$refs['order-creator-services'] && this.$refs['order-creator-services'][this.product.services.length - 1]) {
          let newEl = this.$refs['order-creator-services'][this.product.services.length - 1].$el;
          if (newEl.scrollHeight) {
            newEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
            return;
          }
        }
        this.$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      }, 100);
    },
    updateServices () {
      for (let i = 0; i < this.product.services.length; i++) {
        this.product.services[i] = this.$refs['order-creator-services'][i].getService();
      }
    },
    handleRemoveService (serviceIndex, showConfirm = true) {
      if (!showConfirm) {
        this.removeService(serviceIndex);
        return;
      }
      Vue.swal({
        title: 'Czy na pewno chcesz usunąć to zdobienie?',
        text: 'Aby operacja została zapisana należy zapisać zamówienie',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, usuń',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.removeService(serviceIndex);
        }
      });
    },
    removeService (serviceIndex, itemID = null) {
      this.updateServices();
      let serviceToDeleteIndex = serviceIndex;
      if (serviceIndex === null && itemID !== null) {
        serviceToDeleteIndex = this.product.services.findIndex(item => item.id === itemID);
      }

      if (serviceToDeleteIndex < 0) {
        console.warn('item for removal not found');
        return;
      }

      this.servicesPrices.splice(serviceToDeleteIndex, 1);
      this.product.services.splice(serviceToDeleteIndex, 1);
    },
    getProduct () {
      for (let i = 0; i < this.product.services.length; i++) {
        this.product.services[i] = this.$refs['order-creator-services'][i].getService();
      }
      return JSON.parse(JSON.stringify(this.product));
    },
    getProductForAPI () {
      let productServices = [];

      let productsToSend = {
        id: this.product.id,
        product_code: this.product.product_code,
        quantity: parseFloat(this.product.quantity),
        color_name: this.product.color_name,
        type: this.product.type
      };

      for (let i = 0; i < this.product.services.length; i++) {
        productServices.push(this.$refs['order-creator-services'][i].getServiceForAPI());
      }

      productsToSend.services = productServices;

      return productsToSend;
    },
    handleUpdateServicePrice (serviceIndex, newPrice) {
      Vue.set(this.servicesPrices, serviceIndex, newPrice);
    },
    removeProduct () {
      this.$emit('removeProduct', this.productIndex);
    },
    setProductsAsDelivered () {
      this.productsDeliveredInProgress = true;

      this.$http.request({
        method: 'POST',
        url: '/api/orders/products/set-delivered/' + this.product.id,
        headers: { 'Content-Type': undefined },
      }).then(() => {
        this.productsDeliveredInProgress = false;
        this.$bus.$emit('reload-order-view');
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas zapisywania',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.productsDeliveredInProgress = false;
      });
    },
    async removeServicesWithQuantityZero () {
      let itemsToRemove = [];

      for (let i = 0; i < this.product.services.length; i++) {
        if (!this.product.services[i].quantity) {
          itemsToRemove.push(this.product.services[i].id);
        }
      }

      for (let i = 0; i < itemsToRemove.length; i++) {
        this.removeService(null, itemsToRemove[i]);
      }
    },
    roundProperly (value, decimals = 2) {
      return CustomDecimals.roundProperly(value, decimals);
    },
  }
};
</script>

<style lang="scss">
.order-creator-service {
  .is-disabled {
    background: #eee;
    cursor: not-allowed;
  }

  .form-control.is-date-range .vue-daterange-picker.is-disabled .reportrange-text {
    background: #f5f6f7!important;
    pointer-events: none;
  }
}
</style>
