<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Maszyny</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja maszyny</template>
          <template v-else>Dodawanie maszyny</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/maszyny')"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadMachineError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadMachineError">
          <d-tabs
            card
            :class="{'is-loading': !allDataLoaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <validation-observer
                ref="machine-form"
                tag="form">
                <d-row class="w-100">
                  <d-col sm="12" md="6">
                    <div
                      class="form-group"
                      style="max-width: 600px;">
                      <label for="name">
                        Nazwa maszyny:
                      </label>

                      <validation-provider
                        rules="required"
                        name="name"
                        v-slot="{ errors }">
                        <d-input
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.name" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col sm="12" md="6">
                    <div
                      class="form-group"
                      style="max-width: 600px;">
                      <label for="group_id">
                        Grupa:
                      </label>
                      <validation-provider
                        name="group_id"
                        rules="required"
                        v-slot="{ errors }">
                        <div
                        :class="{
                          'form-control': true,
                          'is-select': true,
                          'is-invalid': errors.length,
                          'vs-wrap-text': true
                        }">
                          <v-select
                            :options="allMachinesGroups"
                            :reduce="group => group.id"
                            v-model="data.group_id"
                            placeholder="Wybierz"
                            label="name"
                            :clearable="false"
                            :searchable="false">
                          </v-select>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                   <d-col sm="12" md="6">
                    <div
                      class="form-group">
                      <label for="day_working_time">
                        Czas pracy w ciągu doby (w min):
                      </label>
                      <validation-provider
                        name="day_working_time"
                        rules="required|min_value:1"
                        v-slot="{ errors }">
                        <div
                        :class="{
                          'form-control': true,
                          'is-select': true,
                          'is-invalid': errors.length,
                          'vs-wrap-text': true
                        }">
                          <v-select
                            :options="workingTimeOptions"
                            v-model="data.day_working_time"
                            placeholder="Wybierz"
                            :clearable="false"
                            :searchable="false">
                          </v-select>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col sm="12" md="6">
                    <div
                      class="form-group">
                      <label for="heads_number">
                        Liczba głowic:
                      </label>

                      <validation-provider
                        rules="required|min_value:1|only-natural-numbers"
                        name="heads_number"
                        v-slot="{ errors }">
                        <d-input
                          type="number"
                          step="1"
                          min="0"
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.heads_number" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>

                <div>
                  <label for="services">
                    Zdobienia obsługiwane przez maszynę:
                  </label>

                  <d-row
                    class="mt-2">
                    <d-col cols="4">
                      <d-input
                        v-model="allServicesFilter"
                        placeholder="Filtruj usługi po nazwie" />
                    </d-col>
                    <d-col cols="8" class="d-flex">
                      <d-button
                        outline
                        class="ml-auto"
                        :disabled="data.services.length === allServices.length"
                        @click.prevent="selectAllServices">
                        Zaznacz wszystkie
                      </d-button>

                      <d-button
                        outline
                        class="ml-4"
                        :disabled="!data.services.length"
                        @click.prevent="unselectAllServices">
                        Odznacz wszystkie
                      </d-button>
                    </d-col>
                  </d-row>

                  <ul class="attach-service-prices-list">
                    <li
                      v-for="(service, index) of allServices"
                      :key="'service-to-add-' + index"
                      v-show="allServicesFilter.trim() === '' || service.name.toLocaleLowerCase().indexOf(allServicesFilter.toLocaleLowerCase()) > -1">
                      <d-checkbox
                        inline
                        v-model="data.services"
                        :value="service.id"
                        toggle
                        toggle-small
                        :label="service.name">
                        {{service.name}}
                      </d-checkbox>
                    </li>
                  </ul>
                </div>
              </validation-observer>

              <d-alert
                v-if="loadError"
                show
                theme="warning">
                Wczytywanie danych nie powiodło się.
                <a
                  href="javascript:window.location.reload();"
                  class="alert-link">
                  Odśwież stronę
                </a>
                aby spróbować ponownie.
              </d-alert>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import Vue from 'vue';
import FormUtils from '../../utils/FormUtils.js';

export default {
  name: 'machine-form',
  computed: {
    allDataLoaded () {
      return this.loaded && this.loadedServices && this.loadedMachinesGroups;
    },
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      allMachinesGroups: [],
      allServices: [],
      allServicesFilter: '',
      data: {
        day_working_time: null,
        group_id: null,
        heads_number: 0,
        id: 0,
        name: '',
        services: []
      },
      loaded: false,
      loadedMachinesGroups: false,
      loadedServices: false,
      loadError: false,
      loadMachineError: false,
      workingTimeOptions: [600, 1200]
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
    }
    this.loadAdditionalData();
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/machines/item/',
        instanceFields: ['id', 'name', 'heads_number', 'services', 'day_working_time', 'group_id'],
        loadedKey: 'loaded',
        errorKey: 'loadMachineError',
        successAction: () => {
          Vue.nextTick(() => {
            this.data.services = this.data.services.map(service => (service.id));
            if (!this.data.group_id) {
              this.data.group_id = null;
            }
          });
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['machine-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/machines/update/' + this.id : '/api/machines/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano maszynę',
        successText: 'Wybrana maszyna została zapisana',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja maszyny nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push('/maszyny');
        }
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        name: this.data.name,
        heads_number: this.data.heads_number,
        services: this.data.services,
        day_working_time: this.data.day_working_time,
        group_id: this.data.group_id
      };

      return formDataToSend;
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/items',
        method: 'get',
        outputKey: 'allServices',
        loadedKey: 'loadedServices',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/groups/items',
        method: 'get',
        outputKey: 'allMachinesGroups',
        loadedKey: 'loadedMachinesGroups',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    },
    selectAllServices () {
      this.data.services = this.allServices.map(service => service.id);
    },
    unselectAllServices () {
      this.data.services = [];
    }
  }
};
</script>

<style lang="scss">

.attach-service-prices-list {
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style-type: none;
  margin: 20px 0 0 0;
  padding: 20px;

  li {
    font-size: 12px;
  }
}
</style>
