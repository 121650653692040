<template>
  <d-modal @close="handleClose">
    <d-modal-header>
      <d-modal-title>
        Importuj cenniki
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <d-form>
        <validation-observer ref="prices-import-form" tag="form">
          <div class="form-group">
            <label for="name">
              Typ importu:
            </label>

            <validation-provider
              rules="required"
              name="type"
              v-slot="{ errors }">
              <d-form-select
                v-model="type"
                :state="errors.length ? 'invalid' : null"
                :options="[{ value: 'update', text: 'Tylko aktualizacja cen' }, { value: 'delete_add', text: 'Usuń ceny i dodaj na nowo' }]" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="name">
              Plik XLSX z cenami:
            </label>

            <validation-provider
              rules="required"
              name="file"
              v-slot="{ errors }">
              <file-input
                :onChange="setNewFile"
                id="inputFileImport" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        :disabled="importInProgress"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="importInProgress"
        @click.prevent="validateForm">
        <div
          v-if="importInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
        Importuj plik
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import FormUtils from './../../utils/FormUtils.js';
import jsonToFormData from 'json-form-data';
import Vue from 'vue';

export default {
  name: 'prices-import-popup',
  data () {
    return {
      file: null,
      type: 'update',
      importInProgress: false
    };
  },
  mounted () {
    this.file = null;
    this.type = 'update';
    this.importInProgress = false;
  },
  methods: {
    handleClose () {
      this.$bus.$emit('prices-import-popup-close');
    },
    validateForm () {
      FormUtils.validate(this.$refs['prices-import-form'], this.import);
    },
    setNewFile (event) {
      this.file = event.target.files[0];
    },
    import () {
      this.importInProgress = true;

      let importData = {
        type: this.type,
        file: this.file
      };

      this.$http.request({
        method: 'POST',
        url: '/api/services/prices/import',
        headers: { 'Content-Type': undefined },
        transformRequest (data) {
          let dataToSend = jsonToFormData(data);
          return dataToSend;
        },
        data: importData
      }).then(() => {
        this.handleClose();
        this.importInProgress = false;
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas importu',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.importInProgress = false;
      });
    },
    cancel () {
      this.handleClose();
    }
  }
}
</script>
