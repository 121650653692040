<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zdobienia</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja zdobienia</template>
          <template v-else>Dodawanie zdobienia</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/zdobienia')"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadServiceError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadServiceError">
          <d-tabs
            card
            :class="{'is-loading': !allDataLoaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="width-narrow px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <validation-observer
                ref="service-form"
                tag="form">

                <div class="form-group">
                  <label for="name">
                    Nazwa zdobienia:
                  </label>

                  <validation-provider
                    rules="required"
                    name="name"
                    v-slot="{ errors }">
                    <d-input
                      :state="errors.length ? 'invalid' : null"
                      v-model="data.name" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <d-row>
                  <d-col cols="12" lg="6" md="6">
                    <div class="form-group">
                      <label for="code">
                        Kod zdobienia:
                      </label>

                      <validation-provider
                        rules="required"
                        name="code"
                        v-slot="{ errors }">
                        <d-input
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.code" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col cols="12" lg="6" md="6">
                    <div class="form-group">
                      <label for="name">
                        Grupa zdobień:
                      </label>

                      <validation-provider
                        name="groupId"
                        rules="required"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-select': true,
                            'is-invalid': errors.length,
                            'vs-wrap-text': true
                          }">
                          <v-select
                            :disabled="!loadedGroups"
                            :options="groups"
                            v-model="data.group_id"
                            :reduce="(group) => group.id"
                            placeholder="Wybierz grupę zdobień"
                            label="name">
                          </v-select>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>

                <d-row>
                  <d-col cols="12" lg="6" md="6">
                    <div class="form-group">
                      <label for="stitchNumber">
                        Liczba ściegów:
                      </label>

                      <validation-provider
                        rules="required|min_value:1|only-natural-numbers"
                        name="stitchNumber"

                        v-slot="{ errors }">
                        <d-input
                          type="number"
                          class="text-right"
                          :min="1"
                          :step="1"
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.stitch_number" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col cols="12" lg="6" md="6">
                    <div class="form-group">
                      <label for="price-multiple-from">
                        Od ilu sztuk mnożyć cenę:
                      </label>

                      <validation-provider
                        rules="required|only-natural-numbers"
                        name="price-multiple-from"
                        v-slot="{ errors }">
                        <d-input
                          v-model="data.price_multiple_from"
                          class="text-right"
                          type="number"
                          min="0"
                          step="1"
                          :state="errors.length ? 'invalid' : null" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>


                </d-row>

              </validation-observer>

              <d-alert
                v-if="loadError"
                show
                theme="warning">
                Wczytywanie danych nie powiodło się.
                <a
                  href="javascript:window.location.reload();"
                  class="alert-link">
                  Odśwież stronę
                </a>
                aby spróbować ponownie.
              </d-alert>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import Vue from 'vue';
import FormUtils from '../../utils/FormUtils.js';

export default {
  name: 'service-form',
  computed: {
    allDataLoaded () {
      return this.loaded;
    },
    isEdit () {
      return !!this.$route.params.id;
    },
  },
  data() {
    return {
      data: {
        id: 0,
        name: '',
        group_id: null,
        code: '',
        price_multiple_from: 0,
        stitch_number: 0
      },
      loaded: false,
      loadError: false,
      loadServiceError: false,
      loadedGroups: false,
      groups: [],
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
    }
    this.loadAdditionalData();
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/services/item/',
        instanceFields: ['id', 'name', 'group_id', 'code', 'price_multiple_from', 'stitch_number'],
        loadedKey: 'loaded',
        errorKey: 'loadServiceError',
        successAction: () => {
          Vue.nextTick(() => {
            this.checkInitialGroupId();
          })
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['service-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/services/update/' + this.id : '/api/services/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano zdobienie',
        successText: 'Wybrane zdobienie zostało zapisane',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja zdobienia nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push('/zdobienia')
        }
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        name: this.data.name,
        group_id: this.data.group_id,
        code: this.data.code,
        price_multiple_from: this.data.price_multiple_from,
        stitch_number: this.data.stitch_number
      };

      return formDataToSend;
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/groups/items',
        method: 'get',
        outputKey: 'groups',
        loadedKey: 'loadedGroups',
        errorKey: 'loadError',
        params: {
          pagination: 0,
        },
        successAction: () => {
          Vue.nextTick(() => {
            this.checkInitialGroupId();
          })
        }
      });
    },
    checkInitialGroupId () {
      if (this.data.group_id && this.groups.length) {
        if (!this.groups.find(item => item.id === this.data.group_id)) {
          this.data.group_id = null;
        }
      }
    }
  }
};
</script>
