<template>
  <div class="custom-file">
    <input
      ref="input"
      type="file"
      @change="onChange"
      class="custom-file-input"
      :id="id"
      :aria-describedby="id" />
    <label
      class="custom-file-label"
      :for="id">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'file-input',
  props: {
    id: {
      default: '',
      type: String,
      required: true
    },
    label: {
      default: 'Wybierz plik',
      type: String
    },
    onChange: {
      default: () => false,
      required: false,
      type: Function
    }
  },
  mounted () {
    this.$refs['input'].addEventListener('change', this.updateLabel);
  },
  methods: {
    updateLabel (e) {
      let fileName = document.getElementById(this.id).files[0].name;
      let nextSibling = e.target.nextElementSibling
      nextSibling.innerText = fileName;
    }
  },
  beforeDestroy () {
    this.$refs['input'].removeEventListener('change', this.updateLabel);
  }
}
</script>
