<template>
  <d-modal
    :class="{ 'is-hidden': isHidden }"
    @close="handleClose">
    <d-modal-header class="py-3 px-3">
      <d-modal-title>
        Wprowadź wykonanie maszyny
      </d-modal-title>
    </d-modal-header>
    <d-modal-body class="py-3 px-3">
      <div
        class="px-2 pt-2 d-flex justify-content-around mx-0 mb-3 flex-wrap"
        style="background: #F5F6F8; border-radius: 0.625rem">
        <small v-if="executionOrderName && executionOrderName !== ''" class="pb-2">Zamówienie: <strong>{{ executionOrderName }}</strong></small>
        <small class="pb-2">Produkt: <strong>{{ executionsProductCode }}</strong></small>
        <small class="pb-2">Zdobienie: <strong>{{ executionServiceName }}</strong></small>
        <small class="pb-2">Do wykonania: <strong>{{ executionQuantity - doneExecutionQuantity }}/{{ executionQuantity }}</strong></small>
      </div>
      <d-form>
        <validation-observer ref="order-creator-service-machine-execution-form" tag="form">
          <d-row
            v-if="isAdmin"
            class="mt-4">
            <d-col sm="12">
              <label for="worker">
                Operator maszyny:
              </label>
              <validation-provider
                name="worker"
                rules="required"
                v-slot="{ errors }">
                <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                  <v-select
                    class=""
                    :options="allWorkers"
                    v-model="user_id"
                    :reduce="worker => worker.id"
                    placeholder="Wybierz operatora maszyny"
                    label="name">
                  </v-select>
                </div>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </d-col>
          </d-row>

          <d-row class="mt-4">
            <d-col sm="8">
              <div
                v-if="dateRangePickerVisible"
                class="form-group">
                <label for="execution-date">
                  Data realizacji:
                </label>
                <validation-provider
                  rules="required|requiredStartRange"
                  name="execution-date"
                  v-slot="{ errors }">
                  <div
                    :class="{
                      'form-control': true,
                      'is-date-range': true,
                      'vs-wrap-text': true,
                      'is-invalid': errors.length
                    }">

                    <date-range-picker
                      ref="picker"
                      :locale-data="{
                        'firstDay': 1,
                        'format': 'DD-MM-YYYY',
                        'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                        'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                        'applyLabel': 'OK',
                        'cancelLabel': 'Anuluj'
                      }"
                      class="w-100"
                      :singleDatePicker="true"
                      :timePicker="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      v-model="dateRange"
                      :ranges="false"
                      :linkedCalendars="true"
                      :auto-apply="true">
                      <template v-slot:input="picker">
                        {{ prepareDateString(picker.startDate) }}
                      </template>
                    </date-range-picker>
                  </div>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </d-col>

            <d-col sm="4">
              <label for="executed">
                Wykonano:
              </label>
              <validation-provider
                :rules="'required|only-natural-numbers|min_value:1|max_value:' + (executionQuantity - doneExecutionQuantity)"
                name="executed"
                v-slot="{ errors }">
                <d-input-group
                  append="szt."
                  :class="{'is-invalid': errors.length}">
                  <d-input
                    :max="executionQuantity - doneExecutionQuantity"
                    min="0"
                    class="text-right"
                    v-model="execution"
                    :state="errors.length ? 'invalid' : null"
                    type="number" />
                </d-input-group>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </d-col>
          </d-row>

          <d-row>
            <d-col sm="12">
              <label for="operator-description">
                Uwagi:
              </label>
              <d-textarea
                name="operator-description"
                v-model="localOperatorDescription"
                :value.prop="localOperatorDescription"
                :rows="4"
                :readonly="sourceView === 'orderView'"/>
            </d-col>
          </d-row>

        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="submitInProgress"
        @click.prevent="validateForm">
        <div
          v-if="submitInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
        Zapisz
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import FormUtils from '../../utils/FormUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'order-creator-service-machine-execution',
  props: [
    'orderID',
    'operatorDescription',
    'sourceView'
  ],
  components: {
    'date-range-picker': DateRangePicker
  },
  watch: {
    'dateRange.startDate': function (newValue) {
      if (newValue === null) {
        this.realization_date = null;
      }

      this.realization_date = this.prepareDateString(newValue);
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters['getUserType'] === 'ADMIN';
    }
  },
  data () {
    return {
      allWorkers: [],
      realization_date: null,
      user_id: 0,
      execution: 0,
      machineExecutionID: null,
      executionQuantity: 0,
      doneExecutionQuantity: 0,
      executionServiceName: '',
      executionOrderName: '',
      executionsProductCode: '',
      submitInProgress: false,
      isHidden: true,
      dateRange: {
        startDate: null
      },
      dateRangePickerVisible: false,
      localInitialOperatorDescription: '',
      localOperatorDescription: '',
      localOrderID: null
    };
  },
  mounted () {
    this.realization_date = null;
    this.user_id = 0;
    this.execution = 0;
    this.$bus.$on('machine-execution-popup-show', this.openPopup);
    this.localOrderID = this.orderID;
    this.localInitialOperatorDescription = this.operatorDescription;
    this.localOperatorDescription = this.operatorDescription;
  },
  methods: {
    handleClose () {
      this.isHidden = true;
      this.dateRangePickerVisible = false;
    },
    validateForm () {
      FormUtils.validate(this.$refs['order-creator-service-machine-execution-form'], this.save);
    },
    save() {
      this.submitInProgress = true;
      let endpointUrl = '/api/orders/machines/add-execution/' + this.machineExecutionID;
      let params = {
        realization_date: this.realization_date,
        execution: this.execution
      };

      if (this.isAdmin) {
        params.user_id = this.user_id
      }

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: params,
        successTitle: 'Zapisano wykonanie maszyny',
        successText: 'Wybrane wykonanie zostało zapisane',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja danych wykonania nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          Vue.nextTick(() => {
            this.submitInProgress = false;
            this.handleClose();

            if (this.localOperatorDescription !== this.localInitialOperatorDescription) {
              FormUtils.submit(this, {
                endpoint: '/api/orders/operator-update/' + this.localOrderID,
                formData: { operator_description: this.localOperatorDescription },
                successTitle: 'Zapisano uwagi do wykonania',
                successText: 'Uwagi do wykonania zostały zaktualizowane',
                errorTitle: 'Wystąpił błąd',
                errorText: 'Modyfikacja uwag wykonania nie powiodła się. Spróbuj ponownie.',
                successAction: () => {
                  Vue.nextTick(() => {
                    if (this.sourceView === 'operatorListView') {
                      this.$bus.$emit('reload-operator-list');
                    } else {
                      this.$bus.$emit('reload-order-view');
                    }
                  });
                }
              });
            } else if (this.sourceView === 'operatorListView') {
              this.$bus.$emit('reload-operator-list');
            } else {
              this.$bus.$emit('reload-order-view');
            }
          });
        },
        failAction: () => {
          this.submitInProgress = false;
        },
      });
    },
    cancel () {
      this.handleClose();
    },
    prepareDateString (input) {
      if (input === null) {
        return 'Wybierz datę';
      }
      input = new Date(input);

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date()
      }
      return classes
    },
    openPopup (machineExecutionID, machineExecutionQuantity, machineDoneQuantity, allWorkers = [], serviceName = '', orderName = '', productCode = '', orderID = null, operatorDescription = null) {
      this.machineExecutionID = machineExecutionID;
      this.executionQuantity = machineExecutionQuantity;
      this.doneExecutionQuantity = machineDoneQuantity;
      this.allWorkers = allWorkers.filter(item => item.type === 'OPERATOR');
      this.executionServiceName = serviceName;
      this.executionOrderName = orderName;
      this.executionsProductCode = productCode;

      this.localOrderID = this.orderID;
      if (orderID) {
        this.localOrderID = orderID;
      }

      this.localInitialOperatorDescription = this.operatorDescription;
      this.localOperatorDescription = this.operatorDescription;
      if (operatorDescription) {
        this.localInitialOperatorDescription = operatorDescription;
        this.localOperatorDescription = operatorDescription;
      }

      this.user_id = null;
      this.execution = 0;
      this.dateRange.startDate = new Date();
      this.realization_date = null;

      setTimeout(() => {
        this.isHidden = false;
        this.dateRangePickerVisible = true;
        if (this.$refs['order-creator-service-machine-execution-form']) {
          this.$refs['order-creator-service-machine-execution-form'].reset();
        }
      }, 0);
    }
  },
  beforeDestroy () {
    this.$bus.$off('machine-execution-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
