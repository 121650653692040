<template>
  <d-modal
    size="lg"
    :class="{ 'big-popup': true, 'is-hidden': isHidden , 'services-to-settle-popup': true}"
    @close="handleClose">
    <d-modal-header>
      <d-modal-title class="w-100">
        Zmiany w zamówieniach HPL
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <order-sync-history
        ref="order-sync-history"
        :is-on-dashboard="true"
        :is-visible="true"
        :logs-list="logsLits"
        :order-id="0"/>
    </d-modal-body>
  </d-modal>
</template>

<script>
import OrderSyncHistory from '@/components/forms/order/OrderSyncHistory.vue';

export default {
  name: 'orders-logs-popup',
  components: {
    'order-sync-history': OrderSyncHistory
  },
  data () {
    return {
      logsLits: [],
      isHidden: true
    };
  },
  mounted () {
    this.$bus.$on('orders-logs-popup-show', this.openPopup);
  },
  methods: {
    handleClose () {
      this.isHidden = true;
    },
    openPopup (listToDisplay) {
      this.logsLits = listToDisplay;

      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    }
  },
  beforeDestroy () {
    this.$bus.$off('orders-logs-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}

.modal-body {
  padding-left: 0;
  padding-right: 0;
}
</style>
