var render = function render(){var _vm=this,_c=_vm._self._c;return _c('d-modal',{class:{ 'is-hidden': _vm.isHidden },on:{"close":_vm.handleClose}},[_c('d-modal-header',[_c('d-modal-title',[_vm._v(" Generuj raport zrealizaowanych zamówień ")])],1),_c('d-modal-body',[_c('d-form',[_c('validation-observer',{ref:"orders-report-download-form",attrs:{"tag":"form"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v(" Wybierz zakres dat raportu ")]),_c('validation-provider',{attrs:{"rules":"required|requiredDateRange"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{class:{
                'form-control': true,
                'is-date-range': true,
                'vs-wrap-text': true,
                'is-invalid': errors.length
              }},[_c('date-range-picker',{ref:"picker",staticStyle:{"min-width":"240px"},attrs:{"locale-data":{
                  'firstDay': 1,
                  'format': 'DD-MM-YYYY',
                  'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                  'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                },"singleDatePicker":false,"timePicker":false,"showWeekNumbers":false,"showDropdowns":false,"autoApply":true,"ranges":false,"linkedCalendars":true},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v(" "+_vm._s(_vm._f("dateStart")(picker.startDate))+" - "+_vm._s(_vm._f("dateEnd")(picker.endDate))+" ")]}}],null,true),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])],1)],1),_c('d-modal-footer',[_c('d-button',{staticClass:"ml-0",attrs:{"theme":"danger","disabled":_vm.generateInProgress},on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_vm._v(" Anuluj ")]),_c('d-button',{staticClass:"ml-auto",attrs:{"theme":"success","disabled":_vm.generateInProgress},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(_vm.generateInProgress)?_c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}}):_vm._e(),_vm._v(" Generuj raport ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }