<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="9"
        lg="9"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Operatorzy i maszyny</span>
        <h3 class="page-title">Lista przydziału do maszyn</h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="3"
        lg="3"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="success"
          @click.prevent="$router.push('/operatorzy-i-maszyny/nowy')">
          <i class="material-icons">add</i>
          Dodaj wpis
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="allDataLoaded"
      style="width: 100%"
      class="d-flex ml-0 mb-3 page-filters">
      <validation-observer
        ref="operators-machines-filters-form"
        tag="form"
        style="width: 100%"
        class="d-flex mb-2">
        <v-select
          style="min-width: 25%;"
          class="mr-1 mb-2 mt-auto"
          :options="allWorkers"
          v-model="filters.user_id"
          :reduce="user => user.id"
          placeholder="Wybierz pracownika"
          :searchable="false"
          label="name">
        </v-select>

        <v-select
          style="min-width: 25%;"
          class="mr-1 mb-2 mt-auto"
          :options="allMachines"
          v-model="filters.machine_id"
          :reduce="machine => machine.id"
          placeholder="Wybierz maszynę"
          :searchable="false"
          label="name">
        </v-select>

        <div class="w-100 mb-2 mr-1" style="width: 25%;min-width: 200px;max-width: 220px;">
          <label for="dateStart" class="mb-0">
            <small>Data:</small>
          </label>
          <div
            :class="{
              'form-control is-date-range vs-wrap-text w-100 text-center': true,
              'is-disabled': false
            }">
            <date-range-picker
              ref="picker"
              :locale-data="{
                'firstDay': 1,
                'format': 'DD-MM-YYYY',
                'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                'applyLabel': 'OK',
                'cancelLabel': 'Anuluj'
              }"
              style="width: auto;"
              v-model="dateRange"
              :singleDatePicker="true"
              :timePicker="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :ranges="false"
              :linkedCalendars="true"
              :auto-apply="true">
              <template v-slot:input="picker">
                {{ picker.startDate | dateFilter }}
              </template>
            </date-range-picker>
          </div>
        </div>

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-auto mr-1 mb-2 mt-auto"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2 mt-auto"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%;">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle">Operator</th>
            <th scope="col" class="border-0 align-middle text-center">Maszyna</th>
            <th scope="col" class="border-0 align-middle text-center">Data</th>
            <th scope="col" class="border-0 align-middle text-center">Początek</th>
            <th scope="col" class="border-0 align-middle text-center">Koniec</th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="allDataLoaded">
          <tr
            v-for="(item, index) of workingHours"
            :key="'machine-' + index">
            <td class="align-middle py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Operator</label>
              {{ item.user && item.user.name ? item.user.name : '-' }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Maszyna</label>
              {{ item.machine && item.machine.name ? item.machine.name : '-' }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Data</label>
              {{ item.date }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Początek</label>
              {{ prepareTimeString(item.start_at) }}
            </td>
            <td class="align-middle text-center py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Koniec</label>
              {{ prepareTimeString(item.end_at) }}
            </td>
            <td class="text-center align-middle pt-0">
              <d-button
                theme="primary"
                @click.native="$router.push('/operatorzy-i-maszyny/edycja/' + item.id)"
                class="mx-2 mt-2"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
              <d-button
                theme="danger"
                :disabled="removeInProgress"
                @click.native="removeItem(item.id)"
                class="mx-2 mt-2"
                title="Usuń">
                <i class="material-icons">delete</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="workingHours.length === 0">
            <td
              colspan="6"
              class="text-center">
              Brak pozycji do wyświetlenia&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!allDataLoaded" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>

    <pagination :meta-data="paginationMetaData" />
  </d-container>
</template>

<script>
import debounce from 'lodash.debounce';
import FormUtils from '@/utils/FormUtils.js';
import CalendarUtils from '@/utils/CalendarUtils.js';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import ListUtils from '@/utils/ListUtils.js';
import Pagination from '@/components/common/Pagination.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'working-hours',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination,
    'date-range-picker': DateRangePicker
  },
  filters: {
    dateFilter (input) {
      if (input === null) {
        return ' szukaj w dniu ';
      }

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    }
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.loadedWorkers && this.loadedMachines;
    }
  },
  data() {
    return {
      allMachines: [],
      allWorkers: [],
      dateRange: {
        endDate: null,
        startDate: null
      },
      filters: {
        machine_id: null,
        user_id: null
      },
      loaded: false,
      loadedMachines: false,
      loadedWorkers: false,
      loadError: false,
      removeInProgress: false,
      workingHours: []
    };
  },
  mounted () {
    this.loadAdditionalData();
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.user_id) {
        where.user_id = this.filters.user_id;
      }

      if (this.filters.machine_id) {
        where.machine_id = this.filters.machine_id;
      }

      if (this.dateRange.startDate) {
        where.date = CalendarUtils.formatDateString(this.dateRange.startDate);
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/users/working-hours/items?page=' + this.currentPage,
        listField: 'workingHours',
        pagination: true,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        machine_id: null,
        user_id: null
      };
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['operators-machines-filters-form'], this.debouncedLoadFilteredData);
    },
    removeItem (machineID) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/users/working-hours/delete/',
        id: machineID,
        successTitle: 'Usunięto wpis',
        successText: 'Wybrany wpis został usunięty',
        confirmTitle: 'Czy na pewno chcesz usunąć ten wpis?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie wpisu nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        }
      });
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/users/items',
        method: 'get',
        outputKey: 'allWorkers',
        loadedKey: 'loadedWorkers',
        errorKey: 'loadError',
        params: {
          pagination: 0,
          where: {
            active: 1,
            type: 'OPERATOR'
          }
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/items',
        method: 'get',
        outputKey: 'allMachines',
        loadedKey: 'loadedMachines',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    },
    prepareTimeString (input) {
      let inputAsArray = input.split(':');
      inputAsArray.pop();
      return inputAsArray.join(':');
    },
  }
};

</script>

<style lang="scss">
.page-filters form {
  width: 100%;
}

@media screen  and (max-width: 1230px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      input {
        margin-right: 0!important;
        min-width: unset!important;
        width: 100%;
      }
    }
  }
}

@media screen  and (max-width: 768px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      .v-select {
        margin-right: 0!important;
        max-width: unset!important;
        width: 100%!important;
      }
    }
  }
}

</style>
