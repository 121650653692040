<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Wykonawcy</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja wykonawcy</template>
          <template v-else>Dodawanie wykonawcy</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/wykonawcy')"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!loaded && !loadError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadError">
          <d-tabs
            card
            :class="{'is-loading': !loaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <validation-observer
                ref="contractor-form"
                tag="form">
                <d-row class="w-100">
                  <d-col sm="12" md="8">
                    <div
                      class="form-group"
                      style="max-width: 600px;">
                      <label for="name">
                        Nazwa wykonawcy:
                      </label>

                      <validation-provider
                        rules="required"
                        name="name"
                        v-slot="{ errors }">
                        <d-input
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.name" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>
              </validation-observer>

              <d-alert
                v-if="loadError"
                show
                theme="warning">
                Wczytywanie danych nie powiodło się.
                <a
                  href="javascript:window.location.reload();"
                  class="alert-link">
                  Odśwież stronę
                </a>
                aby spróbować ponownie.
              </d-alert>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'contractors-form',
  computed: {
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      data: {
        id: 0,
        name: ''
      },
      loaded: false,
      loadError: false
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
    }
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/contractors/item/',
        instanceFields: ['id', 'name'],
        loadedKey: 'loaded',
        errorKey: 'loadError'
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['contractor-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = { name: this.data.name };
      let endpointUrl = this.isEdit ? '/api/contractors/update/' + this.id : '/api/contractors/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano wykonawcę',
        successText: 'Wybrany wykonawca został zapisany',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja wykonawcy nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push('/wykonawcy');
        }
      });
    }
  }
};
</script>

