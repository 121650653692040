<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zamówienia</span>
        <h3 class="page-title">
          <template v-if="isEdit">
            Edycja zamówienia
          </template>
          <template v-else>Dodawanie zamówienia</template>
        </h3>
        <template v-if="isEdit">
          <div
            v-if="data.estimated_date"
            :class="{
              'text-danger': data.endangered,
              'text-primary': !data.endangered ,
              'pt-2': true
            }">
            Planowane zakończenie zamówienia: {{ data.estimated_date }}
          </div>
        </template>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/zamowienia')">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          v-if="isEdit && data.hpl_id"
          theme="primary"
          class="ml-3"
          :disabled="downloadInProgress"
          @click.prevent="downloadSummary">
          Pobierz podsumowanie
        </d-button>
        <d-button
          :disabled="isBlocked"
          theme="success"
          class="ml-3"
          @click.prevent="validateForm">
          Zapisz zamówienie
        </d-button>
      </d-col>
    </d-row>
    <d-alert
      v-if="isBlocked"
      show
      theme="warning"
      class="mb-4">
      To zamówienie jest aktualnie edytowane przez innego użytkownika. Aby edytować spróbuj ponownie później.
    </d-alert>


    <!-- Form -->
    <d-form class="mt-3">
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadError"
          class="loading-spinner pb-3 pt-3 text-center position-fixed">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadError">
          <validation-observer
            ref="order-form"
            tag="form">
            <d-tabs
              card
              :nav-class="allDataLoaded ? validationResults : ''"
              :class="{'is-loading': !allDataLoaded}">
              <!-- Dane ogólne -->
              <d-tab
                title="Dane ogólne"
                active
                class="px-3 px-md-4">
                <div class="d-flex border-bottom mb-3 align-items-center flex-wrap flex-lg-nowrap">
                  <h4 class="pb-3 pr-0 pr-sm-2 mr-auto">Dane ogólne</h4>
                  <div
                    v-if="isEdit"
                    class="form-group mt-0 d-flex ml-md-auto align-items-center"
                    style="width: 500px;" >
                    <label
                      for="status"
                      class="mr-1 mb-0 text-nowrap">
                      Status<span class="d-none d-inline-sm"> zamówienia</span>:
                    </label>
                    <order-status-select
                      ref="status-select"
                      :isEdit="isEdit && !isBlocked"/>
                  </div>
                </div>
                <d-row v-if="isEdit">
                  <d-col md="12">
                    <p class="mb-1">
                      Utworzono: <strong class="text-dark">{{ prepareDateTimeString(data.created_at) }}</strong>
                    </p>

                    <template v-if="data.hpl_id > 0">
                      <p class="mt-0 mb-1">
                        Nie wymaga sampli: <strong class="text-dark">{{ data.dates_not_required === 1 ? 'Tak' : 'Nie' }}</strong>
                      </p>
                      <a
                        :href="hplUrl + 'zamowienia-hafciarnia/edycja/' + parseInt(data.hpl_id, 10)"
                        target="_blank"
                        rel="noopener noreferrer">
                        (Zobacz zamówienie w HPL)
                      </a>
                    </template>
                  </d-col>
                </d-row>
                <d-row class="mt-4">
                  <d-col md="6">
                    <div class="form-group">
                      <label for="customer">
                        Nazwa klienta:
                      </label>

                      <validation-provider
                        rules="required"
                        name="customer"
                        v-slot="{ errors }">
                        <d-input
                          :disabled="isEdit"
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.customer_name" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col md="6">
                    <div class="form-group">
                      <label for="order-name">
                        Nazwa zamówienia:
                      </label>

                      <validation-provider
                        rules="required"
                        name="order-name"
                        v-slot="{ errors }">
                        <d-input
                          :disabled="isEdit"
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.order_name" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>
                <d-row>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="products-planned">
                        Planowana data otrzymania produktów:
                      </label>

                      <validation-provider
                        rules="required|requiredStartRange"
                        name="products-planned"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-date-range': true,
                            'vs-wrap-text': true,
                            'is-invalid': errors.length,
                            'is-disabled': (isEdit && !isProductsDateRangeEnabled) || isBlocked
                          }">
                          <date-range-picker
                            v-if="dateRangePickerVisible"
                            ref="picker"
                            :disabled="(isEdit && !isProductsDateRangeEnabled) || isBlocked"
                            :locale-data="{
                              'firstDay': 1,
                              'format': 'DD-MM-YYYY',
                              'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                              'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                              'applyLabel': 'OK',
                              'cancelLabel': 'Anuluj'
                            }"
                            class="w-100"
                            :singleDatePicker="true"
                            :timePicker="false"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            v-model="productsDateRange"
                            :ranges="false"
                            :linkedCalendars="true"
                            :date-format="dateFormat"
                            :auto-apply="true">
                            <template v-slot:input="picker">
                              {{ prepareDateString(picker.startDate) }}
                            </template>
                          </date-range-picker>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="deadline">
                        Ostateczny termin realizacji:
                      </label>

                      <validation-provider
                        :rules="data.status === 'IN_REALIZATION' ? 'required|requiredStartRange' : ''"
                        name="deadline"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-date-range': true,
                            'vs-wrap-text': true,
                            'is-invalid': errors.length,
                            'is-disabled': isBlocked
                          }">
                          <date-range-picker
                            v-if="dateRangePickerVisible"
                            ref="pickerDeadline"
                            :locale-data="{
                              'firstDay': 1,
                              'format': 'DD-MM-YYYY',
                              'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                              'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                              'applyLabel': 'OK',
                              'cancelLabel': 'Anuluj'
                            }"
                            class="w-100"
                            :disabled="isBlocked"
                            :singleDatePicker="true"
                            :timePicker="false"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            v-model="deadlinedateRange"
                            :ranges="false"
                            :linkedCalendars="true"
                            :date-format="deadlineDateFormat"
                            :auto-apply="true">
                            <template v-slot:input="pickerDeadline">
                              {{ prepareDateString(pickerDeadline.startDate) }}
                            </template>
                          </date-range-picker>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>
                <d-row>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="delivery-method">
                        Sposób dostawy:
                      </label>
                      <validation-provider
                        :rules="isQAOnly ? 'required' : ''"
                        name="delivery-method"
                        v-slot="{ errors }">
                        <div
                          :class="{
                            'form-control': true,
                            'is-select': true,
                            'is-invalid': errors.length,
                            'vs-wrap-text': true
                          }">
                          <v-select
                            :disabled="!isQAOnly || isBlocked"
                            :options="orderDeliveryMethodsOptions"
                            v-model="data.delivery_method"
                            :reduce="method => method.value"
                            placeholder="Wybierz sposób dostawy"
                            label="text">
                          </v-select>
                        </div>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                      <small
                        v-if="!isQAOnly"
                        class="text-secondary">
                        Wybór sposobu dostawy będzie możliwy na etapie kontroli jakości.
                      </small>
                    </div>
                  </d-col>
                  <d-col sm="6">
                    <div class="form-group">
                      <label for="place-in-warehouse">
                        Miejsce w magazynie:
                      </label>
                      <validation-provider
                        name="place-in-warehouse"
                        v-slot="{ errors }">
                        <d-input
                          :disabled="isBlocked"
                          :state="errors.length ? 'invalid' : null"
                          v-model="data.place_in_warehouse" />
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                    </div>
                  </d-col>
                </d-row>

                <files-list
                  :files="data.files"
                  class="mb-4" />

                <d-alert
                  v-if="loadError"
                  show
                  theme="warning">
                  Wczytywanie danych nie powiodło się.
                  <a
                    href="javascript:window.location.reload();"
                    class="alert-link">
                    Odśwież stronę
                  </a>
                  aby spróbować ponownie.
                </d-alert>
              </d-tab>

              <!-- Zamówienie -->
                <d-tab
                  title="Zamówienie"
                  class="px-3 px-md-4">
                  <h4 class="pb-3 mb-0 border-bottom d-flex flex-sm-wrap">
                    Zamówione produkty

                    <d-button
                      theme="success"
                      class="ml-auto"
                      :disabled="isBlocked || (data.status !== null && data.status !== 'NEW' && data.status !== 'WAITING_PRODUCTS')"
                      @click.prevent="$refs['order-creator'].addProduct()">
                      <i class="material-icons">add</i>
                      Dodaj produkt
                    </d-button>
                  </h4>

                  <order-creator
                    ref="order-creator"
                    :isBlocked="isBlocked"
                    :isInPreparation="isInPreparation"
                    :isMachinesEnabled="isMachinesEnabled"
                    :isProductSetup="isProductSetup"
                    :isQAOnly="isQAOnly"
                    :isSamplesOnly="isSamplesOnly"
                    :orderForm="$refs['order-form']"
                    :orderName="data.order_name"
                    :orderedProducts="data.products"
                    :performPriceRecalculate="data.force_calculate_prices"
                    :status="data.status" />

                </d-tab>
                <!-- Historia realizacji -->
                <d-tab
                  title="Historia realizacji zdobień"
                  class="px-3 px-md-4"
                  :disabled="!isEdit">
                  <h4 class="mb-3 pb-3 border-bottom">
                    Historia realizacji zdobień
                  </h4>
                  <p
                    v-if="!isEdit"
                    class="text-center">
                    Dane o historii realizacji zdobień będą dostępne po zapisaniu zamówienia&hellip;
                  </p>

                  <order-execution-history
                    v-if="isEdit"
                    ref="order-execution-history"
                    :operatorDescription="data.operator_description"/>
              </d-tab>
              <!-- Historia kontroli produktów -->
              <d-tab
                title="Historia kontroli produktów"
                class="px-3 px-md-4"
                :disabled="!isEdit">
                <h4 class="mb-3 pb-3 border-bottom">
                  Historia kontroli produktów
                </h4>
                <p
                  v-if="!isEdit"
                  class="text-center">
                  Dane o historii kontroli produktów będą dostępne po zapisaniu zamówienia&hellip;
                </p>

                <order-control-history
                  v-if="isEdit"
                  ref="order-control-history"/>
              </d-tab>
              <!-- Historia synchronizacji -->
              <d-tab
                title="Historia synchronizacji"
                class="px-3 px-md-4"
                :disabled="!isEdit">
                <order-sync-history
                  ref="order-sync-history"
                  :is-readonly="isBlocked"
                  :is-visible="isEdit"
                  :logs-list="data.logs"
                  :order-id="data.id"/>
              </d-tab>
              <!-- Kartony z HPL -->
              <d-tab
                title="Kartony z HPL"
                class="px-3 px-md-4"
                :disabled="!data.boxes || !data.boxes.length">
                <order-boxes-list
                  ref="order-boxes-list"
                  :boxes-list="data.boxes"/>
              </d-tab>

              <!-- Zwroty do HPL -->
              <d-tab
                title="Zwroty do HPL"
                class="px-3 px-md-4"
                :disabled="!data.returns || !data.returns.length">
                <order-returns-list
                  ref="order-returns-to-hpl"
                  :is-readonly="isBlocked"
                  :returns-list="data.returns"/>
              </d-tab>
            </d-tabs>
          </validation-observer>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  <order-creator-service-machine-execution
    :orderID="data.id"
    :operatorDescription="data.operator_description"
    :sourceView="'orderView'" />
  <order-creator-qa-popup />
  </d-container>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import UniversalUtils from '@/utils/UniversalUtils.js';
import CalendarUtils from '@/utils/CalendarUtils.js';
import OrderCreator from './order/OrderCreator.vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import OrderCreatorServiceMachineExecutionPopup from '@/components/popups/OrderCreatorServiceMachineExecutionPopup.vue';
import OrderCreatorQualityControlPopup from '@/components/popups/OrderCreatorQualityControlPopup.vue';
import OrderStatusPhases from '@/data/order-phases.js';
import OrderExecutionHistory from './order/OrderExecutionHistory.vue';
import OrderControlHistory from './order/OrderControlHistory.vue';
import OrderStatusSelect from './order/OrderStatusSelect.vue';
import OrderSyncHistory from './order/OrderSyncHistory.vue';
import OrderBoxesList from './order/OrderBoxesList.vue';
import OrderReturnsList from './order/OrderReturnsList.vue';
import FilesList from '@/components/forms/order/FilesList.vue';

export default {
  name: 'order-form',
  components: {
    'order-creator': OrderCreator,
    'date-range-picker': DateRangePicker,
    'order-creator-service-machine-execution': OrderCreatorServiceMachineExecutionPopup,
    'order-creator-qa-popup': OrderCreatorQualityControlPopup,
    'order-execution-history': OrderExecutionHistory,
    'order-control-history': OrderControlHistory,
    'order-status-select': OrderStatusSelect,
    'order-sync-history': OrderSyncHistory,
    'order-boxes-list': OrderBoxesList,
    'order-returns-list': OrderReturnsList,
    'files-list': FilesList
  },
  computed: {
    allDataLoaded() {
      return this.loaded && !this.actionInProgress && !this.isChildDataLoading;
    },
    isEdit () {
      return !!this.$route.params.id;
    },
    isBlocked () {
      return this.isEdit && !this.data.can_edit;
    },
    isProductSetup () {
      return this.data.status === null || OrderStatusPhases.productsSetup.indexOf(this.data.status) > -1;
    },
    isQAOnly () {
      return OrderStatusPhases.qaOnly.indexOf(this.data.status) > -1;
    },
    isSamplesOnly () {
      return OrderStatusPhases.samplesOnly.indexOf(this.data.status) > -1;
    },
    isInPreparation () {
      return this.data.status === null || OrderStatusPhases.inPreparation.indexOf(this.data.status) > -1;
    },
    isMachinesEnabled () {
      return this.data.status === null || OrderStatusPhases.machinesEnabled.indexOf(this.data.status) > -1;
    },
    orderDeliveryMethodsOptions () {
      return [
        {
          text: 'Kurier',
          value: 'COURIER'
        },
        {
          text: 'Zwrot',
          value: 'RETURN'
        }
      ];
    },
    validationResults() {
      if (!this.allDataLoaded) {
        return false;
      }

      let errorTabs = [];

      if (!this.isValidationRequested) {
        if (this.data.logs.find(item => !item.is_read)) {
          errorTabs.push('error-5');
        }
        return errorTabs.join(' ');
      }

      let rawErrors = this.$refs['order-form'].errors;
      let errorKeys = Object.keys(rawErrors);
      let errors = [];

      for (let i = 0; i < errorKeys.length; i++) {
        if (rawErrors[errorKeys[i]].length) {
          if ((errorKeys[i].indexOf('product-') > -1) || (errorKeys[i].indexOf('service-') > -1)) {
            errors.push('order-creator');
          } else {
            errors.push(errorKeys[i]);
          }
        }
      }

      let tab1Fields = [
        'customer',
        'order-name',
        'deadline',
        'products-planned'
      ];

      if (this.isQAOnly) {
        tab1Fields.push('delivery-method');
      }

      for (let i = 0; i < tab1Fields.length; i++) {
        if (errors.indexOf(tab1Fields[i]) > -1) {
          errorTabs.push('error-1');
          break;
        }
      }

      if (!this.$refs['order-creator'] || !this.$refs['order-creator'].products.length) {
        errorTabs.push('error-2');
      } else if (errors.indexOf('order-creator') > -1) {
        errorTabs.push('error-2');
      }

      return errorTabs.join(' ');
    },
    hplUrl () {
      return process.env.VUE_APP_HPL_URL;
    }
  },
  watch: {
    'deadlinedateRange.startDate': function (newValue) {
      if (newValue === null) {
        this.data.deadline = null;
      }

      this.data.deadline = this.prepareDateString(newValue);
    },
    'productsDateRange.startDate': function (newValue) {
      if (newValue === null) {
        this.data.products_planned_date = null;
      }

      this.data.products_planned_date = this.prepareDateString(newValue);
    }
  },
  data() {
    return {
      actionInProgress: false,
      blockInterval: null,
      data: {
        boxes: [],
        can_add_to_schedule: false,
        can_edit: false,
        created_at: '',
        customer_name: 'HeadWear',
        dates_not_required: 0,
        deadline: null,
        delivery_method: null,
        endangered: false,
        estimated_date: null,
        files: [],
        force_calculate_prices: false,
        hpl_id: 0,
        id: 0,
        logs: [],
        operator_description: '',
        order_name: '',
        place_in_warehouse: '',
        products: [],
        products_planned_date: null,
        returns: [],
        status: null
      },
      dateRangePickerVisible: false,
      deadlinedateRange: {
        startDate: null
      },
      downloadInProgress: false,
      isProductsDateRangeEnabled: false,
      isValidationRequested: false,
      loaded: false,
      loadError: false,
      orderStatusVisible: false,
      productsDateRange: {
        startDate: null
      },
      isChildDataLoading: false // this is for child components to trigger loader
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.isProductsDateRangeEnabled = true;
      Vue.nextTick(() => {
        Vue.set(this.productsDateRange, 'startDate', new Date());
        this.loaded = true;
        this.dateRangePickerVisible = true;
      });
    }

    this.$bus.$on('order-save', this.submitForm);
    this.$bus.$on('reload-order-view', this.reloadView);
    this.$bus.$on('show-order-loader', this.setIsChildDataLoading);
  },
  methods: {
    loadItemData () {
      this.dateRangePickerVisible = false;
      this.loaded = false;

      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/orders/item/',
        instanceFields: ['id', 'can_edit', 'created_at', 'order_name', 'customer_name', 'dates_not_required', 'products_planned_date', 'deadline', 'delivery_method', 'products', 'status', 'estimated_date', 'endangered', 'place_in_warehouse', 'operator_description', 'hpl_id', 'force_calculate_prices', 'logs', 'files', 'boxes', 'returns', 'can_add_to_schedule'],
        booleanFields: ['force_calculate_prices', 'can_add_to_schedule'],
        loadedKey: 'loaded',
        errorKey: 'loadError',
        successAction: (response) => {
          if (response.products.length) {
            this.$refs['order-creator'].setProducts(response.products);
            this.$refs['order-execution-history'].setProducts(response.products);
            this.$refs['order-control-history'].setProducts(response.products);

            Vue.nextTick(() => {
              if (!response.products_planned_date) {
                Vue.set(this.productsDateRange, 'startDate', null);
                this.isProductsDateRangeEnabled = true;
              } else {
                Vue.set(this.productsDateRange, 'startDate', new Date(response.products_planned_date));
                this.isProductsDateRangeEnabled = false;
              }

              if (!response.deadline) {
                Vue.set(this.deadlinedateRange, 'startDate', null);
              } else {
                Vue.set(this.deadlinedateRange, 'startDate', new Date(response.deadline));
              }

              this.dateRangePickerVisible = true;
              this.orderStatusVisible = true;

              if (this.isEdit && this.orderStatusVisible) {
                this.$refs['status-select'].setStatus(this.data.status, this.data.id);
              }
            });
          }

          if (response.can_edit) {
            this.blockOrder();
            this.blockInterval = setInterval(this.blockOrder, 3 * 60 * 1000);
          }
        }
      });
    },
    validateForm () {
      this.isValidationRequested = true;
      FormUtils.validate(this.$refs['order-form'], this.submitForm);
    },
    submitForm () {
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/orders/update/' + this.id : '/api/orders/store';

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano zamówienie',
        successText: 'Wybrane zamówienie zostało zapisane',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja zamówienia nie powiodła się. Spróbuj ponownie.',
        successAction: (response) => {
          if (!this.isEdit) {
            this.$bus.$emit('update-new-orders-count');
            this.$router.push('/zamowienia/edycja/' + parseInt(response.item.id, 10));
            return;
          }

          this.loadItemData();
          this.$bus.$emit('update-schedules-without-date-count');
        }
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        order_name: this.data.order_name,
        customer_name: this.data.customer_name,
        products_planned_date: this.data.products_planned_date,
        deadline: this.data.deadline,
        place_in_warehouse: this.data.place_in_warehouse
      };

      if (this.isQAOnly) {
        formDataToSend.delivery_method = this.data.delivery_method;
      }

      formDataToSend.products = this.$refs['order-creator'].getProductsForAPI();

      return formDataToSend;
    },
    prepareDateString (input) {
      if (input === null) {
        return 'Wybierz datę';
      }
      input = new Date(input);

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    prepareDateTimeString (dateString) {
      if (!dateString) {
        return '-';
      }

      let newDate = new Date(dateString);

      let datePart = [
        newDate.getFullYear(),
        newDate.getMonth() + 1,
        newDate.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');

      let timePart = [
        newDate.getHours(),
        newDate.getMinutes()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join(':');

      return `${datePart} ${timePart}`;
    },
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date()
      }
      return classes
    },
    deadlineDateFormat (classes, date) {
      let currentDate = new Date();

      if (!classes.disabled) {
        classes.disabled = date.getTime() < currentDate;
      }
      return classes
    },
    reloadView () {
      this.loadItemData()
    },
    downloadSummary () {
      this.downloadInProgress = true;

      this.$http.post('/api/orders/summary/' + this.data.id, {}, {
        responseType: 'blob'
      }).then(response => {
        let currentDateString = CalendarUtils.formatDateString(new Date());
        let fileName = `zamowienie_${this.data.id}_${currentDateString}.pdf`;
        UniversalUtils.downloadFile(response.data, fileName);
        this.downloadInProgress = false;

        Vue.swal({
          title: 'Plik zostal pobrany',
          html: `Plik <i>${fileName}</i>, zawierający podsumowanie zamówienia, został pobrany.`,
          icon: 'success',
          confirmButtonText: 'OK',
          buttonsStyling: true
        })
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas pobierania',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.downloadInProgress = false;
      });
    },
    blockOrder () {
      console.log('ORDER BLOCK');
      this.$http.post('/api/orders/start-edit/' + parseInt(this.$route.params.id, 10)).then(() => {}).catch(error => {
        console.log(error);
      });
    },
    unblockOrder () {
      console.log('ORDER UNBLOCK');
      if (this.blockInterval) {
        clearInterval(this.blockInterval);
      }
      this.$http.post('/api/orders/end-edit/' + parseInt(this.data.id, 10)).then(() => {}).catch(error => {
        console.log(error);
      });
    },
    setIsChildDataLoading (isLoading) {
      Vue.set(this, 'isChildDataLoading', isLoading);
    }
  },
  beforeDestroy () {
    this.$bus.$off('order-save', this.submitForm);
    this.$bus.$off('reload-order-view', this.reloadView);
    this.$bus.$off('show-order-loader', this.setIsChildDataLoading);

    if (this.data.id && this.data.can_edit) {
      this.unblockOrder();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-content-container {
  position: relative;
}

.page-header {
  background: #F5F6F8;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  border-radius: 0 0 0.625rem 0.625rem;
}
</style>
