<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="9"
        lg="9"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Zdobienia</span>
        <h3 class="page-title">Lista zdobień</h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="3"
        lg="3"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="success"
          @click.prevent="$router.push('/zdobienia/nowy')">
          <i class="material-icons">add</i>
          Dodaj zdobienie
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="loaded"
      style="width: 100%;"
      class="d-flex ml-0 mb-0 page-filters">
      <validation-observer
        ref="services-filters-form"
        tag="form"
        class="d-flex mb-2">
        <d-input
          placeholder="Podaj nazwę usługi"
          v-model="filters.search"
          class="mr-1 mb-2"
          style="min-width: 290px;" />

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-md-auto mr-1 mb-2"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%;">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle">Nazwa zdobienia</th>
            <th scope="col" class="border-0 align-middle text-center">Kod zdobienia</th>
            <th scope="col" class="border-0 align-middle text-center">Grupa zdobień</th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="loaded">
          <tr
            v-for="(service, index) of services"
            :key="'service-' + index">
            <td class="align-middle py-lg-3 py-md-2 py-3">
              {{ service.name }}<br>
            </td>
            <td class="align-middle text-center py-lg-3 py-md-2 py-3">
              {{ service.code }}
            </td>
            <td class="align-middle text-center py-lg-3 py-md-2 py-3">
              <template v-if="service.group_name">{{ service.group_name }}</template>
              <template v-else>-</template>
            </td>
            <td
              class="align-middle text-center py-lg-3 py-md-2 py-3"
              style="width: 150px;">
              <d-button
                theme="primary"
                @click.native="$router.push('/zdobienia/edycja/' + service.id)"
                class="mr-2"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
              <d-button
                theme="danger"
                :disabled="removeInProgress"
                @click.native="removeService(service.id)"
                title="Usuń">
                <i class="material-icons">delete</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="services.length === 0">
            <td
              colspan="5"
              class="text-center">
              Brak zdobień do wyświetlenia&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!loaded" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>

    <pagination :meta-data="paginationMetaData" />
  </d-container>
</template>

<script>
import debounce from 'lodash.debounce';
import FormUtils from './../utils/FormUtils.js';
import HasFilters from './../mixins/HasFilters.vue';
import HasPagination from './../mixins/HasPagination.vue';
import ListUtils from './../utils/ListUtils.js';
import Pagination from './../components/common/Pagination.vue';

export default {
  name: 'services',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination
  },
  data() {
    return {
      services: [],
      loaded: false,
      loadError: false,
      removeInProgress: false,
      filters: {
        search: ''
      }
    };
  },
  mounted () {
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.search) {
        where.search = this.filters.search;
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/services/items?page=' + this.currentPage,
        listField: 'services',
        pagination: true,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.filters = {
        search: ''
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['services-filters-form'], this.debouncedLoadFilteredData);
    },
    removeService (serviceID) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/services/delete/',
        id: serviceID,
        successTitle: 'Usunięto zdobienie',
        successText: 'Wybrane zdobienie zostało usunięte',
        confirmTitle: 'Czy na pewno chcesz usunąć to zdobienie?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie zdobienia nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        }
      });
    },
  }
};
</script>

<style lang="scss">
.page-filters form {
  width: 100%;
}

@media screen  and (max-width: 1230px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      input {
        margin-right: 0!important;
        min-width: unset!important;
        width: 100%;
      }
    }
  }
}

@media screen  and (max-width: 768px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      .v-select {
        margin-right: 0!important;
        max-width: unset!important;
        width: 100%!important;
      }
    }
  }
}

</style>
