<template>
  <transition name="fade">
    <component
      :is="layout"
      v-if="fetched">
      <transition name="switch">
        <router-view :key="$route.fullPath" />
      </transition>
    </component>
  </transition>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  data () {
    return {
      fetched: false,
      lastLoadItemsWithoutDate: null
    }
  },
  computed: {
    layout () {
      if (!this.$route.meta) {
        return 'default-layout';
      }

      return `${this.$route.meta.layout || 'default'}-layout`;
    },
    userIdInStore () {
      return this.$store.getters['getUserID'];
    },
    isUserOfficeOrAdmin () {
      return (this.$store.getters['getUserType'] === 'OFFICE' || this.$store.getters['getUserType'] === 'ADMIN');
    }
  },
  watch: {
    userIdInStore (newVal) {
      if (newVal && this.isUserOfficeOrAdmin) {
        this.loadScheduleItemsWithoutDate();

        if (!this.$store.getters['getNewOrdersCount']) {
          window.localStorage.setItem('last-counter-refresh', null);
        }
        this.autoLoadNewOrdersCount();
      }
    }
  },
  mounted () {
    let self = this;
    this.fetched = true;
    this.$bus.$on('update-schedules-without-date-count', this.loadScheduleItemsWithoutDate);
    this.$bus.$on('update-new-orders-count', this.autoLoadNewOrdersCount);

    window.crmApp = {
      permissionsAvailable () {
        return self.$store.state && self.$store.state.user && self.$store.state.user.permissions;
      },
      hasPermissions (permissionName) {
        return self.$store.state.user.permissions && self.$store.state.user.permissions[permissionName];
      }
    };

    this.autoRefreshToken();
  },
  methods: {
    refreshToken () {
      window.localStorage.setItem('last-token-refresh', +new Date());

      FormUtils.submitInBackground(this, {
        endpoint: '/api/auth/generate-token',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Nastąpił błąd podczas odnawiania sesji. Proszę odświeżyć stronę.',
        successAction: (response) => {
          this.$http.defaults.headers['X-Csrf-Token'] = `${response.csrf_token}`;
          window.localStorage.setItem('last-token-refresh', +new Date());
        }
      });
    },
    autoRefreshToken () {
      setTimeout(() => {
        let lastRefresh = parseInt(window.localStorage.getItem('last-token-refresh'), 10);

        if (+new Date() - lastRefresh > 30 * 60 * 1000) {
          this.refreshToken();
        }

        this.autoRefreshToken();
      }, 2 * 60 * 1000);
    },
    loadScheduleItemsWithoutDate () {
      if (!this.isUserOfficeOrAdmin) {
        return;
      }
      FormUtils.loadAdditionalData(this, {
        method: 'get',
        endpoint: '/api/orders/schedule/v2/items?without_date_count=1',
        successAction: (response) => {
          this.$store.commit('setScheduleItemsWithoutDateCount', response.count);
        }
      });
    },
    autoLoadNewOrdersCount () {
      if (!this.isUserOfficeOrAdmin) {
        return;
      }
      let lastRefresh = parseInt(window.localStorage.getItem('last-counter-refresh'), 10);
      if (!lastRefresh) {
        this.loadNewOrdersCount();
      }

      setTimeout(() => {
        if (+new Date() - lastRefresh > 2 * 60 * 1000) {
          this.loadNewOrdersCount();
        }

        this.autoLoadNewOrdersCount();
      }, 1 * 60 * 1000);
    },
    loadNewOrdersCount () {
      if (!this.isUserOfficeOrAdmin) {
        return;
      }
      FormUtils.loadAdditionalData(this, {
        method: 'get',
        endpoint: '/api/dashboard/new-orders',
        noPagination: true,
        responseKey: 'new_orders',
        successAction: (response) => {
          this.$store.commit('setNewOrdersCount', response.length);
          window.localStorage.setItem('last-counter-refresh', +new Date());
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('update-schedules-without-date-count', this.loadScheduleItemsWithoutDate);
  }
};
</script>

<style lang="scss">
@import './assets/scss/custom-elements.scss';
@import './assets/scss/responsive.scss';

.switch-enter-to,
.switch-leave-to {
  transition: opacity .25s ease-out;
}

.switch-enter,
.switch-leave-to {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: none;
  width: 100%;
}
</style>
