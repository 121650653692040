<template>
  <d-modal
    :class="{ 'is-hidden': isHidden, 'big-popup': true }"
    @close="handleClose">
    <d-modal-header class="py-3 px-3">
      <d-modal-title>
        Uwagi do maszyny: {{ machineName }}
      </d-modal-title>
    </d-modal-header>
    <d-modal-body class="py-3 px-3">
      <d-form>
        <validation-observer ref="order-creator-service-machine-comment-form" tag="form">
          <d-row>
            <d-col sm="12">
              <label for="comment">
                Nowa uwaga:
              </label>
              <validation-provider
                rules="required"
                name="comment"
                v-slot="{ errors }">
                <d-textarea
                  name="comment"
                  v-model="comment"
                  :value.prop="comment"
                  :rows="4"
                  :state="errors.length ? 'invalid' : null"/>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </d-col>
          </d-row>
        </validation-observer>
      </d-form>
      <order-machine-comments
        :machine-comments="machineComments" />
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="submitInProgress"
        @click.prevent="validateForm">
        <div
          v-if="submitInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
        Zapisz
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import OrderMachineComments from '@/components/forms/order/OrderCreatorServiceMachineComments.vue';

export default {
  name: 'order-creator-service-machine-comment',
  components: {
    'order-machine-comments': OrderMachineComments
  },
  props: [
    'sourceView'
  ],
  data () {
    return {
      comment: '',
      isHidden: true,
      machineComments: [],
      machineName: '',
      orderMachineId: null,
      submitInProgress: false
    };
  },
  mounted () {
    this.$bus.$on('machine-comment-popup-show', this.openPopup);
  },
  methods: {
    handleClose () {
      this.isHidden = true;
      this.dateRangePickerVisible = false;
    },
    validateForm () {
      FormUtils.validate(this.$refs['order-creator-service-machine-comment-form'], this.save);
    },
    save() {
      this.submitInProgress = true;
      let endpointUrl = '/api/machines/comments/store';
      let params = {
        comment: this.comment,
        order_machine_id: this.orderMachineId
      };

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: params,
        method: 'post',
        successTitle: 'Zgłoszono uwagi',
        successText: 'Uwagi do maszyny zostały zgłoszone',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Zgłoszenie uwag do maszyny nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          Vue.nextTick(() => {
            this.submitInProgress = false;
            this.handleClose();

            if (this.sourceView === 'operatorListView') {
              this.$bus.$emit('reload-operator-list');
            } else {
              this.$bus.$emit('reload-order-view');
            }
          });
        },
        failAction: () => {
          this.submitInProgress = false;
        },
      });
    },
    cancel () {
      this.handleClose();
    },
    openPopup (orderMachineId, machineName, machineComments = []) {
      this.orderMachineId = orderMachineId;
      this.machineName = machineName;
      this.machineComments = machineComments;

      this.comment = '';

      setTimeout(() => {
        this.isHidden = false;
        if (this.$refs['order-creator-service-machine-comment-form']) {
          this.$refs['order-creator-service-machine-comment-form'].reset();
        }
      }, 0);
    }
  },
  beforeDestroy () {
    this.$bus.$off('machine-comment-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
