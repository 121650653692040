export default {
  'NEW': {
    label: 'Nowe zamówienie',
    style: 'primary',
    outline: false
  },
  'WAITING_PRODUCTS': {
    label: 'Oczekuje na produkty',
    style: 'danger',
    outline: true
  },
  'WAITING_DST': {
    label: 'Oczekuje na DST',
    style: 'danger',
    outline: true
  },
  'WAITING_SAMPLES': {
    label: 'Oczekuje na próby',
    style: 'danger',
    outline: true
  },
  'WAITING_ACCEPT_SAMPLES': {
    label: 'Oczekuje na akceptację prób',
    style: 'danger',
    outline: true
  },
  'IN_REALIZATION': {
    label: 'W realizacji',
    style: 'success',
    outline: true
  },
  'QUALITY_CONTROL': {
    label: 'Kontrola jakości',
    style: 'warning',
    outline: true
  },
  'WAITING_ADDITIONAL_PRODUCTS': {
    label: 'Oczekuje na dodatkowe produkty',
    style: 'danger',
    outline: true
  },
  'TO_SHIPMENT': {
    label: 'Do wysyłki',
    style: 'primary',
    outline: true
  },
  'ENDED': {
    label: 'Zakończone',
    style: 'success',
    outline: false
  },
  'PAID': {
    label: 'Opłacone',
    style: 'info',
    outline: false
  }
};
