 <template>
  <component
    v-bind:is="tagName"
    ref="targetElement"
    :class="`position-relative lazy-wrapper ${additionalClass}`"
    :style="`min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px`">
    <slot v-if="shouldRender" />
    <div
      v-else
      class="loading-spinner is-light pb-3 pt-3 text-center">
      <span class="loading-spinner-content text-primary">
      </span>
    </div>
  </component>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';

export default {
  name: 'LazyWrapper',
  props: {
    additionalClass: {
      default: '',
      type: String
    },
    minHeight: {
      defaut: 10,
      type: Number
    },
    renderOnIdle: {
      default: false,
      type: Boolean
    },
    tagName: {
      default: 'div',
      type: String
    },
    unrender: {
      default: false,
      type: Boolean
    },
    unrenderDelay: {
      default: 10000,
      type: Number
    }
  },
  data () {
    return {
      fixedMinHeight: 0,
      renderTimer: null,
      shouldRender: false,
      unrenderTimer: null
    }
  },
  mounted() {
    const { stop } = useIntersectionObserver(
      this.$refs.targetElement,
      ([{ isIntersecting }]) => {
        this.targetElement = this.$refs.targetElement;
        if (isIntersecting) {
          // perhaps the user re-scrolled to a component that was set to unrender. In that case stop the unrendering timer
          clearTimeout(this.unrenderTimer);
          // if we're dealing underndering lets add a waiting period of 200ms before rendering. If a component enters the viewport and also leaves it within 200ms it will not render at all. This saves work and improves performance when user scrolls very fast
          this.renderTimer = setTimeout(() => {
            this.shouldRender = true;
          }, this.unrender ? 200 : 0);
          this.shouldRender = true;
          if (!this.unrender) {
            stop();
          }
        } else if (this.unrender) {
          // if the component was set to render, cancel that
          clearTimeout(this.renderTimer);
          this.unrenderTimer = setTimeout(() => {
            this.fixedMinHeight = this.$refs.targetElement ? this.$refs.targetElement.clientHeight : 0;
            this.shouldRender = false;
          }, this.unrenderDelay);
        }
      },
      {
        rootMargin: '400px',
      }
    );

    if (this.renderOnIdle) {
      this.onIdle(() => {
        this.shouldRender = true;
        if (!this.unrender) {
          stop();
        }
      });
    }
  },
  methods: {
    onIdle(callbackFun = () => {}) {
      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(callbackFun);
      } else {
        setTimeout(() => {
          callbackFun();
        }, 550);
      }
    }
  }
};
</script>
