<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Weryfikacja</span>
        <h3 class="page-title">
          Kontrola zamówionych produktów
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/produkty-do-skontrolowania')"
          class="mr-3">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          :disabled="leftToControlCount === 0"
          theme="success"
          @click.prevent="$bus.$emit('quality-control-popup-show', data, data.order.order_name, [], data.services.length)">
          Wprowadź wynik kontroli
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <div
        v-if="!loaded"
        class="loading-spinner pb-3 pt-3 text-center">
        <span class="loading-spinner-content text-primary">
          Trwa ładowanie danych&hellip;
        </span>
      </div>
      <validation-observer
        ref="order-controller-form"
        tag="form">
        <d-alert
          v-if="loadError"
          show
          theme="warning">
          Wczytywanie danych nie powiodło się.
          <a
            href="javascript:window.location.reload();"
            class="alert-link">
            Odśwież stronę
          </a>
          aby spróbować ponownie.
        </d-alert>
        <div v-else-if="loaded">
          <order-controller-product
            :product="data"
            :order-deadline="data.order.deadline"
            :is-in-qa="true" />
        </div>
      </validation-observer>
    </d-form>
    <order-creator-qa-popup />
  </d-container>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';
import ProductTypes from '@/data/product-types.js';
import DstTypes from '@/data/dst-types.js';
import OrderCreatorQualityControlPopup from '../popups/OrderCreatorQualityControlPopup.vue';
import OrderControllerProduct from '@/components/forms/order/OrderControllerProduct.vue';

export default {
  name: 'order-controller-form',
  components: {
    'order-creator-qa-popup': OrderCreatorQualityControlPopup,
    'order-controller-product': OrderControllerProduct
  },
  computed: {
    leftToControlCount () {
      return (this.data.quantity + this.getDeliveredAmount()) - (this.getCorrectAmount() + this.getRejectedAmount());
    },
    productTypeOptions () {
      return ProductTypes;
    },
    dstOptions () {
      return DstTypes;
    }
  },
  data() {
    return {
      data: {
        color_name: null,
        controls: [],
        id: 0,
        order: null,
        product_code: null,
        quantity: null,
        services: [],
        type: null
      },
      loaded: false,
      loadError: false,
    };
  },
  mounted () {
    this.loadItemData();
    this.$bus.$on('reload-order-view', this.loadItemData);
  },
  methods: {
    loadItemData (isControlDone = false) {
      if (isControlDone) {
        this.$router.push('/produkty-do-skontrolowania');
        return;
      }

      this.loaded = false;

      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/orders/products/controller-item/',
        instanceFields: ['id', 'order', 'controls', 'services', 'type', 'color_name', 'quantity', 'product_code'],
        loadedKey: 'loaded'
      });
    },
    getDSTType (dstValue) {
      if (!dstValue) {
        return '-';
      }
      let itemWithValue = this.dstOptions.find(dst => dst.value === dstValue);
      if (itemWithValue && itemWithValue.text) {
        return itemWithValue.text;
      }

      return '-';
    },
    getCorrectAmount () {
      return this.data.controls.map(control => control.correct).reduce((sum, control) => sum + control, 0);
    },
    getRejectedAmount () {
      return this.data.controls.map(control => control.rejected).reduce((sum, control) => sum + control, 0);
    },
    getDeliveredAmount () {
      return this.data.controls.map(control => control.delivered).reduce((sum, control) => sum + control, 0);
    },
    getDstNumber (service) {
      let mostRecentDstNumber = service.dst_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].dst_number) {
            mostRecentDstNumber = acceptedSamples[i].dst_number;
          }
        }
      }
      return mostRecentDstNumber;
    },
    getStitchNumber (service) {
      let mostRecentStitchNumber = service.stitch_number;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].stitch_number) {
            mostRecentStitchNumber = acceptedSamples[i].stitch_number;
          }
        }
      }
      return mostRecentStitchNumber;
    },
    getStrands (service) {
      let mostRecentStrands = service.strands;

      // here we should have only approved samples, filtering is just for safety
      let acceptedSamples = service.samples.filter(item => item.status === 'APPROVED');

      if (acceptedSamples.length) {
        for (let i = 0; i < acceptedSamples.length; i++) {
          if (acceptedSamples[i].strands && acceptedSamples[i].strands.length) {
            mostRecentStrands = acceptedSamples[i].strands;
          }
        }
      }

      let strandsString = '';
      for (let i = 0; i < mostRecentStrands.length; i++) {
        strandsString += mostRecentStrands[i].strand_name + ';  ';
      }

      return strandsString;
    }
  },
  beforeDestroy () {
    this.$bus.$off('reload-order-view', this.loadItemData);
  }
};
</script>
