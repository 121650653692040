<template>
  <d-modal
    size="lg"
    :class="{ 'is-hidden': isHidden }"
    @close="handleClose">
    <d-modal-header>
      <d-modal-title>
        Podgląd zdjęcia
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <img
        v-if="imageUrl && imageUrl.length"
        :src="imageUrl"
        class="d-block mx-auto my-auto"
        style="
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        " />
    </d-modal-body>
  </d-modal>
</template>

<script>
export default {
  name: 'image-preview',
  data () {
    return {
      imageUrl: '',
      isHidden: true
    };
  },
  mounted () {
    this.$bus.$on('image-preview-show', this.openPopup);
  },
  methods: {
    openPopup (imageUrl) {
      this.imageUrl = imageUrl;

      setTimeout(() => {
        this.isHidden = false;
      }, 0);
    },
    handleClose () {
      this.isHidden = true;
    },
    cancel() {
      this.handleClose();
    }
  },
  beforeDestroy () {
    this.$bus.$off('image-preview-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
