<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Pozycje dekoracji</span>
        <h3 class="page-title">
          <template v-if="isEdit">Edycja pozycji dekoracji</template>
          <template v-else>Dodawanie pozycji dekoracji</template>
        </h3>
      </d-col>
      <d-col
        sm="12"
        md="6"
        lg="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="secondary"
          @click.prevent="$router.push('/ustawienia/pozycje-dekoracji')"
          class="mr-1">
          <i class="material-icons">arrow_back</i>
          Wróć do listy
        </d-button>
        <d-button
          theme="success"
          @click.prevent="validateForm">
          Zapisz zmiany
        </d-button>
      </d-col>
    </d-row>

    <!-- Form -->
    <d-form>
      <d-card class="mb-5">
        <div
          v-if="!allDataLoaded && !loadError"
          class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
        <template v-if="!loadError">
          <d-tabs
            card
            :class="{'is-loading': !allDataLoaded}">
            <!-- Dane ogólne -->
            <d-tab
              title="Dane ogólne"
              active
              class="width-narrow px-3 px-md-4">
              <h4 class="mb-3 pb-3 border-bottom">
                Dane ogólne
              </h4>

              <validation-observer
                ref="embroidery-position-form"
                tag="form">
                <div class="form-group">
                  <label for="name">
                    Nazwa:
                  </label>

                  <validation-provider
                    rules="required"
                    name="name"
                    v-slot="{ errors }">
                    <d-input
                      :state="errors.length ? 'invalid' : null"
                      v-model="data.name" />
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label>
                    Zdjęcie:
                  </label>

                  <div class="">
                    <div
                      v-if="data.image_url"
                      class="mb-1 mt-1">
                      <d-img
                        fluid
                        rounded
                        width="100%"
                        height="auto"
                        center
                        style="min-width: 100%;"
                        :src="data.image_url" />
                    </div>

                    <div
                      v-if="data.image_url"
                      class="text-center pt-2">
                      <d-link
                        @click.prevent="removeImage()"
                        title="Usuń zdjęcie"
                        class="text-danger">
                        Usuń zdjęcie
                      </d-link>
                    </div>

                    <file-input
                      v-if="!data.image_url"
                      :onChange="setImage"
                      id="inputFileImage" />
                  </div>
                </div>
              </validation-observer>
            </d-tab>
          </d-tabs>
        </template>
        <template v-else>
          <d-alert
            show
            theme="warning"
            class="my-5 mx-5"
            style="font-weight: bold;">
            Wczytywanie danych nie powiodło się. Sprawdź poprawność podanego URL lub
            <a
              href="javascript:window.location.reload();"
              class="alert-link"
              style="font-weight: bold; text-decoration: underline;">
              odśwież stronę
            </a>
            aby spróbować ponownie.
          </d-alert>
        </template>
      </d-card>
    </d-form>
  </d-container>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'embroidery-position-form',
  computed: {
    allDataLoaded () {
      return this.loaded;
    },
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      data: {
        id: 0,
        image: '',
        image_url: '',
        name: ''
      },
      loaded: false,
      loadError: false
    };
  },
  mounted () {
    if (this.isEdit) {
      this.loadItemData();
    } else {
      this.loaded = true;
    }
  },
  methods: {
    loadItemData () {
      FormUtils.loadItemData(this, {
        method: 'get',
        endpointBase: '/api/embroidery-positions/item/',
        instanceFields: ['id', 'image', 'image_url', 'name'],
        loadedKey: 'loaded',
        errorKey: 'loadError'
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['embroidery-position-form'], this.submitForm);
    },
    submitForm () {
      let imageFields = false;
      let formDataToSend = this.prepareFormDataToSend();
      let endpointUrl = this.isEdit ? '/api/embroidery-positions/update/' + this.id : '/api/embroidery-positions/store';

      if (this.data.image instanceof File) {
        formDataToSend.image = this.data.image;
        imageFields = ['image'];
      } else if (this.data.image) {
        formDataToSend.image = this.data.image;
      } else {
        formDataToSend.image = '';
        formDataToSend.image_url = '';
      }

      FormUtils.submit(this, {
        endpoint: endpointUrl,
        formData: formDataToSend,
        successTitle: 'Zapisano pozycję dekoracji',
        successText: 'Wybrana pozycja dekoracji została zapisana',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Modyfikacja pozycji dekoracji nie powiodła się. Spróbuj ponownie.',
        successAction: () => {
          this.$router.push('/ustawienia/pozycje-dekoracji')
        }
      }, imageFields);
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        name: this.data.name,
        image: this.data.image,
      };

      return formDataToSend;
    },
    removeImage () {
      this.data.image_url = '';
      this.data.image = '';
    },
    setImage (event) {
      this.data.image = event.target.files[0];
    }
  }
};
</script>
