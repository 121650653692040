export default [
  {
    text: 'Do zwrotu',
    value: 'TO_RETURN',
    class: 'text-danger'
  },
  {
    text: 'Zwrócono',
    value: 'RETURNED',
    class: 'text-warning'
  },
  {
    text: 'Przyjęto w HPL',
    value: 'RECEIVED_IN_HPL',
    class: 'text-success'
  }
];
