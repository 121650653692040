<template>
  <div class="form-group">
    <label :for="'service-strands-' + index">
      Kolory nici:
    </label>

    <template v-if="isReadOnly">
      <d-input
        :disabled="true"
        :value="strandsString" />
    </template>
    <template v-else>
      <validation-provider
        :name="'service-strands-' + index"
        v-slot="{ errors }">
        <div
          :class="{
            'form-control': true,
            'is-select': true,
            'is-invalid': errors.length,
            'vs-wrap-text': true
          }">
          <v-select
            :disabled="isDisabled"
            :options="localAllStrands"
            v-model="selectedStrands"
            :reduce="(strand) => strand.id"
            placeholder="Wybierz nici"
            :searchable="true"
            :multiple="true"
            :taggable="true"
            @option:created="addNewStrandColor($event)"
            label="name">
            <template slot="no-options">
              Brak takiego koloru&hellip;
            </template>
            <template slot="option" slot-scope="option">
              {{ option.name }}
              <template v-if="isNewStrandColor(option.name)">
                (Dodaj nowy)
              </template>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
        </div>
      </validation-provider>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'strand-select',
  props: [
    'allStrands',
    'currentStrands',
    'isDisabled',
    'isReadOnly',
    'index'
  ],
  computed: {
    strandsString () {
      if (!this.allStrands || !this.selectedStrands) {
        return '';
      }
      let filteredStrands = this.allStrands.filter(item => this.selectedStrands.indexOf(item.id) > -1);
      let strandsString = '';
      for (let i = 0; i < filteredStrands.length; i++) {
        strandsString += filteredStrands[i].name + ';  ';
      }
      return strandsString;
    }
  },
  data () {
    return {
      localAllStrands: [],
      selectedStrands: []
    }
  },
  mounted () {
    if (this.allStrands) {
      this.localAllStrands = JSON.parse(JSON.stringify(this.allStrands));
    }
    if (this.currentStrands && this.currentStrands.length) {
      if ((typeof this.currentStrands[0]) === 'number') {
        this.selectedStrands = JSON.parse(JSON.stringify(this.currentStrands))
      } else {
        this.selectedStrands = JSON.parse(JSON.stringify(this.currentStrands)).map(strand => strand.strand_id);
      }
    }
  },
  methods: {
    isNewStrandColor (colorToCheck) {
      let existingItem = this.localAllStrands.find(item => item.name === colorToCheck);
      return !existingItem;
    },
    addNewStrandColor (newColor) {
      FormUtils.submitInBackground(this, {
        $http: this.$http,
        endpoint: '/api/strands/store',
        params: {
          name: newColor.name ? newColor.name : newColor
        },
        errorTitle: 'Wystąpił błąd',
        errorText: 'Operacja nie powiodła się. Spróbuj ponownie.',
        skipErrorDisplay: true,
        successAction: (response) => {
          if (response.success) {
            this.localAllStrands.push(response.item);
            this.selectedStrands = this.selectedStrands.filter(strand => strand);
            this.selectedStrands.push(response.item.id);

            Vue.swal({
              title: 'Dodano kolor nici',
              html: 'Dodano nowy kolor nici',
              icon: 'success',
              confirmButtonText: 'OK',
              buttonsStyling: true,
              timer: 2000
            });
          } else {
            this.selectedStrands = this.selectedStrands.filter(strand => strand);

            Vue.swal({
              title: 'Wystąpił błąd podczas dodawania koloru nici',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
          }
        },
        failAction: () => {
          this.selectedStrands = this.selectedStrands.filter(strand => strand);

          Vue.swal({
            title: 'Wystąpił błąd podczas dodawania koloru nici',
            html: 'Spróbuj ponownie',
            icon: 'warning',
            confirmButtonText: 'OK',
            buttonsStyling: true
          });
        }
      });
    },
    getStrands () {
      return this.selectedStrands;
    }
  }
}
</script>
