export default [
  {
    text: 'Zatwierdzona',
    value: 'APPROVED'
  },
  {
    text: 'Odrzucona',
    value: 'REJECTED'
  },
  {
    text: 'Oczekuje',
    value: 'WAITING'
  },
  {
    text: 'Do akceptacji HPL',
    value: 'TO_HPL_ACCEPT'
  }
];
