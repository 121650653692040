import { email } from 'vee-validate/dist/rules';

export default function addVeeValidateRules (VeeValidateExtend) {
  VeeValidateExtend('required', {
    validate: value => value !== null && String(value).trim() !== '',
    computesRequired: true,
    message: 'To pole jest wymagane'
  });

  VeeValidateExtend('email', {
    ...email,
    message: 'To pole musi zawierać poprawny adres e-mail'
  });

  VeeValidateExtend('valid-url', {
    validate: value => {
      if (value.trim() === '') {
        return true;
      }

      let urlPattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');
      return !!urlPattern.test(value);
    },
    computesRequired: true,
    message: 'To pole musi zawierać poprawny adres URL'
  })

  VeeValidateExtend('only-big-letters', {
    validate: value => value === value.replace(/[^A-Z]/gm, ''),
    computesRequired: true,
    message: 'To pole może zawierać wyłącznie duże litery'
  });

  VeeValidateExtend('only-numbers', {
    validate: value => value === value.replace(/[^0-9.]/gm, '') && !Number.isNaN(parseFloat(value.replace(/[^0-9.]/gm, ''))),
    computesRequired: true,
    message: 'To pole musi zawierać liczbę'
  });

  VeeValidateExtend('only-letters-and-dashes', {
    validate: value => value === value.replace(/[^a-zA-Z-]/gm, ''),
    computesRequired: true,
    message: 'To pole może zawierać wyłącznie litery i myślniki'
  });

  VeeValidateExtend('min', {
    params: ['min'],
    validate: (value, { min }) => value.length >= min,
    message: (field, params) => {
      if (params.min === 1) {
        return 'To pole musi zawierać co najmniej jeden znak';
      }

      if (params.min >= 2 && params.min <= 4) {
        return 'To pole musi zawierać co najmniej ' + params.min + ' znaki';
      }

      return 'To pole musi zawierać co najmniej ' + params.min + ' znaków';
    }
  });

  VeeValidateExtend('max', {
    params: ['max'],
    validate: (value, { max }) => value.length <= max,
    message: (field, params) => {
      if (params.max === 1) {
        return 'To pole może zawierać maksymalnie jeden znak';
      }

      if (params.max >= 2 && params.max <= 4) {
        return 'To pole może zawierać maksymalnie ' + params.max + ' znaki';
      }

      return 'To pole może zawierać maksymalnie ' + params.max + ' znaków';
    }
  });

  VeeValidateExtend('min_value', {
    params: ['minValue'],
    validate: (value, { minValue }) => parseFloat(value) >= parseFloat(minValue),
    message: (field, params) => 'Wartość nie może być mniejsza niż ' + params.minValue
  });

  VeeValidateExtend('max_value', {
    params: ['maxValue'],
    validate: (value, { maxValue }) => parseFloat(value) <= parseFloat(maxValue),
    message: (field, params) => 'Wartość nie może być większa niż ' + params.maxValue
  });

  VeeValidateExtend('password', {
    params: [{
      name: 'original',
      isTarget: true
    }],
    validate: (value, { original }) => value === original,
    message: 'Hasła nie są identyczne'
  });

  VeeValidateExtend('requiredDateRange', {
    validate: value => value.startDate !== null && value.endDate !== null,
    computesRequired: true,
    message: 'Podanie zakresu dat jest wymagane'
  });

  VeeValidateExtend('requiredStartRange', {
    validate: value => value.startDate !== null,
    computesRequired: true,
    message: 'Podanie daty jest wymagane'
  });

  VeeValidateExtend('only-natural-numbers', {
    validate: value => {
      value = value.toString();
      return value === value.replace(/[^0-9]/gm, '') && !Number.isNaN(parseFloat(value.replace(/[^0-9]/gm, '')));
    },
    computesRequired: true,
    message: 'To pole musi zawierać liczbę całkowitą'
  });

  VeeValidateExtend('is_not', {
    params: ['invalidValue'],
    validate: (value, { invalidValue }) => value !== invalidValue,
    message: (field, params) => 'Wartość musi być inna niż ' + params.invalidValue
  });
};
