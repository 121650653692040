<template>
  <d-container
    fluid
    class="main-content-container js-disable-sticky-header px-0 px-md-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-5">
      <d-col
        sm="9"
        class="text-left">
        <span class="text-uppercase page-subtitle">Strona główna</span>
        <h3 class="page-title">Witaj w Hafciarnia CRM</h3>
      </d-col>
      <d-col sm="3" class="align-self-end">
        <time-tracker />
      </d-col>
    </d-row>

    <d-row class="mb-5">
      <d-col
        sm="12"
        class="d-flex align-items-center">
        <d-button
          :disabled="!allDataLoaded"
          @click.prevent="$router.push('/operatorzy-i-maszyny/nowy')"
          theme="success">
          <i class="material-icons">add</i>
          Przypisz operatora do maszyny
        </d-button>
        <d-button
          class="ml-2"
          :disabled="!allDataLoaded || reportInProgress"
          @click.prevent="$bus.$emit('orders-report-download-popup-show')"
          theme="primary">
          <i class="material-icons">cloud_download</i>
          Pobierz raport zrealizowanych zamówień
        </d-button>
        <d-button
          class="ml-2"
          :title="!dashboardLists.machines_report ? 'Brak przypisanych operatorów do maszyny' : ''"
          :disabled="!allDataLoaded || reportInProgress || !dashboardLists.machines_report"
          @click.prevent="getMachinesReport"
          theme="secondary">
          <i class="material-icons">
            <template v-if="!dashboardLists.machines_report">do_not_disturb</template>
            <template v-else>cloud_download</template>
          </i>
          Pobierz plan dnia
        </d-button>
      </d-col>
    </d-row>

    <!-- Small Stats Blocks -->
    <d-row
      v-if="loadedLists"
      class="justify-content-center">
      <d-col
        lg
        v-for="(statsItem, idx) in stats"
        :key="idx"
        :class="'mb-5 ' + statsItem.cssClasses">
        <d-card class="p-4 text-center">
          <h6>{{ statsItem.label }}</h6>
          <h3 :class="{ 'text-danger' : getIsStatItemDanger(dashboardLists[statsItem.key]) }">
            <template v-if="'value' in statsItem">
              {{ statsItem.value }}
            </template>
            <template v-else-if="!Array.isArray(dashboardLists[statsItem.key])">
              {{ dashboardLists[statsItem.key] }}
            </template>
            <template v-else>
              {{ dashboardLists[statsItem.key].length }}
            </template>
            <i
              v-if="getIsStatItemDanger(dashboardLists[statsItem.key])"
              class="material-icons alert-icon">
              report_problem
            </i>
          </h3>

          <d-button
            v-if="statsItem.key === 'to_settlement_count'"
            @click.prevent="$bus.$emit('orders-services-to-settle-popup-show')">
            Pokaż zamówienia
          </d-button>
          <d-button
            v-else-if="statsItem.key === 'orders_logs'"
            @click.prevent="$bus.$emit('orders-logs-popup-show', dashboardLists.orders_logs)">
            Pokaż zamówienia
          </d-button>
          <d-button
            v-else-if="statsItem.key === 'orders_boxes'"
            @click.prevent="$bus.$emit('orders-boxes-popup-show', dashboardLists.orders_boxes)">
            Pokaż zamówienia
          </d-button>
          <d-button
            v-else
            @click.prevent="$bus.$emit(
                              'orders-stats-list-popup-show',
                              dashboardLists[statsItem.key],
                              statsItem.key
                            )">
            Pokaż zamówienia
          </d-button>
        </d-card>
      </d-col>
    </d-row>
    <orders-list-stats-popup />
    <orders-report-download-popup />
    <orders-services-to-settle-popup />
    <orders-logs-popup/>
    <orders-boxes-popup />
    <services-settlements-import-popup
      v-if="showServicesSettlementsImportPopup" />
  </d-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import OrdersListStatsPopup from '@/components/popups/OrdersListStatsPopup.vue';
import OrdersReportDownloadPopup from '@/components/popups/OrdersReportDownloadPopup.vue';
import TimeTracker from '@/components/common/TimeTracker.vue';
import OrdersServicesToSettlePopup from '@/components/popups/OrdersServicesToSettlePopup.vue';
import ServicesSettlementsImportPopup from '@/components/popups/ServicesSettlementsImportPopup.vue';
import OrdersLogsPopup from '@/components/popups/OrdersLogsPopup.vue';
import OrdersBoxesPopup from '@/components/popups/OrdersBoxesPopup.vue';

export default {
  name: 'dashboard',
  components: {
    'orders-list-stats-popup': OrdersListStatsPopup,
    'orders-report-download-popup': OrdersReportDownloadPopup,
    'time-tracker': TimeTracker,
    'orders-services-to-settle-popup': OrdersServicesToSettlePopup,
    'services-settlements-import-popup': ServicesSettlementsImportPopup,
    'orders-logs-popup': OrdersLogsPopup,
    'orders-boxes-popup': OrdersBoxesPopup
  },
  data() {
    return {
      dashboardLists: [],
      loaded: false,
      loadedLists: false,
      loadError: false,
      reportInProgress: false,
      showServicesSettlementsImportPopup: false
    };
  },
  computed: {
    ...mapGetters({
      newOrdersCount: 'getNewOrdersCount'
    }),
    allDataLoaded () {
      return this.loadedLists;
    },
    stats() {
      return [
        {
          label: 'Nowe zamówienie',
          key: 'new_orders',
          cssClasses: 'columns-4',
          value: this.newOrdersCount
        },
        {
          label: 'Czeka na próbę',
          key: 'waiting_samples',
          cssClasses: 'columns-4'
        },
        {
          label: 'Czeka na akceptację próby',
          key: 'waiting_accept_samples',
          cssClasses: 'columns-4'
        },
        {
          label: 'Czeka na DST',
          key: 'waiting_dst',
          cssClasses: 'columns-4'
        },
        {
          label: 'Oczekuje na produkty',
          key: 'waiting_products',
          cssClasses: 'columns-4'
        },
        {
          label: 'Minął deadline',
          key: 'deadline_passed',
          cssClasses: 'columns-4'
        },
        {
          label: 'Oczekuje ponownie na produkty',
          key: 'waiting_additional_products',
          cssClasses: 'columns-4'
        },
        {
          label: 'Do rozliczenia',
          key: 'to_settlement_count',
          cssClasses: 'columns-4'
        },
        {
          label: 'Zmiany w zamówieniach HPL',
          key: 'orders_logs',
          cssClasses: 'columns-4'
        },
        {
          label: 'Kartony z HPL',
          key: 'orders_boxes',
          cssClasses: 'columns-4'
        }
      ];
    }
  },
  mounted () {
    this.$bus.$on('dashboard-services-settlements-import-popup-open', this.openServicesSettlementsImportPopup);
    this.$bus.$on('dashboard-services-settlements-import-popup-close', this.closeServicesSettlementsImportPopup);

    this.loadAdditionalData();
  },
  methods: {
    loadAdditionalData () {
      this.$http.get('/api/dashboard/lists').then(response => {
        if (!response.data) {
          this.loadError = true;
          return;
        }

        this.dashboardLists = response.data;
        this.loadedLists = true;
        this.$store.commit('setNewOrdersCount', response.data.new_orders.length);
        window.localStorage.setItem('last-counter-refresh', +new Date());
      }).catch(error => {
        console.log(error);
        this.loadError = true;
      });
    },
    getMachinesReport () {
      this.reportInProgress = true;

      Vue.swal({
        title: 'Czy na pewno chcesz pobrać plan pracy na dzisiaj?',
        text: 'Pobrany plik będzie miał format .xlsx',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, pobierz',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.$http.get('/api/dashboard/machines-report', {
            responseType: 'blob'
          }).then(response => {
            if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'planPracy.xlsx');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.reportInProgress = false;
            } else {
              Vue.swal({
                title: 'Wystąpił błąd podczas pobierania',
                html: 'Spróbuj ponownie',
                icon: 'warning',
                confirmButtonText: 'OK',
                buttonsStyling: true
              });
              this.reportInProgress = false;
            }
          }).catch(error => {
            console.log(error);
            Vue.swal({
              title: 'Wystąpił błąd podczas generowania',
              html: 'Spróbuj ponownie',
              icon: 'warning',
              confirmButtonText: 'OK',
              buttonsStyling: true
            });
            this.reportInProgress = false;
          });
        } else {
          this.reportInProgress = false;
        }
      });
    },
    openServicesSettlementsImportPopup () {
      this.showServicesSettlementsImportPopup = true;
    },
    closeServicesSettlementsImportPopup (shouldReloadList = false) {
      this.showServicesSettlementsImportPopup = false;

      if (shouldReloadList) {
        this.loadedLists = false;
        this.loadAdditionalData();
      }
    },
    getIsStatItemDanger (statItem) {
      if (Array.isArray(statItem)) {
        return statItem.length
      }

      return Number(statItem) > 0;
    }
  },
  beforeDestroy () {
    this.$bus.$off('dashboard-services-settlements-import-popup-open', this.openServicesSettlementsImportPopup);
    this.$bus.$off('dashboard-services-settlements-import-popup-close', this.closeServicesSettlementsImportPopup);
  }
};
</script>

<style lang="scss" scoped>
.columns-2 {
  flex-basis: auto;
  flex-grow: unset;
  max-width: 50%!important;
  width: 50%;
}

.columns-3 {
  flex-basis: auto;
  flex-grow: unset;
  max-width: calc(100% / 3)!important;
  width: calc(100% / 3);
}

.columns-4 {
  flex-basis: auto;
  flex-grow: unset;
  max-width: 25%!important;
  width: 25%;
}

.columns-2,
.columns-3,
.columns-4 {
  h6 {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    min-height: 48px;
  }

  h3 {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 80px;
  }

  .btn {
    margin: 0 auto;
    max-width: 100%;
    width: 200px;
  }
}

.alert-icon {
  font-size: 28px;
  margin-left: 5px;
}

button:disabled {
  cursor: not-allowed;
}
</style>
