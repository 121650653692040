<template>
  <d-container
    fluid
    class="main-content-container px-4 pb-4">
    <!-- Error -->
    <div class="error">
      <div class="error__content">
        <h2>404</h2>
        <h3>Wybrana podstrona nie istnieje</h3>
        <p>Spróbuj ponownie później lub skontaktuj się z administratorem systemu.</p>
        <d-button
          pill
          @click="goBack">
          <i class="material-icons">arrow_back</i>
          Wróć
        </d-button>
      </div>
    </div>
  </d-container>
</template>

<script>
export default {
  name: 'error-404',
  methods: {
    goBack() {
      window.history.back();
    }
  }
}
</script>
