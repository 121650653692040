<template>
  <div class="order-creator">
    <d-alert
      v-if="!isBlocked && !products.length"
      theme="warning"
      show>
      Dodaj produkty do zamówienia klikając przycisk "Dodaj produkt"
    </d-alert>

    <div
      v-if="allDataLoaded">
      <d-row v-if="products.length > 0 && !userIsOffice">
        <d-col
          sm="12"
          md="6"
          lg="6"
          class="text-primary pt-3 mt-1">
          Całkowity koszt zamówienia: <strong>{{ productsTotalCost }} zł</strong>
        </d-col>
        <d-col
          v-if="!isProductSetup || isQAOnly"
          sm="12"
          md="6"
          lg="6"
          class="text-right mt-1">
          <d-button
            theme="primary"
            class="ml-auto mt-2"
            :disabled="isBlocked || !hasProductsWithServicesZeroQuantity"
            @click.prevent="removeServicesWithZeroQuantity">
            Usuń zerowe zdobienia
          </d-button>
        </d-col>
      </d-row>
      <d-row v-if="performPriceRecalculate" class="mt-2">
        <d-col col sm="12">
          <d-alert
            theme="warning"
            show>
            Ceny zostały ponownie przeliczone ponieważ zamówienie pochodzi z HPL.
          </d-alert>
        </d-col>
      </d-row>
      <order-creator-product
        v-for="(product, index) of products"
        :key="'product-item-' + index + '-' + product.uuid"
        ref="order-creator-products"
        :allContractors="allContractors"
        :allEmbroideryPositions="allEmbroideryPositions"
        :allEmbroideryTypes="allEmbroideryTypes"
        :allMachines="allMachines"
        :allPrices="allPrices"
        :allServiceGroups="allServiceGroups"
        :allServices="allServices"
        :allStrands="allStrands"
        :allWorkers="allWorkers"
        :initialProduct="product"
        :isBlocked="isBlocked"
        :isInPreparation="isInPreparation"
        :isMachinesEnabled="isMachinesEnabled"
        :isProductSetup="isProductSetup"
        :isQAOnly="isQAOnly"
        :isSamplesOnly="isSamplesOnly"
        :orderName="orderName"
        :performPriceRecalculate="performPriceRecalculate"
        :productIndex="index"
        :status="status"
        :toBeDelivered="status === 'WAITING_ADDITIONAL_PRODUCTS' && product.control_rejected_count > 0"
        @removeProduct="handleRemoveProduct"
        @updateProductPrice="handleUpdateProductPrice" />
    </div>

    <div
      v-if="!allDataLoaded && !!products.length"
      class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import UniversalUtils from '@/utils/UniversalUtils.js';
import CustomDecimals from '@/utils/CustomDecimals.js';
import OrderCreatorProduct from './OrderCreatorProduct.vue';

export default {
  name: 'order-creator',
  props: [
    'isBlocked',
    'isInPreparation',
    'isMachinesEnabled',
    'isProductSetup',
    'isQAOnly',
    'isSamplesOnly',
    'orderForm',
    'orderName',
    'orderedProducts',
    'performPriceRecalculate',
    'status'
  ],
  components: {
    'order-creator-product': OrderCreatorProduct
  },
  computed: {
    allDataLoaded () {
      return this.dataLoaded && this.loadedServiceGroups && this.loadedServices && this.loadedStrands && this.loadedMachines && this.loadedWorkers && this.loadedEmbroideryType && this.loadedEmbroideryPositions && this.loadedContractors && this.loadedPrices;
    },
    formErrors () {
      return this.orderForm.errors;
    },
    userIsOffice () {
      return this.$store.getters['getUserType'] === 'OFFICE';
    },
    productsTotalCost () {
      return this.roundProperly(this.productsPrices.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0));
    }
  },
  data () {
    return {
      allContractors: [],
      allEmbroideryPositions: [],
      allEmbroideryTypes: [],
      allMachines: [],
      allPrices: [],
      allServiceGroups: [],
      allServices: [],
      allStrands: [],
      allWorkers: [],
      dataLoaded: false,
      hasProductsWithServicesZeroQuantity: false,
      isPricesSet: false,
      loadedContractors: false,
      loadedEmbroideryPositions: false,
      loadedEmbroideryType: false,
      loadedMachines: false,
      loadedPrices: false,
      loadedServiceGroups: false,
      loadedServices: false,
      loadedStrands: false,
      loadedWorkers: false,
      loadError: false,
      products: [],
      productsPrices: []
    };
  },
  mounted () {
    this.loadAdditionalData();
  },
  methods: {
    addProduct () {
      this.products.push({
        id: null,
        product_code: null,
        quantity: null,
        color_name: '',
        type: null,
        services: [],
        control_rejected_count: 0,
        uuid: UniversalUtils.generateUUID()
      });

      this.productsPrices.push(0);

      Vue.nextTick(() => {
        this.dataLoaded = true;

        setTimeout(() => {
          if (this.$refs['order-creator-products'] && this.$refs['order-creator-products'][this.products.length - 1]) {
            let newEl = this.$refs['order-creator-products'][this.products.length - 1].$el;
            if (newEl.scrollHeight) {
              newEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
              return;
            }
          }
          this.$el.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        }, 100);
      });
    },
    getProductsForAPI () {
      let productsToSend = [];

      for (let i = 0; i < this.products.length; i++) {
        productsToSend.push(this.$refs['order-creator-products'][i].getProductForAPI());
      }

      return productsToSend;
    },
    setProducts (orderedProducts) {
      this.resetView();

      for (let i = 0; i < orderedProducts.length; i++) {
        orderedProducts[i].uuid = orderedProducts[i].id;
        for (let j = 0; j < orderedProducts[i].services.length; j++) {
          orderedProducts[i].services[j].uuid = orderedProducts[i].services[j].id;
          if (orderedProducts[i].services[j].dst_receive_date) {
            orderedProducts[i].services[j].dstReceiveDate = {
              startDate: new Date(orderedProducts[i].services[j].dst_receive_date)
            };
          } else {
            orderedProducts[i].services[j].dstReceiveDate = {
              startDate: null
            };
          }
        }

        this.products.push(orderedProducts[i]);
      }
      this.updateHasProductsWithServicesZeroQuantity();
      Vue.nextTick(() => {
        this.dataLoaded = true;
      })
    },
    resetView () {
      this.products = [];
      this.productsPrices = [];
      this.dataLoaded = false;

      this.loadPrices();
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/groups/items',
        method: 'get',
        outputKey: 'allServiceGroups',
        loadedKey: 'loadedServiceGroups',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/services/items',
        method: 'get',
        outputKey: 'allServices',
        loadedKey: 'loadedServices',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/strands/items',
        method: 'get',
        outputKey: 'allStrands',
        loadedKey: 'loadedStrands',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/items',
        method: 'get',
        outputKey: 'allMachines',
        loadedKey: 'loadedMachines',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/users/items',
        method: 'get',
        outputKey: 'allWorkers',
        loadedKey: 'loadedWorkers',
        errorKey: 'loadError',
        params: {
          pagination: 0,
          where: {
            active: 1
          }
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/embroidery-types/items',
        method: 'get',
        outputKey: 'allEmbroideryTypes',
        loadedKey: 'loadedEmbroideryType',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/embroidery-positions/items',
        method: 'get',
        outputKey: 'allEmbroideryPositions',
        loadedKey: 'loadedEmbroideryPositions',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/contractors/items',
        method: 'get',
        outputKey: 'allContractors',
        loadedKey: 'loadedContractors',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });

      this.loadPrices();
    },
    loadPrices () {
      if (this.isProductSetup && !this.isQAOnly) {
        FormUtils.loadAdditionalData(this, {
          endpoint: '/api/services/prices/items',
          method: 'get',
          outputKey: 'allPrices',
          loadedKey: 'loadedPrices',
          errorKey: 'loadError',
          params: {
            pagination: 0
          }
        });
      } else {
        this.loadedPrices = true;
      }
    },
    removeServicesWithZeroQuantity () {
      for (let i = 0; i < this.products.length; i++) {
        this.$refs['order-creator-products'][i].removeServicesWithQuantityZero();
      }

      Vue.nextTick(() => {
        this.updateProducts();
      })
    },
    handleUpdateProductPrice (productIndex, newPrice) {
      Vue.set(this.productsPrices, productIndex, newPrice);
    },
    updateProducts () {
      for (let i = 0; i < this.products.length; i++) {
        Vue.set(this.products, i, this.$refs['order-creator-products'][i].getProduct());
      }

      Vue.nextTick(() => {
        this.updateHasProductsWithServicesZeroQuantity();
      })
    },
    handleRemoveProduct (productIndex, showConfirm = true) {
      console.log('removeProduct', productIndex);
      if (!showConfirm) {
        this.removeProduct(productIndex);
        return;
      }
      Vue.swal({
        title: 'Czy na pewno chcesz usunąć ten produkt?',
        text: 'Aby operacja została zapisana należy zapisać zamówienie',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, usuń',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          this.removeProduct(productIndex);
        }
      });
    },
    removeProduct (productIndex) {
      this.updateProducts();

      this.productsPrices.splice(productIndex, 1);
      this.products.splice(productIndex, 1);
    },
    updateHasProductsWithServicesZeroQuantity () {
      if (!this.products.length) {
        this.hasProductsWithServicesZeroQuantity = false;
        return;
      }
      this.hasProductsWithServicesZeroQuantity = !!this.products.filter(product => product.services.some(service => service.quantity === 0)).length;
    },
    roundProperly (value, decimals = 2) {
      return CustomDecimals.roundProperly(value, decimals);
    },
  }
};
</script>

<style lang="scss">
.product-info {
  &-select {
    min-width: 230px;
  }
}

@media screen and (max-width: 1200px) {
  .product-info {
    &-pricing {
      flex-wrap: wrap!important;

      > div {
        margin-left: 1rem !important;
        min-width: 55%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-info {
    &-select {
      width: 100%!important;
    }
    &-pricing {
      margin-top: 20px;
      width: 100%!important;

      > div {
        margin-left: 0!important;
        margin-right: 1rem!important;
        min-width: unset;
      }
    }
  }
}
</style>
