<template>
  <div
    v-if="loaded"
    class="schedule-plan-item my-auto"
    :key="`${schedule.order_id}-${itemIndex}-${schedule.service_id}-${scheduleItem.date}-${scheduleItem.machine_id}-${schedule.uuid || 0}`">
    <template
      v-if="editMode && !isReadonly">
      <div
        class="form-group text-right"
        :key="`${schedule.order_id}-${itemIndex}-${schedule.service_id}-${scheduleItem.date}-${scheduleItem.machine_id}-edit`">
        <validation-provider
          :rules="`only-natural-numbers|min_value:${minPlannedQuantity}|max_value:${maxPlannedQuantity}`"
          name="planned-quantity"
          v-slot="{ errors, failedRules }"
          class="d-flex flex-column">
          <d-input-group
            append="szt."
            :class="{'is-invalid': errors.length}"
            style="order: 2;">
            <input
              ref="plan-input"
              :key="maxPlannedQuantity"
              :state="errors.length ? 'invalid' : null"
              type="number"
              :min="minPlannedQuantity"
              :max="maxPlannedQuantity"
              :step="1"
              class="form-control text-right"
              @focus="setMinaAndMaxPlannedQty"
              @blur="updatePlan"
              v-model="data.planned_quantity"/>
          </d-input-group>
          <div class="invalid-feedback" style="order: 1; margin-bottom: 0.25rem;">
            {{ errors[0] }}.
            <span
              v-if="failedRules && failedRules.min_value"
              key="field-min-value">
              <br>Na maszynie wykonano łącznie {{ machinesPlanAndExecutionAmount[[machine.id]].executions }}.
            </span>
          </div>
        </validation-provider>
      </div>

      <div class="d-flex justify-content-between mt-2 schedule-item-buttons">
        <d-button
          theme="success"
          outline
          class="text-nowrap mx-0"
          size="sm"
          :disabled="!!(data.planned_quantity > maxPlannedQuantity || data.planned_quantity < 0)"
          @click.prevent="stopEdit"
          title="Zatwierdź">
          <i class="material-icons">thumb_up_alt</i>
        </d-button>
        <d-button
          theme="primary"
          outline
          class="text-nowrap mx-1"
          size="sm"
          @click.prevent="editPlan"
          title="Edytuj">
          <i class="material-icons">edit</i>
        </d-button>
        <d-button
          theme="danger"
          outline
          class="text-nowrap mx-0"
          size="sm"
          :disabled="!data.planned_quantity"
          @click.prevent="resetPlan"
          title="Usuń">
          <i class="material-icons">delete</i>
        </d-button>
      </div>
    </template>
    <div
      v-else
      :key="`${schedule.order_id}-${itemIndex}-${schedule.service_id}-${scheduleItem.date}-${scheduleItem.machine_id}-read`"
      class="input-group text-right flex-column">
      <small>
    </small>
      <span class="ml-auto mb-1"><strong class="text-primary">{{data.planned_quantity}}</strong> .szt</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'SchedulePlan',
  props: {
    day: {
      required: true,
      type: String
    },
    isReadonly: {
      default: false,
      type: Boolean
    },
    itemIndex: {
      required: true,
      type: Number
    },
    machine: {
      required: true,
      type: Object
    },
    machineForDayScheduled: {
      required: true,
      type: Number
    },
    machinesPlanAndExecutionAmount: {
      type: Object,
      required: true
    },
    schedule: {
      default: () => {},
      type: Object
    },
    scheduleItem: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      data: {
        date: '',
        execution: 0,
        machine_id: null,
        order_service_id: 0,
        ordering: 0,
        planned_quantity: 0
      },
      editMode: false,
      initialPlannedQuantity: 0,
      loaded: false,
      maxPlannedQuantity: 0,
      minPlannedQuantity: 0,
      samplePreparationTimeForMachine: 0
    };
  },
  mounted () {
    this.samplePreparationTimeForMachine = this.schedule.sample_preparation_time / this.machine.heads_number;

    if (this.scheduleItem) {
      Vue.set(this, 'data', JSON.parse(JSON.stringify(this.scheduleItem)));

      if (this.scheduleItem.edit_mode) {
        Vue.set(this, 'editMode', true);
      }
    }

    this.initialPlannedQuantity = this.data.planned_quantity;
    this.loaded = true;
    Vue.nextTick(() => {
      if (!this.editMode) {
        return;
      }

      this.focusInputField();
    })
  },
  methods: {
    enableEditMode () {
      Vue.set(this, 'editMode', true);

      Vue.nextTick(() => {
        this.focusInputField();
      })
    },
    resetPlan () {
      Vue.set(this.data, 'planned_quantity', 0);
      this.updatePlan();
    },
    stopEdit () {
      Vue.set(this, 'editMode', false);
      this.updatePlan();
    },
    editPlan () {
      this.$bus.$emit('add-schedule-popup-show', this.schedule, this.itemIndex, this.machinesPlanAndExecutionAmount, this.scheduleItem);
    },
    focusInputField () {
      Vue.nextTick(() => {
        let inputField = this.$refs['plan-input'];

        if (inputField) {
          inputField.focus();
        } else {
          this.setMinaAndMaxPlannedQty();
        }
      })
    },
    setMaxPlannedQuantity () {
      let currentlyScheduledTimeForMachine = Number(this.data.planned_quantity) * Number(this.samplePreparationTimeForMachine);
      let availableForMachine = ((Number(this.machine.day_working_time) - Number(this.machineForDayScheduled)) + currentlyScheduledTimeForMachine) / Number(this.samplePreparationTimeForMachine);
      let notScheduledAmount = (Number(this.schedule.quantity) - Number(this.schedule.planned_quantity)) + Number(this.data.planned_quantity);

      Vue.set(this, 'maxPlannedQuantity', Math.max(Math.floor(Math.min(availableForMachine, notScheduledAmount)), 0));
    },
    setMinPlannedQuantity () {
      let minPlan = this.machinesPlanAndExecutionAmount[this.machine.id].executions - (this.machinesPlanAndExecutionAmount[this.machine.id].plannedQuantity - this.initialPlannedQuantity);
      Vue.set(this, 'minPlannedQuantity', Math.floor(Math.max(minPlan, 0)));
    },
    setMinaAndMaxPlannedQty () {
      this.setMaxPlannedQuantity();
      this.setMinPlannedQuantity();
    },
    updatePlan () {
      this.data.planned_quantity = Number(this.data.planned_quantity);
      if (Number(this.data.planned_quantity) === Number(this.scheduleItem.planned_quantity)) {
        return;
      }

      this.$bus.$emit('schedule-items-modify', this.itemIndex, this.data);
      this.$bus.$emit('execution-updated', `${this.day}-${this.machine.id}`, this.data.planned_quantity * this.samplePreparationTimeForMachine, this.initialPlannedQuantity * this.samplePreparationTimeForMachine);
      this.initialPlannedQuantity = this.data.planned_quantity;
    }
  }
}
</script>
