<template>
  <div class="d-flex justify-content-end align-items-center ml-auto">
    <d-button
      v-if="canStartWork"
      theme="success"
      @click.prevent="startWork">
      Rozpocznij pracę
    </d-button>
    <d-button
      v-if="canStopWork"
      theme="success"
      @click.prevent="stopWork">
      Zakończ pracę
    </d-button>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'time-tracker',
  data() {
    return {
      canStartWork: false,
      canStopWork: false
    }
  },
  mounted () {
    this.canStartWork = this.$store.getters['getUserCanStartWork'];
    this.canStopWork = this.$store.getters['getUserCanStopWork'];
  },
  methods: {
    startWork () {
      Vue.swal({
        title: 'Czy na pewno chcesz zacząć pracę?',
        text: 'Twój czas pracy będzie naliczany od momentu potwierdzenia tej operacji.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, zacznij',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          FormUtils.submit(this, {
            endpoint: '/api/users/working-times/start',
            successTitle: 'Zaczęto pracę',
            successText: 'Twój czas pracy jest naliczany.',
            errorTitle: 'Wystąpił błąd',
            errorText: 'Rozpoczęcie naliczania czasu pracy nie powiodło się. Spróbuj ponownie.',
            successAction: () => {
              Vue.nextTick(() => {
                this.canStartWork = false;
                this.canStopWork = true;

                this.$store.commit('setUserCanStartWork', false);
                this.$store.commit('setUserCanStopWork', true);
              });
            }
          });
        }
      });
    },
    stopWork () {
      Vue.swal({
        title: 'Czy na pewno chcesz zakończyć pracę?',
        text: 'Twój czas pracy przestanie być naliczany od momentu potwierdzenia tej operacji.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Tak, zakończ',
        cancelButtonText: 'Anuluj'
      }).then((result) => {
        if (result.value) {
          FormUtils.submit(this, {
            endpoint: '/api/users/working-times/end',
            successTitle: 'Zakończono pracę',
            successText: 'Twój czas pracy przestał być naliczany',
            errorTitle: 'Wystąpił błąd',
            errorText: 'Zakończenie naliczania czasu pracy nie powiodło się. Spróbuj ponownie.',
            successAction: () => {
              Vue.nextTick(() => {
                this.canStartWork = true;
                this.canStopWork = false;

                this.$store.commit('setUserCanStartWork', true);
                this.$store.commit('setUserCanStopWork', false);
              });
            }
          });
        }
      });
    }
  }
}
</script>
