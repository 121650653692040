<template>
  <div class="py-3 pb-4 border-top border-bottom">
    Załączone pliki:
    <ul
      v-if="files && files.length"
      class="files-list">
      <li
        v-for="(item, itemIndex) in files"
        :key="'file-' + itemIndex"
        class="files-list-item">
        <a
          :href="item.file_url"
          target="_blank">
          Pobierz plik - {{ item.name || item.file }}
        </a>
      </li>
    </ul>
    <p v-else class="my-1 text-light">Brak plików</p>
  </div>
</template>

<script>
export default {
  name: 'files-list',
  props: {
    files: {
      default: () => [],
      type: Array
    },
    id: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      downloadInProgress: false,
    };
  }
};
</script>

<style lang="scss" scoped>
.files-list {
  margin: 0;
  padding-left: 30px;

  &-item {
    margin: 10px 0;
  }
}
</style>
