<template>
  <div>
    <table class="table mt-0 mb-0 full-width">
      <thead class="bg-light">
        <tr>
          <th scope="col" class="border-0 align-middle">Produkt</th>
          <th scope="col" class="border-0 align-middle">Kontroler, data</th>
          <th scope="col" class="border-0 align-middle">Poprawne</th>
          <th scope="col" class="border-0 align-middle">Odrzucone</th>
          <th scope="col" class="border-0 align-middle">Komentarz</th>
        </tr>
      </thead>
      <tbody v-if="historyLoaded">
        <tr
          v-for="(control, index) of controlsForDisplay"
          :key="'control-' + index">
          <td class="align-middle">
            Produkt: <strong>{{ control.product_code }}</strong>
          </td>
          <td class="align-middle">
            {{ control.user_name }}<br>
            {{ control.control_date }}<br>
          </td>
          <td class="align-middle">
            <strong class="text-primary">{{ control.correct }} szt.</strong>
          </td>
          <td class="align-middle">
            <strong class="text-danger">{{ control.rejected }} szt.</strong>
            <template v-if="control.reject_reason_txt">
              <br>
              <small>Powód: <strong>{{ control.reject_reason_txt }}</strong>
              </small>
            </template>
            <img
              v-if="control.fault_image_url && control.fault_image_url.length"
              :src="control.fault_image_url"
              @click.prevent="showImagePreview(control.fault_image_url)"
              class="d-block mt-1"
              style="
                cursor: pointer;
                max-width: 100px;
                max-height: 100px;
                object-fit: contain;
              " />
          </td>
          <td class="align-middle">
            {{ control.description }}
          </td>
        </tr>
        <tr v-if="controlsForDisplay.length === 0">
          <td colspan="5" class="text-center">
            Brak kontroli do wyświetlenia&hellip;
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!historyLoaded" class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
    <image-preview />
  </div>
</template>

<script>
import RejectionReasons from '@/data/rejection-reasons.js';
import ImagePreview from '@/components/popups/ImagePreview.vue';

export default {
  name: 'order-execution-history',
  components: {
    'image-preview': ImagePreview
  },
  computed: {
    isAdmin () {
      return this.$store.getters['getUserType'] === 'ADMIN';
    },
    rejectionReasons () {
      return RejectionReasons;
    }
  },
  data() {
    return {
      controlsForDisplay: [],
      historyLoaded: false
    }
  },
  mounted () {
    this.historyLoaded = true;
  },
  methods: {
    setProducts(products) {
      this.controlsForDisplay = [];

      for (let i = 0; i < products.length; i++) {
        for (let j = 0; j < products[i].controls.length; j++) {
          this.controlsForDisplay.push({
            product_code: products[i].product_code,
            user_name: products[i].controls[j].user_name,
            control_date: products[i].controls[j].control_date,
            correct: products[i].controls[j].correct,
            rejected: products[i].controls[j].rejected,
            delivered: products[i].controls[j].delivered,
            description: products[i].controls[j].description,
            reject_reason: products[i].controls[j].reject_reason,
            reject_reason_txt: this.getRejectionReasonTxt(products[i].controls[j].reject_reason),
            fault_image_url: products[i].controls[j].fault_image_url || null
          });
        }
      }

      this.controlsForDisplay.sort((itemA, itemB) => new Date(itemB.control_date) - new Date(itemA.control_date));
      this.historyLoaded = true;
    },
    getRejectionReasonTxt (rejectReason) {
      if (!rejectReason) {
        return '';
      }

      let currentReason = this.rejectionReasons.find(item => item.value === rejectReason);

      if (!currentReason || !currentReason.text) {
        return '';
      }

      return currentReason.text;
    },
    showImagePreview (imageUrl) {
      this.$bus.$emit('image-preview-show', imageUrl);
    }
  }
}
</script>
