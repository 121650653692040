<template>
  <d-modal @close="handleClose">
    <d-modal-header>
      <d-modal-title>
        Dodaj zdobienie #{{ serviceIndex }} do harmonogramu
      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <d-form>
        <validation-observer ref="add-to-schedule-form" tag="form">
          <d-row class="w-100">
            <d-col sm="12" md="12">
              <div class="form-group">
                <label for="machine_id">
                  Maszyna:
                </label>
                <validation-provider
                  name="machine_id"
                  rules="required"
                  v-slot="{ errors }">
                  <div
                  :class="{
                    'form-control': true,
                    'is-select': true,
                    'is-invalid': errors.length,
                    'vs-wrap-text': true
                  }">
                    <v-select
                      :options="machinesData"
                      :reduce="item => item.id"
                      v-model="machineID"
                      placeholder="Wybierz"
                      label="name"
                      :clearable="false"
                      :searchable="false">
                      <template slot="selected-option" slot-scope="option">
                        {{ option.name }} (od: {{option.available_date}})
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.name }} (od: {{option.available_date}})
                      </template>
                    </v-select>
                  </div>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </d-col>
            <d-col sm="12">
              <div class="form-group">
                <label for="schedule-date">
                  Data wykonania:
                </label>

                <validation-provider
                  rules="required|requiredStartRange"
                  name="schedule-date"
                  v-slot="{ errors }">
                  <div
                    :class="{
                      'form-control': true,
                      'is-date-range': true,
                      'vs-wrap-text': true,
                      'is-invalid': errors.length,
                      'is-disabled': !machineID
                    }">
                    <date-range-picker
                      ref="picker"
                      :key="machineID"
                      :disabled="!machineID"
                      :locale-data="{
                        'firstDay': 1,
                        'format': 'DD-MM-YYYY',
                        'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                        'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                        'applyLabel': 'OK',
                        'cancelLabel': 'Anuluj'
                      }"
                      class="w-100"
                      :singleDatePicker="true"
                      :timePicker="false"
                      :showWeekNumbers="false"
                      :showDropdowns="false"
                      v-model="scheduleDate"
                      :ranges="false"
                      :linkedCalendars="true"
                      :auto-apply="true"
                      :minDate="minDate">
                      <template v-slot:input="picker">
                        {{ formatDateString(picker.startDate) }}
                      </template>
                    </date-range-picker>
                  </div>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </d-col>
          </d-row>
        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        :disabled="actionInProgress"
        title="Anuluj"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="actionInProgress"
        title="Dodaj do harmonogramu"
        @click.prevent="validateForm">
        <div
          v-if="actionInProgress"
          class="spinner-border spinner-border-sm"
          role="status">
        </div>
        Dodaj do harmonogramu
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import CalendarUtils from '@/utils/CalendarUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'service-add-to-schedule-popup',
  components: {
    'date-range-picker': DateRangePicker
  },
  props: {
    machinesData: {
      required: true,
      type: Array
    },
    serviceID: {
      required: true,
      type: Number
    },
    serviceIndex: {
      required: true,
      type: Number
    }
  },
  computed: {
    currentMachineObj () {
      return this.machinesData.find(item => item.id === this.machineID);
    },
    minDate () {
      if (!this.machineID) {
        return null
      }

      if (!this.currentMachineObj) {
        return null;
      }

      return this.currentMachineObj.available_date;
    }
  },
  watch: {
    machineID (newValue) {
      if (!newValue || !this.currentMachineObj) {
        this.scheduleDate.startDate = null;
        return;
      }

      this.scheduleDate.startDate = new Date(this.currentMachineObj.available_date);
    }
  },
  data () {
    return {
      actionInProgress: false,
      machineID: null,
      scheduleDate: {
        startDate: null
      }
    };
  },
  mounted () {
    this.actionInProgress = false;
    this.machineID = null;
    this.scheduleDate.startDate = null;
  },
  methods: {
    handleClose () {
      this.$bus.$emit('add-to-schedule-popup-close');
    },
    validateForm () {
      FormUtils.validate(this.$refs['add-to-schedule-form'], this.submitForm);
    },
    submitForm () {
      this.actionInProgress = true;
      let formDataToSend = {
        machine_id: this.machineID,
        date: this.formatDateString(this.scheduleDate.startDate)
      };

      FormUtils.submit(this, {
        endpoint: '/api/orders/services/add-to-schedule/' + this.serviceID,
        formData: formDataToSend,
        successTitle: 'Dodano do harmonogramu',
        successText: `Zdobienie #${this.serviceIndex} zostało dodane do harmonogramu.`,
        errorTitle: 'Wystąpił błąd',
        errorText: 'Dodawanie do harmonogramu nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.actionInProgress = false;

          Vue.nextTick(() => {
            this.$bus.$emit('reload-order-view');
          });
        },
        failAction: () => {
          this.actionInProgress = false;
        }
      });
    },
    cancel () {
      this.handleClose();
    },
    formatDateString (date) {
      return CalendarUtils.formatDateString(date);
    }
  }
}
</script>
