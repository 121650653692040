<template>
  <d-modal @close="handleClose">
    <d-modal-header @click.stop>
      <d-modal-title>
        Importuj plik xlsx z rozliczeniami zdobień
      </d-modal-title>
    </d-modal-header>
    <d-modal-body @click.stop>
      <d-form>
        <validation-observer ref="services-settlements-import-form" tag="form">
          <div class="form-group">
            <label for="name">
              Plik XLSX:
            </label>

            <validation-provider
              rules="required"
              name="file"
              v-slot="{ errors }">
              <file-input
                :onChange="setNewFile"
                id="inputFileImport" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer @click.stop>
      <d-button
        class="ml-0"
        theme="danger"
        :disabled="importInProgress"
        @click.prevent.stop="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="importInProgress"
        @click.prevent.stop="validateForm">
        <div
          v-if="importInProgress"
          class="spinner-border spinner-border-sm"
          role="status"></div>
        Importuj plik
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import FormUtils from '@/utils/FormUtils.js';
import jsonToFormData from 'json-form-data';
import Vue from 'vue';

export default {
  name: 'services-settlements-import-popup',
  data () {
    return {
      file: null,
      importInProgress: false
    };
  },
  mounted () {
    this.file = null;
    this.importInProgress = false;
  },
  methods: {
    handleClose (shouldReloadList = false) {
      this.$bus.$emit('dashboard-services-settlements-import-popup-close', shouldReloadList);
    },
    validateForm () {
      FormUtils.validate(this.$refs['services-settlements-import-form'], this.import);
    },
    setNewFile (event) {
      this.file = event.target.files[0];
    },
    import () {
      this.importInProgress = true;

      this.$http.request({
        method: 'POST',
        url: '/api/orders/services/settlement-by-import',
        headers: {
          'Content-Type': undefined
        },
        transformRequest (data) {
          let dataToSend = jsonToFormData(data);
          return dataToSend;
        },
        data: {
          file: this.file
        }
      }).then(() => {
        this.cancel();
        this.importInProgress = false;

        Vue.swal({
          title: 'Dane zaimportowane',
          html: 'Zaimportowano dane z pliku XLSX',
          icon: 'success',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });

        this.$bus.$emit('orders-services-to-settle-popup-reload');
        this.handleClose(true);
      }).catch(error => {
        console.log(error);
        Vue.swal({
          title: 'Wystąpił błąd podczas importu',
          html: 'Spróbuj ponownie',
          icon: 'warning',
          confirmButtonText: 'OK',
          buttonsStyling: true
        });
        this.importInProgress = false;
      });
    },
    cancel () {
      this.handleClose();
    }
  }
}
</script>
