<template>
  <aside :class="{
    'main-sidebar': true,
    'col-12': true,
    'col-xl-3': true,
    'px-0': true,
    'open': sidebarVisible
  }">
    <div class="main-navbar">
      <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
        <a
          class="navbar-brand w-100 mr-0"
          :href="isAdmin ? '/kokpit' : '/zamowienia'"
          style="line-height: 25px;">
          <div class="d-table m-auto">
            <img
              id="main-logo"
              class="d-inline-block align-top ml-auto mr-auto"
              style="max-width: 110px; width: 100%; height: auto;"
              src="@/assets/images/logo.jpg"
              alt="Hafciarnia CRM">
          </div>
        </a>

        <a
          class="toggle-sidebar d-xl-none"
          @click="handleToggleSidebar()">
          <template v-if="sidebarVisible">
            <i class="material-icons">close</i>
          </template>
          <template v-else>
            <i class="material-icons">menu</i>
            <span class="menu-icon-label">Menu</span>
          </template>

          <img
            v-if="!sidebarVisible"
            id="top-main-logo"
            class="d-inline-block align-top ml-auto mr-auto"
            src="@/assets/images/logo.jpg"
            alt="Hafciarnia CRM">
        </a>
      </nav>

      <div class="logged-in-as text-center">
        <small>Zalogowany jako:</small><br>
        <small class="text-primary">{{ getUserEmail }}</small>
      </div>
    </div>

    <div class="nav-wrapper">
        <d-nav class="flex-column">
          <li
            v-for="(item, navItemIdx) in filteredItems"
            :key="navItemIdx"
            class="nav-item dropdown">
            <d-link :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle' : '']" :to="item.to" v-d-toggle="`snc-${navItemIdx}`" @click.native="handleToggleSidebar()">
              <div
                v-if="item.htmlBefore"
                v-html="item.htmlBefore"
                class="item-icon-wrapper"/>
              <span v-if="item.titles && item.titles[userType]">{{ item.titles[userType] }}</span>
              <span v-else-if="item.title">{{ item.title }}</span>
              <div
                v-if="item.htmlAfter"
                v-html="item.htmlAfter"
                class="item-icon-wrapper"/>
              <d-badge
                v-if="(isAdmin || isOffice) && item.counter && counters[item.counter] >= 0"
                :theme="counters[item.counter] === 0 ? 'light' : 'danger'"
                class="ml-auto">
                {{ counters[item.counter] }}
              </d-badge>
            </d-link>
            <d-collapse
              v-if="item.items && item.items.length"
              :id="`snc-${navItemIdx}`"
              class="dropdown-menu dropdown-menu-small"
              accordion="sidebar-items-accordion">
              <d-dropdown-item v-for="(subItem, subItemIdx) in item.items" :key="subItemIdx" :href="subItem.href" :to="subItem.to">
                {{ subItem.title }}
              </d-dropdown-item>
            </d-collapse>
          </li>
        </d-nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'main-sidebar',
  props: {
    hideLogoText: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'getUserEmail'
    ]),
    filteredItems () {
      let filteredItems = this.items.filter(item => {
        let hasPermission = this.$store.state.user.permissions && (this.$store.state.user.permissions[item.permissions] || !item.permissions)
        let isFoUserType = item.userType ? this.userType === item.userType : true;
        return hasPermission && isFoUserType;
      });

      if (!this.$showScheduleV2) {
        filteredItems = filteredItems.filter(item => item.to !== '/harmonogram-2');
      }

      return filteredItems;
    },
    userType () {
      return this.$store.getters['getUserType'];
    },
    isAdmin () {
      return this.userType === 'ADMIN';
    },
    isOffice () {
      return this.userType === 'OFFICE';
    },
    counters () {
      if (!this.filteredItems.find(item => item.counter)) {
        return {};
      }

      return this.$store.getters['getCounters']
    }
  },
  data() {
    return {
      sidebarVisible: false,
    };
  },
  created() {
    this.$bus.$on('toggle-sidebar', this.handleToggleSidebar);
  },
  beforeDestroy() {
    this.$bus.$off('toggle-sidebar');
  },
  methods: {
    handleToggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
  },
};
</script>

<style lang="scss">
.main-sidebar {
  left: 0;
  max-width: 240px;
  position: fixed;
  top: 0;

  .item-icon-wrapper {
    display: inline-block;
  }
  .dropdown-menu {
    display: block;
  }

  #top-main-logo {
    margin-left: calc(50% - 190px)!important;
    margin-top: -10px;
    object-fit: contain;
    height: 34.5px!important;
    width: 199.5px!important;
  }
}

.logged-in-as {
  background: #f5f5f5;
  border-bottom: 1px solid #e1e5eb;
  line-height: 1.2;
  padding: 10px;
  max-height: 60px;
}

@media screen  and (max-width: 1200px) {
  .main-sidebar {
    box-shadow: none;
    max-width: unset;
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 480px) {
  .menu-icon-label {
    display: none;
  }

  .main-sidebar #top-main-logo {
    margin-left: calc(50% - 120px)!important;
  }
}
</style>
