<template>
  <div>
    <d-row>
      <d-col class="col-12 mb-4">
        <div>
          Uwagi do realizacji:
        </div>
        <div>
          <template v-if="operatorDescription && operatorDescription.length">
            {{ operatorDescription }}
          </template>
          <template v-else>
            Brak
          </template>
        </div>
      </d-col>
    </d-row>

    <table class="table mt-0 mb-0 full-width">
      <thead class="bg-light">
        <tr>
          <th scope="col" class="border-0">Zdobienie</th>
          <th scope="col" class="border-0">Maszyna</th>
          <th scope="col" class="border-0">Operator, data</th>
          <th scope="col" class="border-0">Ilość</th>
        </tr>
      </thead>
      <tbody v-if="historyLoaded">
        <tr
          v-for="(execution, index) of executionsForDisplay"
          :key="'execution-' + index">
          <td class="align-middle">
            <span class="text-primary">{{ execution.service_name }}</span><br>
            <small>Grupa: {{ execution.service_group_name }}</small><br>
            <small>Produkt: {{ execution.product_code }}</small>
          </td>
          <td class="align-middle">
            {{ execution.machine_name }}
          </td>
          <td class="align-middle">
            {{ execution.user_name }}<br>
            {{ execution.realization_date }}<br>
          </td>
          <td class="align-middle">
            {{ execution.execution }} szt.
          </td>
        </tr>
        <tr v-if="executionsForDisplay.length === 0">
          <td colspan="4" class="text-center">
            Brak realizacji do wyświetlenia&hellip;
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!historyLoaded" class="pb-3 pt-3 text-center">
      Trwa ładowanie danych&hellip;
    </div>
  </div>
</template>

<script>

export default {
  name: 'order-execution-history',
  props: [
    'operatorDescription'
  ],
  data() {
    return {
      executionsForDisplay: [],
      historyLoaded: false
    }
  },
  mounted () {
    this.historyLoaded = true;
  },
  methods: {
    setProducts(products) {
      this.executionsForDisplay = [];

      for (let i = 0; i < products.length; i++) {
        for (let s = 0; s < products[i].services.length; s++) {
          for (let m = 0; m < products[i].services[s].machines.length; m++) {
            for (let e = 0; e < products[i].services[s].machines[m].executions.length; e++) {
              this.executionsForDisplay.push({
                product_code: products[i].product_code,
                service_name: products[i].services[s].service_name,
                service_group_name: products[i].services[s].service_group_name,
                machine_name: products[i].services[s].machines[m].machine_name,
                user_name: products[i].services[s].machines[m].executions[e].user_name,
                realization_date: products[i].services[s].machines[m].executions[e].realization_date,
                execution: products[i].services[s].machines[m].executions[e].execution
              });
            }
          }
        }
      }

      this.executionsForDisplay.sort((itemA, itemB) => new Date(itemB.realization_date) - new Date(itemA.realization_date));
      this.historyLoaded = true;
    }
  }
}
</script>
