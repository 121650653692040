<template>
  <d-container
    fluid
    class="main-content-container px-0 px-md-2 px-lg-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="9"
        lg="9"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Użytkownicy</span>
        <h3 class="page-title">Lista użytkowników</h3>
      </d-col>
      <d-col
        col
        sm="12"
        md="3"
        lg="3"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="success"
          @click.prevent="$router.push('/uzytkownicy/nowy')">
          <i class="material-icons">add</i>
          Dodaj użytkownika
        </d-button>
      </d-col>
    </d-row>

    <d-row
      v-if="loaded && currentUserID > 0"
      style="width: 100%;"
      class="d-flex ml-0 mb-0 page-filters">
      <validation-observer
        ref="users-filters-form"
        tag="form"
        class="d-flex mb-2">
        <d-input
          placeholder="Podaj imię, nazwisko lub e-mail użytkownika"
          v-model="filters.search"
          class="mr-1 mb-2"
          style="min-width: 290px;" />

        <v-select
          style="width: 360px; min-width: 150px; max-width: 30%;"
          class="mr-1 mb-2"
          :options="accountTypeOptions"
          v-model="filters.accountType"
          :reduce="type => type.value"
          placeholder="Rodzaj konta?"
          :searchable="false"
          label="text">
        </v-select>

        <v-select
          style="width: 360px; min-width: 170px; max-width: 30%;"
          class="mr-3 mb-2"
          :options="userIsActiveOptions"
          v-model="filters.isActive"
          :reduce="isActive => isActive.value"
          placeholder="Konto aktywne?"
          :searchable="false"
          label="text">
        </v-select>

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-md-auto mr-1 mb-2"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%;">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0 align-middle">Imię i nazwisko</th>
            <th scope="col" class="border-0 align-middle text-center">Adres e-mail</th>
            <th scope="col" class="border-0 align-middle text-center">Rodzaj konta</th>
            <th scope="col" class="border-0 align-middle text-center">Konto aktywne?</th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="loaded && currentUserID">
          <tr
            v-for="(user, index) of users"
            :key="'user-' + index">
            <td class="align-middle py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Imię i nazwisko</label>
              {{ user.name }}
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Adres e-mail</label>
              <template v-if="user.email">
                <a :href="'mailto:' + user.email">
                  {{ user.email }}
                </a>
              </template>
              <template v-else>
                Brak
              </template>
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Rodzaj konta</label>
              <strong
                v-if="user.type === 'ADMIN'"
                class="text-danger">
                Administrator
              </strong>
              <strong
                v-if="user.type === 'OFFICE'"
                class="text-secondary">
                Biuro
              </strong>
              <strong
                v-if="user.type === 'OPERATOR'"
                class="text-primary">
                Operator maszyny
              </strong>
              <strong
                v-if="user.type === 'CONTROLLER'"
                class="text-success">
                Kontroler
              </strong>
            </td>
            <td class="align-middle text-center py-lg-3 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Konto aktywne?</label>
              <span v-if="user.active === 1" class="text-success">
                TAK
              </span>
              <span v-else class="text-danger">
                NIE
              </span>
            </td>
            <td class="text-center align-middle pt-0">
              <d-button
                theme="primary"
                @click.native="$router.push('/uzytkownicy/edycja/' + user.id)"
                class="mx-2 mt-2"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
              <d-button
                theme="danger"
                :disabled="user.id === currentUserID || removeInProgress"
                @click.native="removeUser(user.id)"
                class="mx-2 mt-2"
                title="Usuń">
                <i class="material-icons">delete</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="users.length === 0">
            <td
              colspan="6"
              class="text-center">
              Brak użytkowników do wyświetlenia&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="!loaded || !currentUserID" class="pb-3 pt-3 text-center">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>

    <pagination :meta-data="paginationMetaData" />
  </d-container>
</template>

<script>
import debounce from 'lodash.debounce';
import FormUtils from './../utils/FormUtils.js';
import HasFilters from './../mixins/HasFilters.vue';
import HasPagination from './../mixins/HasPagination.vue';
import ListUtils from './../utils/ListUtils.js';
import Pagination from './../components/common/Pagination.vue';

export default {
  name: 'users',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination
  },
  computed: {
    accountTypeOptions () {
      return [
        {
          text: 'Dowolny',
          value: ''
        },
        {
          text: 'Administrator',
          value: 'ADMIN'
        },
        {
          text: 'Biuro',
          value: 'OFFICE'
        },
        {
          text: 'Operator maszyny',
          value: 'OPERATOR'
        },
        {
          text: 'Kontroler',
          value: 'CONTROLLER'
        }
      ];
    },
    userIsActiveOptions() {
      return [
        {
          text: 'Dowolny stan',
          value: '',
        },
        {
          text: 'Konta aktywne',
          value: '1',
        },
        {
          text: 'Konta nieaktywne',
          value: '0',
        },
      ];
    }
  },
  data() {
    return {
      users: [],
      currentUserID: 0,
      loaded: false,
      loadError: false,
      removeInProgress: false,
      filters: {
        search: '',
        accountType: null,
        isActive: null
      }
    };
  },
  mounted () {
    this.currentUserID = this.$store.getters.getUserID;
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.search) {
        where.search = this.filters.search;
      }

      if (this.filters.accountType) {
        where.type = this.filters.accountType;
      }

      if (this.filters.accountActive !== null && this.filters.accountActive && typeof this.filters.accountActive.value === 'number') {
        where.active = this.filters.accountActive.value;
      }

      if (this.filters.isActive) {
        where.active = this.filters.isActive;
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/users/items?page=' + this.currentPage,
        listField: 'users',
        pagination: true,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        search: '',
        accountType: null,
        isActive: null
      };

      this.loadFilteredData();
      this.$bus.$emit('view-filters-reset');
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['users-filters-form'], this.debouncedLoadFilteredData);
    },
    removeUser (userID) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/users/delete/',
        id: userID,
        successTitle: 'Usunięto użytkownika',
        successText: 'Wybrany użytkownik został usunięty',
        confirmTitle: 'Czy na pewno chcesz usunąć tego użytkownika?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie użytkownika nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        }
      });
    }
  }
};

</script>

<style lang="scss">
.page-filters form {
  width: 100%;
}

@media screen  and (max-width: 1230px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      input {
        margin-right: 0!important;
        min-width: unset!important;
        width: 100%;
      }
    }
  }
}

@media screen  and (max-width: 768px) {
  .page-filters {
    form {
      flex-wrap: wrap;

      .v-select {
        margin-right: 0!important;
        max-width: unset!important;
        width: 100%!important;
      }
    }
  }
}

</style>
