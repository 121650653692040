<template>
  <div>
    <table class="table mt-4 mb-0 full-width">
      <thead class="bg-light">
        <tr>
          <th scope="col" class="border-0">Dodane przez</th>
          <th scope="col" class="border-0">Data dodania</th>
          <th scope="col" class="border-0">Uwagi</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(comment, index) of machineComments"
          :key="'comment-' + index">
          <td>
            <label class="table-mobile-label mb-1">Dodane przez</label>
            {{ comment.user_name }}
          </td>
          <td>
            <label class="table-mobile-label mb-1">Data dodania</label>
            {{ formatDateString(comment.created_at) }}
          </td>
          <td>
            <label class="table-mobile-label mb-1">Uwagi</label>
            {{ comment.comment }}
          </td>
        </tr>
        <tr v-if="machineComments.length === 0">
          <td colspan="5" class="text-center">
            Brak uwag do wyświetlenia&hellip;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CalendarUtils from '@/utils/CalendarUtils.js';

export default {
  name: 'order-machine-comments',
  props: {
    machineComments: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters['getUserType'] === 'ADMIN';
    }
  },
  data() {
    return {}
  },
  methods: {
    formatDateString (date) {
      return CalendarUtils.formatDateString(date);
    }
  }
}
</script>
