<template>
  <d-container
    fluid
    class="main-content-container px-4">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        col
        sm="6"
        class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">
          Wykonawcy
        </span>
        <h3 class="page-title">
          Lista wykonawców
        </h3>
      </d-col>
      <d-col
        col
        sm="6"
        class="text-right text-sm-right d-flex justify-content-end align-items-end">
        <d-button
          theme="success"
          @click.prevent="$router.push('/wykonawcy/nowy')">
          <i class="material-icons">add</i>
          Dodaj wykonawcę
        </d-button>
      </d-col>
    </d-row>

    <d-row
      style="width: 100%;"
      class="d-flex ml-0 mb-3 page-filters">
      <validation-observer
        ref="contractors-filters-form"
        tag="form"
        class="d-flex mb-2">
        <d-input
          placeholder="Podaj nazwę wykonawcy"
          v-model="filters.search"
          class="mr-1 mb-2"
          style="min-width: 290px;" />

        <d-button
          theme="info"
          outline
          class="text-nowrap ml-md-auto mr-1 mb-2"
          @click.prevent="validateFiltersForm">Szukaj</d-button>

        <d-button
          theme="danger"
          outline
          class="text-nowrap mb-2"
          @click.prevent="clearFilters">Resetuj filtry</d-button>
      </validation-observer>
    </d-row>

    <d-card style="width: 100%;">
      <table class="table mt-0 mb-0 full-width">
        <thead class="bg-light">
          <tr>
            <th scope="col" class="border-0" style="width: 360px;">Nazwa wykonawcy</th>
            <th scope="col" class="border-0 align-middle text-center" style="width: 150px;">Akcje</th>
          </tr>
        </thead>
        <tbody v-if="!loadError">
          <tr
            v-for="(contractor, index) of contractors"
            :key="'contractor-' + index">
            <td class="align-middle py-lg-1 py-md-3 py-3">
              <label class="table-mobile-label mb-1">Nazwa wykonawcy</label>
              {{ contractor.name }}
            </td>
            <td class="text-center align-middle py-lg-1 py-md-3 py-3">
              <d-button
                theme="primary"
                @click.native="$router.push('/wykonawcy/edycja/' + contractor.id)"
                class="mx-2 mt-2"
                title="Edytuj">
                <i class="material-icons">edit</i>
              </d-button>
              <d-button
                theme="danger"
                :disabled="removeInProgress"
                @click.native="removeContractor(contractor.id)"
                class="mx-2 mt-2"
                title="Usuń">
                <i class="material-icons">delete</i>
              </d-button>
            </td>
          </tr>
          <tr v-if="contractors.length === 0">
            <td
              colspan="8"
              class="text-center">
              Brak wykonawców do wyświetlenia&hellip;
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="!allDataLoaded"
        class="pb-3 pt-3 text-center overlay-loading">
        Trwa ładowanie danych&hellip;
      </div>

      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
    </d-card>

    <pagination :meta-data="paginationMetaData" />
  </d-container>
</template>

<script>
import debounce from 'lodash.debounce';
import HasFilters from '@/mixins/HasFilters.vue';
import HasPagination from '@/mixins/HasPagination.vue';
import ListUtils from '@/utils/ListUtils.js';
import FormUtils from '@/utils/FormUtils.js';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'contractors',
  mixins: [
    HasFilters,
    HasPagination
  ],
  components: {
    'pagination': Pagination
  },
  computed: {
    allDataLoaded () {
      return this.loaded;
    }
  },
  data() {
    return {
      contractors: [],
      loaded: false,
      loadError: false,
      removeInProgress: false,
      filters: {
        search: ''
      }
    };
  },
  mounted () {
    this.loadFilteredData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      let where = {};

      if (this.filters.search) {
        where.search = this.filters.search;
      }

      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/contractors/items?page=' + this.currentPage,
        listField: 'contractors',
        pagination: true,
        params: {
          where
        }
      });
    },
    clearFilters () {
      this.loaded = false;
      this.loadError = false;
      this.filters = {
        search: ''
      };

      this.$bus.$emit('view-filters-reset');
      this.$bus.$emit('pagination-reset', false);
    },
    validateFiltersForm () {
      this.$bus.$emit('pagination-reset');
      FormUtils.validate(this.$refs['contractors-filters-form'], this.debouncedLoadFilteredData);
    },
    removeContractor (contractorID) {
      this.removeInProgress = true;

      ListUtils.removeItem(this, {
        endpointBase: '/api/contractors/delete/',
        id: contractorID,
        successTitle: 'Usunięto wykonawcę',
        successText: 'Wybrany wykonawca został usunięty',
        confirmTitle: 'Czy na pewno chcesz usunąć tego wykonawcę?',
        confirmText: 'Tej operacji nie można cofnąć',
        errorTitle: 'Wystąpił błąd',
        errorText: 'Usuwanie wykonawcy nie powiodło się. Spróbuj ponownie.',
        successAction: () => {
          this.removeInProgress = false;
          this.loadFilteredData();
        },
        cancelAction: () => {
          this.removeInProgress = false;
        }
      });
    }
  }
};
</script>

