<template>
  <d-modal
  @close="handleClose"
  :class="{ 'is-hidden': isHidden }">
    <d-modal-header>
      <d-modal-title>
        Dodaj plan dla: <span class="text-primary">{{ schedule.order_id }} - {{ schedule.order_name }}</span><br>
        <div
          v-if="isForPlanWithoutDate"
          style="line-height: 1.2; margin: 10px 0 5px 0;">
          Dodawanie istniejącego planu bez daty:<br>
          <small>maszyna: <strong class="text-primary">{{ allMachines.find(item => item.id === originalScheduleItem.machine_id).name }}</strong><br></small>
          <small>planowana ilosć <strong class="text-primary">{{ originalScheduleItem.planned_quantity }} szt.</strong><br></small>
        </div>
        <small>(deadline: <strong>{{ schedule.order_deadline }}</strong>)</small>

      </d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <d-form>
        <validation-observer ref="new-schedule-form" tag="form">
          <div class="form-group">
            <label for="date">
              Plan w dniu:
            </label>

            <validation-provider
              rules="required|requiredStartRange"
              name="date"
              v-slot="{ errors }">
              <div
                :class="{
                  'form-control': true,
                  'is-date-range': true,
                  'vs-wrap-text': true,
                  'is-invalid': errors.length
                }">
                <date-range-picker
                  v-if="dateRangePickerVisible"
                  ref="pickerDate"
                  :locale-data="{
                    'firstDay': 1,
                    'format': 'DD-MM-YYYY',
                    'daysOfWeek': ['Ndz', 'Pon', 'Wto', 'Śro', 'Czw', 'Ptk', 'Sob'],
                    'monthNames': ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
                    'applyLabel': 'OK',
                    'cancelLabel': 'Anuluj'
                  }"
                  class="w-100"
                  :singleDatePicker="true"
                  :timePicker="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  v-model="dateRange"
                  :ranges="false"
                  :linkedCalendars="true"
                  :date-format="dateFormat"
                  :auto-apply="true">
                  <template v-slot:input="pickerDate">
                    {{ prepareDateString(pickerDate.startDate) }}
                  </template>
                </date-range-picker>
              </div>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="machine">
              Maszyna:
            </label>
            <validation-provider
              name="machine"
              rules="required"
              v-slot="{ errors }">
              <div
                :class="{
                  'form-control': true,
                  'is-select': true,
                  'is-invalid': errors.length,
                  'vs-wrap-text': true
                }">
                <v-select
                  :options="allMachines"
                  :disabled="!dateRange.startDate"
                  v-model="data.machine"
                  :selectable="option => supportedMachines.indexOf(option.id) >= 0"
                  placeholder="Wybierz maszynę"
                  label="name">
                  <template slot="no-options">
                    Brak maszyn realizujących wybrane zdobienie
                  </template>
                </v-select>
              </div>
            </validation-provider>
          </div>

          <div class="form-group">
            <label for="planned-quantity">
              Ilość zaplanowanych:
            </label>
            <validation-provider
              :rules="`only-natural-numbers|min_value:${minPlannedQuantity}|max_value:${maxPlannedQuantity}`"
              name="planned-quantity"
              v-slot="{ errors, failedRules }">
              <d-input-group
                append="szt."
                :class="{'is-invalid': errors.length}">
                <d-input
                  :state="errors.length ? 'invalid' : null"
                  type="number"
                  :min="minPlannedQuantity"
                  :max="maxPlannedQuantity"
                  :step="1"
                  class="text-right"
                  :disabled="!data.machine || !data.date"
                  v-model="data.planned_quantity"/>
              </d-input-group>
              <div class="invalid-feedback">
                {{ errors[0] }}
                <span
                  v-if="failedRules && failedRules.min_value && planForCurrentMachine"
                  key="field-min-value">
                  <br>Na maszynie wykonano łącznie {{ planForCurrentMachine.executions }}.
                </span>
              </div>
            </validation-provider>
          </div>
        </validation-observer>
      </d-form>
    </d-modal-body>
    <d-modal-footer>
      <d-button
        class="ml-0"
        theme="danger"
        :disabled="actionInProgress"
        @click.prevent="cancel">
        Anuluj
      </d-button>
      <d-button
        class="ml-auto"
        theme="success"
        :disabled="actionInProgress"
        @click.prevent="validateForm"
        :title="isEdit ? 'Zapisz' : 'Dodaj'">
        <div
          v-if="actionInProgress"
          class="spinner-border spinner-border-sm"
          role="status"/>
        <template v-if="isEdit">
          Zapisz
        </template>
        <template v-else>
          Dodaj
        </template>
      </d-button>
    </d-modal-footer>
  </d-modal>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import CalendarUtils from '@/utils/CalendarUtils';

export default {
  name: 'schedule-item-popup',
  components: {
    'date-range-picker': DateRangePicker,
  },
  props: {
    allMachines: {
      required: true,
      type: Array
    },
    machinesForDaysTime: {
      required: true,
      type: Object
    },
    maxDate: {
      required: true,
      type: Date
    },
    minDate: {
      required: true,
      type: Date
    }
  },
  watch: {
    'dateRange.startDate': function (newValue) {
      if (newValue === null) {
        this.data.date = null;
      }

      this.data.date = this.prepareDateString(newValue);
    }
  },
  computed: {
    machinesSupportingService () {
      return this.allMachines.filter(item => item.supported_services.indexOf(this.schedule.service_id) >= 0);
    },
    supportedMachines () {
      if (!this.dateRangePickerVisible || !this.data.date || !this.schedule) {
        return [];
      }

      let machinesScheduled = [];
      if (this.schedule.items) {
        machinesScheduled = this.schedule.items.filter(item => item.date === this.data.date);
        if (machinesScheduled.length) {
          machinesScheduled = machinesScheduled.map(item => item.machine_id);
        }
      }

      if (this.isEdit) {
        if (this.data.machine && this.data.machine.id) {
          return this.machinesSupportingService.filter(item => machinesScheduled.indexOf(item.id) < 0 || item.id === this.data.machine.id).map(item => item.id);
        }
        return this.machinesSupportingService.filter(item => machinesScheduled.indexOf(item.id) < 0).map(item => item.id);
      }

      return this.machinesSupportingService.filter(item => machinesScheduled.indexOf(item.id) < 0).map(item => item.id);
    },
    maxPlannedQuantity () {
      if (!this.data.date || !this.data.machine || !this.data.machine.id) {
        return 0;
      }

      let machineForDayScheduled = Number(this.machinesForDaysTime[this.data.date + '-' + this.data.machine.id] || 0);
      let samplePreparationTimeForMachine = Number(this.schedule.sample_preparation_time) / Number(this.data.machine.heads_number);
      let currentlyScheduledTimeForMachine = Number(this.originalPlannedQuantity) * samplePreparationTimeForMachine;
      let availableForMachine = ((Number(this.data.machine.day_working_time) - Number(machineForDayScheduled)) + currentlyScheduledTimeForMachine) / samplePreparationTimeForMachine;
      let notScheduledAmount = (Number(this.schedule.quantity) - Number(this.schedule.planned_quantity)) + Number(this.originalPlannedQuantity);

      return Math.max(Math.floor(Math.min(availableForMachine, notScheduledAmount)), 0);
    },
    planForCurrentMachine () {
      if (!this.data.machine || !this.data.machine.id) {
        return null;
      }
      return this.machinesPlanAndExecutionAmount[this.data.machine.id];
    },
    minPlannedQuantity () {
      if (!this.data.machine || !this.data.machine.id || !this.planForCurrentMachine) {
        return 0;
      }

      let minPlan = this.planForCurrentMachine.executions - (this.planForCurrentMachine.plannedQuantity - this.originalPlannedQuantity);

      return Math.floor(Math.max(minPlan, 0))
    }
  },
  data () {
    return {
      actionInProgress: false,
      data: {
        date: '',
        id: 0,
        machine: null,
        order_service_id: 0,
        ordering: 0,
        planned_quantity: 0
      },
      dateRange: {
        startDate: null
      },
      dateRangePickerVisible: false,
      isEdit: false,
      isForPlanWithoutDate: false,
      isHidden: true,
      machinesPlanAndExecutionAmount: null,
      minDateForSchedule: null,
      originalScheduleItem: 0,
      schedule: {},
      scheduleIndex: 0
    };
  },
  mounted () {
    this.$bus.$on('add-schedule-popup-show', this.openPopup);
  },
  methods: {
    openPopup (schedule, scheduleIndex, machinesPlanAndExecutionAmount, scheduleItem = null) {
      this.minDateForSchedule = this.minDate;
      if (CalendarUtils.getIsDayBeforeDate(this.minDate, schedule.order_created_at)) {
        this.minDateForSchedule = new Date(schedule.order_created_at);
      }

      this.resetView();
      this.schedule = JSON.parse(JSON.stringify(schedule));
      this.scheduleIndex = scheduleIndex;
      this.machinesPlanAndExecutionAmount = JSON.parse(JSON.stringify(machinesPlanAndExecutionAmount));
      this.data.ordering = schedule.ordering;
      this.data.order_service_id = schedule.order_service_id;
      this.originalPlannedQuantity = scheduleItem && scheduleItem.planned_quantity ? scheduleItem.planned_quantity : 0;

      if (scheduleItem) {
        let newDate = scheduleItem.date ? new Date(scheduleItem.date) : new Date();

        if (!scheduleItem.date) {
          this.isForPlanWithoutDate = true;

          let todayDateString = this.prepareDateString(newDate);

          let plannedItemsForThisMachine = schedule.items.filter(item => item.machine_id === scheduleItem.machine_id && item.date);
          if (plannedItemsForThisMachine.length && plannedItemsForThisMachine.find(item => item.date === todayDateString)) {
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 1);

            while (CalendarUtils.getIsDayBeforeDate(currentDate, this.maxDate)) {
              let currentDateString = this.prepareDateString(currentDate);

              if (!plannedItemsForThisMachine.find(item => item.date === currentDateString)) {
                newDate = currentDate;
                break;
              }
            }
          }
        }
        this.isEdit = true;
        Vue.set(this.dateRange, 'startDate', newDate);
        this.data.id = scheduleItem.id;
        this.data.machine = this.allMachines.find(item => item.id === scheduleItem.machine_id);
        this.data.planned_quantity = scheduleItem.planned_quantity || 0;

        this.originalScheduleItem = JSON.parse(JSON.stringify(scheduleItem));
      }

      this.actionInProgress = false;

      setTimeout(() => {
        this.dateRangePickerVisible = true;
        this.isHidden = false;
      }, 0);
    },
    handleClose () {
      this.isHidden = true;
    },
    resetView () {
      this.isEdit = false;
      this.dateRange.startDate = null;
      this.dateRangePickerVisible = false;
      this.data = {
        date: '',
        id: 0,
        machine: null,
        order_service_id: 0,
        ordering: 0,
        planned_quantity: 0
      };

      this.isForPlanWithoutDate = false;
      this.originalScheduleItem = {};
      this.schedule = {};
    },
    cancel() {
      this.handleClose();
    },
    validateForm () {
      FormUtils.validate(this.$refs['new-schedule-form'], this.saveSchedule);
    },
    saveSchedule () {
      this.actionInProgress = true;
      let itemToSave = JSON.parse(JSON.stringify(this.data));
      itemToSave.machine_id = itemToSave.machine.id;
      let samplePreparationTimeForMachine = Number(this.schedule.sample_preparation_time) / Number(itemToSave.machine.heads_number);
      delete itemToSave.machine;
      delete itemToSave.execution;
      itemToSave.planned_quantity = Number(itemToSave.planned_quantity);

      if (this.isEdit) {
        this.$bus.$emit('schedule-items-modify', this.scheduleIndex, itemToSave);

        if (itemToSave.date !== this.originalScheduleItem.date || itemToSave.machine_id !== this.originalScheduleItem.machine_id) {
          this.updatePreviousMachinePlan();
        }

        this.$bus.$emit('execution-updated', `${itemToSave.date}-${itemToSave.machine_id}`, itemToSave.planned_quantity * samplePreparationTimeForMachine);

        Vue.nextTick(() => {
          this.actionInProgress = false;
          this.handleClose();
        })
      } else {
        delete itemToSave.id;
        itemToSave.ordering = 0
        itemToSave.order_service_id = this.data.order_service_id

        this.$bus.$emit('schedule-items-add', this.scheduleIndex, itemToSave);
        Vue.nextTick(() => {
          this.$bus.$emit('execution-updated', `${itemToSave.date}-${itemToSave.machine_id}`, itemToSave.planned_quantity * samplePreparationTimeForMachine);
          this.actionInProgress = false;
          this.handleClose();
        })
      }
    },
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = CalendarUtils.getIsDayBeforeDate(date, this.minDateForSchedule) || CalendarUtils.getIsDayBeforeDate(this.maxDate, date);
      }
      return classes
    },
    prepareDateString (input) {
      if (input === null) {
        return 'Wybierz datę';
      }
      input = new Date(input);

      return [
        input.getFullYear(),
        input.getMonth() + 1,
        input.getDate()
      ]
        .map(n => n < 10 ? '0' + n : n)
        .join('-');
    },
    updatePreviousMachinePlan () {
      let prevMachine = this.allMachines.find(item => item.id === this.originalScheduleItem.machine_id);
      let samplePreparationTimeForMachine = this.schedule.sample_preparation_time / prevMachine.heads_number;
      this.$bus.$emit('execution-updated', `${this.originalScheduleItem.date}-${prevMachine.id}`, 0, this.originalScheduleItem.planned_quantity * samplePreparationTimeForMachine);
    }
  },
  beforeDestroy () {
    this.$bus.$off('add-schedule-popup-show', this.openPopup);
  }
}
</script>

<style lang="scss" scoped>
.modal.is-hidden {
  display: none;
  pointer-events: none;
}
</style>
