export default [
  {
    text: 'DST z zamówienia',
    value: 'ORDER'
  },
  {
    text: 'DST od klienta',
    value: 'CUSTOMER'
  },
  {
    text: 'DST - ponowne zamówienie',
    value: 'REPEAT'
  }
];
